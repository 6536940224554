import React from 'react';
import { ServiceQuotation } from '../../services/service-quotation';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import appState from '../../state/AppStateContainer';
import './page-quotation.css';
import { ServiceAuth } from '../../services/service-auth';
import BidConfirmation from './BidConfirmation/bidConfirmation';
import { withRouter, Switch, Route, NavLink, Redirect } from 'react-router-dom';
import {
  LeftMenuContainer,
} from './StaticComponents';

import PageSupplierMessages from './page-supplier-messages';
import QuotationForm from './QuotationForm/QuotationForm';
import ContactInfoForm from './ContactInfoForm/ContactInfoForm';
import QuotationError from "./BidError/bidError";
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import { NotificationManager } from 'react-notifications';
import * as Sentry from "@sentry/react";
import BtButton from '../bt-button/bt-button';
import { ReactComponent as ShareIcon } from '../../assets/svg/shareIcon.svg';
import QuoteSharingModal from './QuoteSharingModal/QuoteSharingModal';

export class PageQuotation extends React.Component {
  appCreateProject;
  elAppChatWriteMessageTemplates;
  modalIndustryTypes;
  static contextTypes = {
    router: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      areaSlug: '',
      projectData: {},
      supplierData: {},
      supplierMemberId: '',
      id: '',
      allVersions: [],
      hasQuotation: false,
      showRequestShareModal: false,
      userNotFound: undefined,
      supplierStatus: undefined,
    };
  }
  elInputFile;
  elBtnSend;

  async componentDidMount() {
    let id = this.props.match.params.id;
    try {
      if (id.length < 128) {
        NotificationManager.error('Kontakta support@accurator.se för att hjälpa oss att lösa detta problem');
        Sentry.captureMessage(`Invalid encrypted id at ${window.location.href} and with referrer ${Document.referrer}`);
      }
      if (!appState.state.token) {
        await ServiceAuth.verifyAndGetSupplierToken(id, this.props.orgSlug);
      }
      const response = await ServiceQuotation.getProjectQuotationByEncryptedId(id);

      const supplierStatus = await ServiceQuotation.fetchSupplierState(response[0].supplier?.organizationNumber);

      const hasQuotation = !!response[0].quoteRefId;
      this.setState({
        id,
        allVersions: response,
        areaSlug: response[0].quotation.area.slug,
        projectData: response[0].quotation.project,
        supplierData: response[0].supplier,
        supplierMemberId: response[0].supplierMemberId,
        hasQuotation,
        supplierStatus,
       });
    } catch (e) {
      console.log('error', e);
      let errorStatus = e?.response?.status;
      //If user can't be verified
      if(errorStatus === 404 && e?.response?.data?.message == 'error.userNotFound') {
        if (e?.response?.data?.userNotFound) {
          this.setState({ userNotFound: e?.response?.data?.userNotFound });
          appState.setSupplierToken({userData: { accessToken: e?.response?.data?.userNotFound.accessToken }})
        }
        errorStatus = 403;
      }
      
      const errorMessage = errorStatus == '404'
      ? `Förfrågan är borttagen.`
      : `Du har inte längre tillgång till denna förfrågan.`;

      this.setState({ error: errorMessage});
    }
  }

  menuButton = (link, title, isActive) => {
    const { match, history } = this.props;
    const remainSelected =
      history.location.pathname.split('/').pop() === 'confirmation' && link === '';
    return (
      <NavLink
        exact
        to={`/${appState.getCurrentOrgSlug()}/quotation/info/${match.params.id}/${link}`}
        activeClassName="bidFormNavLinkSelected"
        className={`bidFormNavLink ${remainSelected ? 'bidFormNavLinkSelected' : ''}`}
        isActive={isActive}
      >
        <div className="bt-button">
          <span>{title}</span>
        </div>
      </NavLink>
    );
  };

  renderChat() {
    const {
      projectData,
      supplierData,
      areaSlug,
      supplierMemberId
    } = this.state;
    return projectData && supplierData && (
      <BtColumn>
        <PageSupplierMessages
          projectSlug={projectData.slug}
          areaSlug={areaSlug}
          supplierSlug={supplierData.slug}
          supplierId={supplierData._id}
          supplierMemberId={supplierMemberId}
        />
      </BtColumn>
    );
  }

  renderFooter() {
    return (
      <footer className='bidFormFooter'>
        <img src="https://assets.website-files.com/60cb60c79838d8d947708ce8/6200ef55808a7d7751fdb8a5_accurator_logo.png" alt="Accurator" width="140" height="48" />
        
        <p>
          Vid frågor om Accurator, kontakta Accurator support:<br/>
          08-156 225, <a href='mailto:support@accurator.se'>support@accurator.se</a>
        </p>

        <p>
          <em>Hur lämnar jag anbud?</em><br/>
          <a target='_blank' rel='noreferrer' href="https://www.accurator.se/sidor/information-for-leverantorer">Instruktionsvideo för leverantörer</a>
        </p>
      </footer>
    )
  }

  renderUserNotFound() {
    const { userNotFound, showRequestShareModal } = this.state;
    return (
      <div>
        Kontaktpersonen länken skickades till finns inte kvar, men du kan skicka förfrågan vidare till en annan kontaktperson.<br/><br/>
        <BtButton size='xxs' color='white' onClick={() => {this.setState({ showRequestShareModal: true })}}  leftIcon={<ShareIcon style={{ color: 'var(--gray-700)' }} fontSize="small" />} className="quotation-share-button">
          Dela förfrågan
        </BtButton>
        <QuoteSharingModal
          fetchingSupplierMembers={false}
          getQuotationData={() => userNotFound.quotation}
          onClose={() => { this.setState({ showRequestShareModal: false })}}
          showRequestShareModal={showRequestShareModal}
          supplierMembers={userNotFound.otherUsers}
          allowNewContacts={false}
        />
      </div>
    );
  }

  render() {
    const {
      id,
      projectData,
      supplierData,
      allVersions,
      error,
      hasQuotation,
      userNotFound,
    } = this.state;
    const { match, location } = this.props;
    const search = this.props.location?.search || '';
    const { name: projectName, number: projectNumber } = projectData;
    const { slug: supplierSlug } = supplierData;
    const currentPath = match.url;

    if (error) return <QuotationError error={userNotFound ? this.renderUserNotFound() : error} />;
    
    return (
      <div className="general--page--content page--quotation">
        <BtGrid>
          <BtRow>
            <LeftMenuContainer>
              {hasQuotation && this.menuButton('' + search, 'Fyll i anbudsformulär')}
              {this.menuButton(
                'communication' + search,
                'Kontakta beställare',
                (_, location) =>
                  location.pathname.indexOf(`/quotation/info/${match.params.id}/communication`) > 0,
              )}
              {supplierSlug && this.menuButton('contactInfo', 'Kontaktuppgifter')}
            </LeftMenuContainer>

            <div className='page-quotation-content'>
              <Switch>
                <Route path={`${currentPath}/confirmation/:organizationNumber`}><BidConfirmation /></Route>
                <Route path={`${currentPath}/communication`}>{this.renderChat()}</Route>
                <Route path={`${currentPath}/contactInfo`}>
                  <ContactInfoForm
                    projectData={{
                      name: projectName,
                      number: projectNumber
                    }}
                    supplierData={supplierData}
                  />
                </Route>
                <Route path={`${currentPath}`}>
                  {hasQuotation && (
                    <div style={{ width: '100%' }}>
                      <QuotationForm
                        supplierStatus={this.state.supplierStatus}
                        quotationVersions={allVersions}
                        onSubmit={() => {
                          window.location.pathname = `/${appState.getCurrentOrgSlug()}/quotation/info/${match.params.id}/confirmation/${supplierData.organizationNumber}`;
                        }}
                      />
                      {this.renderFooter()}
                    </div>
                  )}
                </Route>
                <Route>
                  <Redirect
                    to={`/${appState.getCurrentOrgSlug()}/quotation/info/${id}${location?.search}`}
                  />
                </Route>
              </Switch>
            </div>
          </BtRow>
        </BtGrid>
      </div>
    );
  }
}

export default withRouter(injectIntl(PageQuotation));
