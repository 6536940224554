import axios from "axios";
import NProgress from "nprogress";
import appState from "../state/AppStateContainer";
import FileDownload from "js-file-download";
import _ from "lodash";
import moment from "moment";
import BaseService from "./baseService";
import { PrequalificationDecision } from "../util/appEnum";

const qs = require("query-string");

class ServiceSupplierController extends BaseService {
  /**
   * getProjects
   * @description Get projects
   * @static
   * @returns {Object.<projects>} -
   * @memberOf Service
   */
  async getSupplierMembers(supplierId) {
    const response = await axios.get("/api/supplier/member", {
      params: { supplierId: supplierId.toString() }
    });
    return response.data;
  }
  async getAuthSupplierMembers(supplierId, isSigningAuthority) {
    const response = await axios.get("/api/supplier/member", {
      params: {
        supplierId: supplierId.toString(),
        isSigningAuthority
      }
    });
    return response.data;
  }

  async saveSupplier(data, files) {
    const formData = new FormData();
    formData.append("json", JSON.stringify(data));

    for (let key in files) {
      const value = files[key];
      formData.append(key, value);
    }
    const response = await axios.post("/api/supplier", formData);
    return response.data;
  }

  async saveSupplierApplication(data, files) {
    const formData = new FormData();
    formData.append("json", JSON.stringify(data));

    for (let key in files) {
      const value = files[key];
      formData.append(key, value);
    }
    const response = await axios.post("/api/supplier/application", formData);
    return response.data;
  }

  async fetchSupplier(params) {
    const response = await axios.get('/api/suppliers', { params });
    return response.data;
  }

  async saveReason(data) {
    const response = await axios.put("/api/supplier/addReason", data);
    return response.data;
  }

  async getSupplieById(id) {
    const response = await axios.get(`/api/area/supplier/${id}`);
    return response.data;
  }

  async updateSupplier(data, files) {
    const formData = new FormData();
    formData.append("json", JSON.stringify(data));

    for (let key in files) {
      const value = files[key];
      formData.append(key, value);
    }
    const response = await axios.put("/api/supplier", formData);
    return response.data;
  }

  async addSupplierArea(data) {
    const response = await axios.put("/api/supplier/addArea", data);
    return response.data;
  }

  async updateSupplierMember(data) {
    const response = await axios.put("/api/supplier/member", data);
    return response.data;
  }

  async saveSupplierMember(data) {
    const response = await axios.post("/api/supplier/member", data);
    return response.data;
  }

  async getSuppliersMembers(suppliers) {
    const response = await axios.get("/api/supplier/members", {
      params: {
        suppliers: suppliers
      },
    });

    return response.data;
  }

  async deleteSupplier(supplierId) {
    const response = await axios.delete(`/api/suppliers/${supplierId}`);
    return response.data;
  }

  async updateSupplierMembers(supplierSlug, membersToAdd, membersToRemove, membersToUpdate) {
    const response = await axios.put(`/api/suppliers/${supplierSlug}/members`, {
      membersToAdd, membersToRemove, membersToUpdate
    });
    return response.data;
  }

  async getRecommendedSuppliers(areaId, municipality) {
    const response = await axios.get(`/api/suppliers/recommended?areaId=${areaId}&municipality=${municipality}`);
    return response.data;
  }

  async addFeaturedSupplierToRegistry(supplierId) {
    const response = await axios.post(`/api/suppliers/featured-supplier/${supplierId}`);
    return response.data;
  }
  async approveSupplierApplication(supplierId) {
    const response = await axios.put(`/api/suppliers/${supplierId}/prequalification`, {
      decision: PrequalificationDecision.APPROVED,
    });
    return response.data;
  }

  async rejectSupplierApplication(supplierId) {
    const response = await axios.put(`/api/suppliers/${supplierId}/prequalification`, {
      decision: PrequalificationDecision.REJECTED,
    });
    return response.data;
  }

  async reprocessSupplierApplication(supplierId) {
    const response = await axios.put(`/api/suppliers/${supplierId}/prequalification`, {
      decision: PrequalificationDecision.INBOX,
    });
    return response.data;
  }

  async fetchRecommendedSupplierAdInfo() {
    const response = await axios.get(`/api/supplier-ad-info`);
    return response.data;
  }

  async putBlock(supplierId, block, reason) {
    const response = await axios.put(`/api/suppliers/${supplierId}/block`, {
      block,
      reason,
    });
    return response.data;
  }
}
export const ServiceSupplier = new ServiceSupplierController();
