import { FC } from "react";
import appState from "../../../state/AppStateContainer";
import styles from './EmailTemplate.module.scss';

const EmailTemplate: FC = ({ children }) => {
  return (
    <div className={styles.emailContainer}>
      <img src={appState.currentOrganization.logoURL} style={{ maxWidth: '300px', margin: '16px 0' }} />
      <section className={styles.emailContent}>
        {children}
      </section>
      <footer style={{ marginBottom: '10px' }}>
        <img src="https://cdn.mcauto-images-production.sendgrid.net/745eb0b2b7b2f2d7/827495b0-0f9b-4cac-a761-56aa92059abd/280x96.png" width="140" height="48" style={{ width: '140px', height: '48px' }} />
        <p>Obs! Det går inte att svara på detta mail. Svara avsändaren direkt i Accurator.</p>
        <p>Vid frågor om Accurator, kontakta Accurator support:<br />
          08-156 225, <a href='mailto:support@accurator.se'>support@accurator.se</a></p>

        <p><em>Hur lämnar jag anbud?</em><br />
          <a href="https://www.accurator.se/sidor/information-for-leverantorer">Instruktionsvideo för leverantörer</a></p>
      </footer>
    </div>
  )
}

export default EmailTemplate;