import { FC, useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/layout/app-layout/app-layout";
import DataTable from "../../components/shared/DataTable/DataTable";
import Tabs from "../../components/shared/Tabs";
import BtButton from "../bt-button/bt-button";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { serviceSalesOrgApplication } from "../../services/salesOrgApplicationService";
import { formatDateTime } from "../../util";
import File from "../../components/File/File";
import { FileScope } from "../../util/appEnum";

type Props = {
} & RouteComponentProps;

type Application = {
  _id: string,
  organizationNumber: string,
  createdAt: Date,
  contract: { 
    signedAt?: Date,
    fileName?: string,
    file?: string,
    signedFileName?: string,
  },
}

const PageOrganizationApplications : FC<Props> = ({ history, location }) => {
  const [loader, _setLoader] = useState(false);
  const [applications, setApplications] = useState<Application[]>([]);
  
  const onChangeTab = useCallback((tab: number) => {
    const base = location.pathname.split("/").slice(0, 2).join("/");
    if (tab == 0) history.push(base + "/organizations");
    if (tab == 1) history.push(base + "/organizations/inactive");
    if (tab == 2) history.push(base + "/organizations/applications");
  }, []);

  useEffect(() => {
    (async () => {
      const response = await serviceSalesOrgApplication.listPendingSignedApplications();
      setApplications(response);  
      console.log({response})
    })();
  }, []);

  const approveApplication = useCallback(async (id) => {
    await serviceSalesOrgApplication.approveApplication(id);
    const response = await serviceSalesOrgApplication.listPendingSignedApplications();
    setApplications(response);  
  }, []);

  const rejectApplication = useCallback(async (id) => {
    await serviceSalesOrgApplication.rejectApplication(id);
    const response = await serviceSalesOrgApplication.listPendingSignedApplications();
    setApplications(response);  
  }, []);

  const getColumns = useCallback(() => [
    { title: "Organisationsnummer", field: "organizationNumber", },
    { title: "Skapad", field: "createdAt", render: (rowData: Application) => formatDateTime(rowData.createdAt)},
    { title: "Signerad", field: "contract.signedAt", render: (rowData: Application) => formatDateTime(rowData.contract.signedAt)},
    { title: "Kontrakt", render: (rowData: Application) => <>
      <File 
        file={rowData.contract.signedFileName} 
        fileName={`Användaravtal ${rowData.organizationNumber}.pdf`}
        scope={FileScope.SalesOrgApplicationContract}
        scopeOptions={{ organizationNumber: rowData.organizationNumber }}
      />
    </> },
    { title: "Åtgärd", render: (rowData: Application) => <>
      <BtButton size="xxs" color="gray" onClick={() => approveApplication(rowData._id)}>Godkänn</BtButton>
      {" "}
      <BtButton size="xxs" color="gray" onClick={() => rejectApplication(rowData._id)}>Neka</BtButton>
    </> },

  ], []);

  return (
    <AppLayout title="Organisationer">
      <div style={{ padding: "32px" }}>
        <div className="tab-container">
          <Tabs
            tabs={["Aktiva organisationer", "Inaktiva organisationer", `Ansökningar (${applications.length})`]}
            selected={2}
            onChange={onChangeTab}            
          />
          <div className="tab-actions">
            <BtButton onClick={() => {
              }}>
              Lägg till organisation
            </BtButton>
          </div>
        </div>


        <DataTable 
          isLoading={loader}
          columns={getColumns()}
          data={applications}
        />
        
      </div>
    </AppLayout>
  );
}

export default withRouter(PageOrganizationApplications);

