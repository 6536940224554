import React from 'react';
import './app-create-project.css';
import { NotificationManager } from 'react-notifications';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import _ from 'lodash';
import services from '../../services';
import { ServiceProjects } from '../../services/service-projects';
import appEnum from '../../util/appEnum';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import Field from '../../components/shared/Field/Field';
import Selector from '../../components/shared/Selector/Selector';


const projectSchema = Yup.object().shape({
  name: Yup.string().trim().required('Obligatoriskt'),
  number: Yup.string(),
  customer: Yup.string(),
  province: Yup.object().shape({
    label: Yup.string().required('Obligatoriskt'),
    value: Yup.string().required('Obligatoriskt'),
  }),
  entreprenad: Yup.object().shape({
    label: Yup.string().required('Obligatoriskt'),
    value: Yup.string().required('Obligatoriskt'),
  }),
  estimatedCost: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  projectType: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
});

export default class AppCreateProject extends React.Component {
  constructor(props) {
    super(props);

    let entreprenadList = appEnum.getEntreprenadDataSource();
    let projectCostList = appEnum.getProjectCostDataSource();
    let entreprenadOptions = [...entreprenadList];
    let projectCostOptions = [...projectCostList];
    this.state = {
      name: '',
      number: '',
      responsible: '',
      customer: '',
      province: {},
      entreprenad: {},
      estimatedCost: {},
      projectType: {},
      loaderShow: false,
      dropDownCities: [],
      dropDownProjectTypes: [],
      entreprenadOptions: entreprenadOptions,
      projectCostOptions: projectCostOptions,
    };
  }

  async componentDidMount() {
    services.getCities().then(data => {
      let dropDownCities = [];
      data.forEach(item => dropDownCities.push({ label: item, value: item }));
      this.setState({ dropDownCities });
    });
    ServiceProjects.getProjectTypes().then(data => {
      let dropDownProjectTypes = [];
      data.forEach(item => dropDownProjectTypes.push({ label: item.name, value: item._id }));
      this.setState({ dropDownProjectTypes });
    });
  }

  componentWillUnmount() {
    this.form1 = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentProject && this.props.currentProject != nextProps.currentProject) {
      var data = nextProps.currentProject;
      var newData = {
        _id: data._id,
        slug: data.slug,
        name: data.name,
        number: data.number,
        responsible: data.responsible,
        customer: data.customer,
        province: {
          label: data.province,
          value: data.province,
        },
        entreprenad: data.entreprenad
          ? {
              value: data.entreprenad,
              label: appEnum.getEntreprenadCostValueByKey(data.entreprenad),
            }
          : undefined,
        estimatedCost: data.estimatedCost
          ? {
              value: data.estimatedCost,
              label: appEnum.getProjectCostValueByKey(data.estimatedCost),
            }
          : {},
        projectType: data.projectTypeName
          ? {
              label: data.projectTypeName,
              value: data.projectType,
            }
          : {},
        loaderShow: false,
      };
      this.setState(newData);
    } else if (this.props.showCount != nextProps.showCount) {
      this.resetDialog();
    }
  }

  resetDialog() {
    this.setState({
      _id: '',
      slug: '',
      name: '',
      number: '',
      responsible: '',
      customer: '',
      province: {},
      entreprenad: {},
      estimatedCost: {},
      projectType: {},
      loaderShow: false,
    });
    this.form1?.resetForm();
  }

  btnCreateProjectClickCallback = async () => await this.form1.submitForm();

  showError = error => <div className="error-show">{error}</div>;

  renderForm() {
    const { dropDownCities, entreprenadOptions, projectCostOptions, dropDownProjectTypes } =
      this.state;

    return (
      <div id="create-project-modal">
        <Formik
          ref={el => {
            this.form1 = el;
          }}
          initialValues={{
            name: this.state.name || '',
            number: this.state.number || '',
            customer: this.state.customer || '',
            province: this.state.province || {},
            projectType: this.state.projectType || {},
            entreprenad: this.state.entreprenad,
            estimatedCost: this.state.estimatedCost || {},
          }}
          onSubmit={this.submitForm}
          validationSchema={projectSchema}
          enableReinitialize={true}
        >
          {({ values, errors, touched, setFieldValue }) => (
              <Form>
                <Field
                  className='add-project-field'
                  errorMessage={errors.name}
                  label="Projektnamn"
                  onChange={(value) => setFieldValue("name", value)}
                  value={values.name}
                  name="name"
                  required
                />

                <Field
                  className='add-project-field'
                  errorMessage={errors.number}
                  label="Projektnummer"
                  onChange={(value) => setFieldValue("number", value)}
                  value={values.number}
                  name="number"
                />

                <Field
                  className='add-project-field'
                  errorMessage={errors.customer}
                  label="Beställare"
                  onChange={(value) => setFieldValue("customer", value)}
                  value={values.customer}
                  name="customer"
                />

                  <Selector
                    required
                    placeholder="Vänligen välj"
                    options={dropDownCities?.map(c => {
                      return {
                        value: c.value,
                        label: c.value,
                      };
                    })}
                    value={values.province}
                    onChange={v => setFieldValue('province', v, true)}
                    label="Kommun"
                    className='add-project-field'
                    errorMessage={errors.province && touched.province ? errors.province.value : ""}
                  />

                  <Selector
                    placeholder="Vänligen välj"
                    options={entreprenadOptions?.map(eo => {
                      return {
                        value: eo.key,
                        label: eo.value,
                      };
                    })}
                    value={values.entreprenad}
                    onChange={v => setFieldValue('entreprenad', v, true)}
                    className='add-project-field'
                    errorMessage={errors.entreprenad && touched.entreprenad ? errors.entreprenad.value : ""}
                    label="Entreprenadform"
                    required
                  />

                  <Selector
                    label="Estimerad projektkostnad i MSEK"
                    placeholder="Vänligen välj"
                    options={projectCostOptions?.map(pco => {
                      return {
                        value: pco.key,
                        label: pco.value,
                      };
                    })}
                    value={values.estimatedCost}
                    onChange={v => setFieldValue('estimatedCost', v, true)}
                    className='add-project-field'
                    errorMessage={errors.estimatedCost && touched.estimatedCost ? errors.estimatedCost.value : ""}
                  />

                  <Selector
                    label="Projekttyp"
                    placeholder="Vänligen välj"
                    name="projectType"
                    options={dropDownProjectTypes?.map(c => {
                      return {
                        value: c.value,
                        label: c.label,
                      };
                    })}
                    value={values.projectType}
                    onChange={v => setFieldValue('projectType', v, true)}
                    className='add-project-field'
                    errorMessage={errors.projectType && touched.projectType ? errors.projectType.value : ""}

                  />
              </Form>
            )}
        </Formik>
      </div>
    );
  }

  submitForm = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      this.setState({ loaderShow: true, ...values });
      let newData = {
        _id: this.state._id,
        slug: this.state.slug,
        name: values.name?.trim(),
        responsible: this.state.responsible,
        entreprenad: values.entreprenad?.value,
      };

      if (values.province) newData.province = values.province.value;
      if (values.number) newData.number = values.number;
      if (values.customer) newData.customer = values.customer;
      if (values.projectType) newData.projectType = values.projectType;
      if (values.estimatedCost) newData.estimatedCost = values.estimatedCost?.value;

      if (newData.projectType) {
        const projectType = newData.projectType.value;
        const projectTypeName = newData.projectType.label;
        newData.projectType = projectType;
        newData.projectTypeName = projectTypeName;
      }
      let response;
      if (this.state._id) {
        response = await ServiceProjects.updateProject(newData);

        var newProject = { ...this.props.currentProject };
        newProject.slug = response.slug;
        newProject.name = response.name;
        newProject.number = response.number;
        newProject.customer = response.customer;
        newProject.province = response.province;
        newProject.responsible = response.responsible;
        newProject.projectType = newData.projectType;
        newProject.projectTypeName = newData.projectTypeName;
        newProject.estimatedCost = response.estimatedCost;
        newProject.entreprenad = response.entreprenad;

        response = newProject;

        ServiceProjects.replaceDataItem(response);
        response.isEdit = true;
        NotificationManager.success('Projektet har uppdaterats')
      } else {
        response = await ServiceProjects.addProject(newData);
        ServiceProjects.add(response);
        response.isEdit = false;

        NotificationManager.success('Projektet har skapats')
      }

      if (this.props.onProjectUpdateCreated) {
        this.props.onProjectUpdateCreated(this, response);
      }
    } catch (e) {
      console.error('error', e);

      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, 'response.data.error', 'error.unexpectedError'),
          defaultMessage: 'An unexpected error has occurred',
        }),
        this.props.intl.formatMessage({
          id: 'global.error',
          defaultMessage: 'Error',
        }),
      );
    } finally {
      this.setState({ loaderShow: false });
      setSubmitting(false);
    }
  };

  onClose = () => this.props?.onClose();

  render() {
    return (
      <div className="app-add-supplier app-create-project" ref={el => (this.element = el)}>
        <div className="page-paddings">
          <BtGrid>
            <BtRow>
              <BtColumn className="colum-left pp-col-left">
                <div className="content-left">{this.renderForm()}</div>
              </BtColumn>
            </BtRow>
          </BtGrid>
        </div>
      </div>
    );
  }
}
