import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "../../components/shared/DataTable/DataTable";
import Selector from "../../components/shared/Selector/Selector";
import serviceSupplierMonitoring, { MonitoringEvent, MonitoringType, Result, Sort } from "../../services/service-supplier-monitoring";
import { SearchFieldWithDropdown } from "./SearchFieldWithDropdown";
import Card from "../../components/shared/Card/card";
import { CustomSort } from "../../components/shared/CustomSort";
import TooltipContainer from "../../components/shared/TooltipContainer/TooltipContainer";
import _ from "lodash";
import { MultiValue } from "react-select";
import { useDebouncedState } from "../../util/hooks";
import { XIcon } from "@heroicons/react/outline";
import { NotificationManager } from "react-notifications";
import * as Sentry from "@sentry/react";
import { RouteComponentProps } from "react-router-dom";
import styles from "./EventTab.module.scss";
import { TabPage } from "../../components/shared/PageWithTabs/TabPage";
import { TabsType } from "../../components/shared/PageWithTabs/PageWithTabs";

type Params = {
  supplier?: {
    organizationNumber: string,
    name: string,
  },
  project?: {
    id: string,
    name: string,
  }
};
type RouteTypes = RouteComponentProps<null, Record<string, unknown>, Params>;

type BadgeProps = {
  onRemove?: () => void;
  children: React.ReactNode;
}

const Badge: FC<BadgeProps> = ({ onRemove, children, }) => {
  return <div className={styles.badge} onClick={onRemove}>{children}<XIcon /></div>;
}

type Props = {
  location?: RouteTypes['location'],
  tabs: TabsType,
}

export const EventTab: FC<Props> = ({ tabs, location, }) => {
  const [fetchingEvents, setFetchingEvents] = useState(false);
  const [filterFieldValue, setFilterFieldValue, debouncedFieldValue] = useDebouncedState("", 500);
  const [monitoringTypes, setMonitoringTypes] = useState<MonitoringType[]>([]);
  const [monitoringEvents, setMonitoringEvents] = useState<MonitoringEvent[]>([]);
  const [filteredMonitoredTypes, setFilteredMonitoredTypes] = useState<MultiValue<{ label: string; value: string; }>>([]);
  const [filteredEntities, setFilteredEntities] = useState<Result[]>([]);
  const [eventSort, setEventSort] = useState<Sort>();
  const [initialLoading, setInitialLoading] = useState(true);

  const supplier = location?.state?.supplier;
  const project = location?.state?.project;

  useEffect(() => {
    (async () => {
      if (supplier) {
        setTimeout(() => {
          setFilteredEntities([{
            id: supplier.organizationNumber,
            title: supplier.name,
            tags: {
              type: 'Supplier',
              suppliers: [{
                supplierInfo: {
                  name: supplier.name,
                  organizationNumber: supplier.organizationNumber,
                  id: supplier.organizationNumber
                }
              }]
            }
          }]);
          setInitialLoading(false);
        }, 100);
      } else if (project) {
        const suppliers = await serviceSupplierMonitoring.fetchSuppliersFromProject(project.id);
        if (!_.isEmpty(suppliers)) {
          setFilteredEntities([suppliers]);
        } else {
          setFilteredEntities([{
            id: project.id,
            title: project.name,
            tags: {
              type: 'Supplier',
              suppliers: []
            }
          }]);
        }
        setInitialLoading(false);
      } else {
        setInitialLoading(false);
      }
    })()
  }, [supplier, project]);

  useEffect(() => {
    (async () => {
      try {
        const types = await serviceSupplierMonitoring.fetchMonitoringTypes();
        setMonitoringTypes(types);
      } catch (error) {
        const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
        NotificationManager.error(errorMsg, 'Misslyckat');
        Sentry.captureException(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (initialLoading) {
      return;
    }
    (async () => {
      setFetchingEvents(true);
      const events = await serviceSupplierMonitoring.fetchEvents(
        eventSort,
        filteredMonitoredTypes.map(x => x.value),
        Array.from(new Set(filteredEntities.reduce((total: string[], y) => {
          return total.concat(y.tags.suppliers.map(g => g.supplierInfo.organizationNumber))
        }, [])))
      );
      setMonitoringEvents(events);
      setFetchingEvents(false);
    })()
  }, [eventSort, filteredMonitoredTypes, filteredEntities, initialLoading]);

  const eventColumns = useMemo(() => [
    {
      title: <CustomSort
        sort={eventSort}
        setSort={setEventSort}
        field="date"
      >
        Datum/vecka
      </CustomSort>,
      render: (row: any) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          {row.week ? row.week : row.date}
        </div>
      ),
    }, {
      title: <CustomSort
        sort={eventSort}
        setSort={setEventSort}
        field="name"
      >
        Leverantör
      </CustomSort>,
      render: (row: any) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <TooltipContainer renderReferenceComponent={
            (className, ref) => (
              <span className={className} ref={ref} style={{ color: row.supplier.notFound ? 'var(--gray-400)' : 'var(--gray-700)', marginLeft: '4px' }}>
                {row.supplier.name ?? '-'}
              </span>
            )}
          >
            {row.supplier.notFound && 'Leverantören finns inte i din databas.'}
          </TooltipContainer>
        </div>
      ),
    }, {
      title: <CustomSort
        sort={eventSort}
        setSort={setEventSort}
        field="organizationNumber"
      >
        Org.nr
      </CustomSort>,
      render: (row: any) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          {row.supplier.organizationNumber}
        </div>
      ),
    }, {
      title: <CustomSort
        sort={eventSort}
        setSort={setEventSort}
        field="code"
      >
        Händelse
      </CustomSort>,
      render: (row: any) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          {row.code}{row.type ? ` (${row.type})` : ''}
        </div>
      ),
    }
  ], [eventSort]);

  const getTranslatedType = useCallback((type: 'Supplier' | 'Project' | 'User') => {
    if (type === 'Supplier') {
      return 'Leverantör';
    } else if (type === 'Project') {
      return 'Projekt';
    } else if (type === 'User') {
      return 'Användare';
    }
  }, []);

  return (
    <TabPage tabs={tabs} title="Leverantörsbevakning">
      <div>
        <Card>
          <div className={styles.filterContainer}>
            <span className={styles.filterText}>
              Leverantörer
            </span>
            <div className={styles.filterFieldContainer}>
              <SearchFieldWithDropdown
                placeholder="Filtera på leverantörer, projekt, ansvariga"
                className={styles.filterField}
                value={filterFieldValue}
                onChange={setFilterFieldValue}
                debouncedValue={debouncedFieldValue}
                fetchSearchResults={async (value) => {
                  const results = await serviceSupplierMonitoring.fetchFilteredSuppliers(value);
                  return results.filter(r => !filteredEntities.find(e => e.id === r.id));
                }}
                onSelect={(entity) => {
                  setFilterFieldValue("");
                  setFilteredEntities((oldState) => [...oldState, entity]);
                }}
              />
              <div className={styles.badgeContainer}>
                {filteredEntities.map(e => (
                  <Badge
                    key={e.id}
                    onRemove={() => setFilteredEntities(filteredEntities.filter(f => f.id !== e.id))}
                  >
                    {getTranslatedType(e.tags.type)}: {e.title}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.filterContainer}>
            <span className={styles.filterText}>
              Händelsekategorier
            </span>
            <Selector
              className={styles.filterTypeSelector}
              isOptionDisabled={() => fetchingEvents}
              options={monitoringTypes.map(t => ({ label: t.description, value: t.value }))}
              value={filteredMonitoredTypes}
              isMulti
              onChange={(value) => setFilteredMonitoredTypes(value)}
              placeholder="Alla"
            />
          </div>
        </Card>
        <DataTable
          isLoading={fetchingEvents}
          data={monitoringEvents}
          columns={eventColumns}
          pageSize={10}
        />
      </div>
    </TabPage>
  )
}