import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import SupplierDatabase from '../app-supplier-database/supplier-database';
import BtButton from '../bt-button/bt-button';
import styles from './page-supplier-selection.module.scss';
import { truncateString } from '../../util';

const PageSupplierSelection = ({
  currentProject,
  showLoading,
  onSaveClick,
  selectedSupplierIds,
  match,
  areas
}) => {
  const [suppliers, setSuppliers] = useState();
  const supplierDatabaseInstance = useRef();

  const [area, setArea] = useState();
  const [displayArea, setDisplayArea] = useState();

  useEffect(() => {
    if (areas && areas.length && match && match.params && match.params.areaSlug) {
      let foundArea;
      for (let area of areas) {
        if (area.value == match.params.areaSlug) {
          foundArea = {area};
          break;
        }
        for (let subArea of area.subAreas) {
          if (subArea.value == match.params.areaSlug) {
            foundArea = {area: subArea, isSubArea: true, mainArea: area};
            break;
          }
        }
      }
      setArea(foundArea);
    }
  }, [areas]);

  useEffect(() => {
    if (area) {
      setDisplayArea(area && area.isSubArea ? area.mainArea : area.area);
    }
  }, [area])

  const selectEventHandler = (selectedSupplier) => {
    setSuppliers(selectedSupplier);
  }

  const getVersion = (versionData, supplier) => {
    let quotationVersions = [];

    const getVersion = !_.isEmpty(versionData) ? versionData[0].versions : undefined;

    // push all relative versions of the supplier
    if (!_.isEmpty(getVersion)) {
      for (const qv of getVersion) {
        for (const s of qv.suppliers) {
          if (s.supplierId === supplier._id) quotationVersions.push(qv);
        }
      }
    }
    return quotationVersions.length ? quotationVersions.length : 0;
  };

  const getRequiredQuoteData = supDbState => {
    const { quoteVersion, contactPersons, extendedDate, quoteVersionsData } = supDbState;
    let extendedCp = [];
    let extendedCpForProjectQuotation = [];

    if (quoteVersion) {
      let data = {};
      if (extendedDate) {
        contactPersons.map(e => {
          let version = getVersion(quoteVersionsData, e);

          extendedCp.push({
            supplierId: e.supplierId,
            endDate: extendedDate,
            version: quoteVersion.refVersion,
          });
          extendedCpForProjectQuotation.push({
            supplierId: e.supplierId,
            endDate: extendedDate,
            version,
          });
        });
        data.extendedContactPersons = JSON.stringify(extendedCp);
        data.extendedCpForProjectQuotation = JSON.stringify(extendedCpForProjectQuotation);
      }
      data.areaId = quoteVersion.areaId;
      data.attachments = JSON.stringify(quoteVersion.attachments);
      data.content = quoteVersion.content;
      data.emailText = quoteVersion.emailText;
      data.projectId = quoteVersion.projectId;
      data.quotationExpiryDate = moment(quoteVersion.quotationExpiryDate).endOf('day').utc();
      data.suppliers = JSON.stringify(contactPersons);
      data.refVersion = quoteVersion.refVersion;
      data._id = quoteVersionsData[0].refId;

      if (quoteVersion.demands && quoteVersion.demands.length) {
        data.demands = quoteVersion.demands.map(d => ({
          demandId: d.demandId._id,
          name: d.demandId.name,
        }));
        data.demands = JSON.stringify(data.demands);
      }

      if (quoteVersion.priceDetails && quoteVersion.priceDetails.length) {
        data.priceDetails = quoteVersion.priceDetails.map(p => ({
          priceType: p.priceType._id,
          name: p.priceType.name,
        }));
        data.priceDetails = JSON.stringify(data.priceDetails);
      }
      return data;
    }
    return null;
  };

  const elSaveClickCallback = () => {
    let bidData = {};
    if (supplierDatabaseInstance && supplierDatabaseInstance.current.state) {
      bidData = getRequiredQuoteData(supplierDatabaseInstance.current.state);
    }

    if (onSaveClick) {
      onSaveClick(this, [...new Set([...suppliers, ...selectedSupplierIds])], bidData);
      setSuppliers([]);
    }
  };

  return currentProject && displayArea ? (
    <div className={styles.container}>
      <SupplierDatabase
        ref={supplierDatabaseInstance}
        areaName={displayArea && truncateString(displayArea.label, 25)}
        key={1}
        selectMode={true}
        areaUid={displayArea && displayArea._id}
        currentProject={currentProject}
        selectedSupplierIds={suppliers}
        selectEvent={selectEventHandler}
        realArea={area && area.area}
        realAreaId={area && area.area && area.area._id}
        onSaveSelectedSuppliersClick={elSaveClickCallback}
        showLoading={showLoading}
      />
    </div>
  ) : null
}

export default PageSupplierSelection;