import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import appState from '../../state/AppStateContainer';
import { ServiceUser } from '../../services/service-user';
import { ServiceChat } from '../../services/service-chat';

import Tabs from '../../components/shared/Tabs';
import NotificationCounter from '../../components/shared/NotificationCounter/NotificationCounter';
import BtButton from '../bt-button/bt-button';
import SearchBarWithActions from '../../components/shared/SearchBarWithActions/SearchBarWithActions';
import MaterialTable from 'material-table';
import './page-project-intern-list.css';
import { CustomAvatar, Loading } from '../../components/shared';
import OverflowTip from '../../components/shared/OverflowTip/OverflowTip';
import OptionsMenu from '../../components/shared/OptionsMenu/OptionsMenu';
import { serviceProfile } from '../../services/service-profile';
import AppLayout from '../../components/layout/app-layout/app-layout';
import { ServiceProjects } from '../../services/service-projects';
import AddMember from '../page-internal-chat/add-member';
import appEnum from '../../util/appEnum';
import Modal from '../../components/shared/Modal/Modal';

const PageProjectInternList = ({ isLoading, history, intl }) => {
  const tableRef = useRef();
  const appAddMemberRef = useRef();
  const [allColleagues, setAllColleagues] = useState([]);
  const [colleagues, setColleagues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [showModalAddMember, setShowModalAddMember] = useState(false);
  const [currentMemberToEdit, setCurrentMemberToEdit] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [submitBtnLoader, setSubmitBtnLoader] = useState(false);

  const roleLabels = {
    [appEnum.Role.Client]: 'Beställare',
    [appEnum.Role.Kalkyl]: 'Besökare',
    [appEnum.Role.Inköp]: 'Deltagare',
    [appEnum.Role.Management]: 'Administratör',
    [appEnum.Role.SuperAdmin]: "Superadmin",
  }

  useEffect(() => {
    const startProcessing = async () => {
      setLoading(true);
      try {
        let _colleagues = await serviceProfile.getColleagues();
        const chatNotifications = await ServiceChat.getNotifications();
        _colleagues.map(x => {
          var result = chatNotifications.filter(n => n.fromUserId == x._id);
          if (result.length > 0) {
            x.notificationCount = result[0].count;
          }
          return x;
        });
        tableRef.current.dataManager.changePageSize(_colleagues.length);
        setColleagues(_colleagues);
        setAllColleagues(_colleagues);
      } catch (e) {
        console.error('error', e);
      }

      setLoading(false);
      appState.setState({ loader: false });
    };

    startProcessing().catch(console.error);
  }, []);

  useEffect(() => {
    const socket = ServiceChat.createSocket(appState.getUserToken());
    socket.on(`chatmessage`, x => {
      const matchSlug = x.orgSlugs && x.orgSlugs.includes(appState.getCurrentOrgSlug());
      if (!matchSlug) return;
      subscriber(x);
    });

    return () => {
      socket.off(`chatmessage`);
    };
  }, []);

  const subscriber = async messageObject => {
    await ServiceChat.updateNotifications(messageObject.fromUserId);
    updateNotification(messageObject.fromUserId);
  };

  const updateNotification = async id => {
    const pathName = window.location.pathname;
    const urlLength = pathName.split('/').length;
    let notifyColleagues = [...colleagues];

    if (id !== appState.getUserId()) {
      let i = -1;
      const checkIntern = await notifyColleagues.find((c, _i) => {
        i = _i;
        return c._id == id;
      });
      if (i > -1 && !checkUrl(pathName, checkIntern.slug)) {
        // don't notify for opened chat
        if (!notifyColleagues[i].notificationCount) {
          notifyColleagues[i].notificationCount = 1;
        } else {
          notifyColleagues[i].notificationCount++;
        }

        setColleagues(notifyColleagues);
        if (urlLength > 2) await ServiceChat.updateNotifications(id);
      }
    }
  };

  const checkUrl = (str, strToSearch) => {
    const url = str.split('/');
    return url.find(e => e == strToSearch);
  };

  const onInternClick = async internId => {
    const _colleagues = [...colleagues];
    let selectedInternIdx = _colleagues.findIndex(i => i._id === internId);
    _colleagues[selectedInternIdx].notificationCount = 0;
    await ServiceChat.clearNotifications(internId);
    setColleagues(_colleagues);
  };

  const removeInternLocally = async internId => {
    await ServiceUser.removeUser(internId);
    const _colleagues = [...colleagues];
    let selectedInternIdx = _colleagues.findIndex(i => i._id === internId);
    _colleagues.splice(selectedInternIdx, 1);
    setColleagues(_colleagues);
    setAllColleagues(_colleagues);
  };

  const getColumns = () => {
    return [
      {
        title: 'Namn',
        cellStyle: { fontSize: null },
        field: 'name',
        render: rowData => {
          const unansweredInvitation = !rowData?.isActive && rowData.invitationCode;
          const name = unansweredInvitation ? "Obesvarad inbjudan" : rowData?.name;
          return(
          <div className="name">
            <CustomAvatar alt={unansweredInvitation ? "?" : rowData?.name} src={rowData?.avatarUrl} />
            <span>{name}</span>
            <NotificationCounter counter={parseInt(rowData?.notificationCount)} />
          </div>
        )},
      },
      {
        title: 'E-post',
        cellStyle: { fontSize: null },
        field: 'email',
        render: rowData => <div className="email">{rowData?.email}</div>,
      },
      {
        title: 'Roll',
        cellStyle: { fontSize: null },
        field: 'role',
        render: rowData => <div className="role">{rowData?.role && roleLabels[rowData?.role]}</div>,
      },
      {
        title: 'Projekt',
        cellStyle: { fontSize: null, minWidth: '25vw' },
        render: getProjects,
      },
      {
        title: '',
        align: 'center',
        render: rowData => {

          const hasEditAccess = rowData?._id && !appState.superAdminOrgAndUser() && appState.isManagementRole();
          const hasRemoveAccess = appState.isManagementRole() && rowData._id != appState.getUserId();
          const options = [];


        if (hasEditAccess) {
          options.push({
            label: 'Redigera',
            action: () => onEditMember(rowData),
          });
        }
        if (hasRemoveAccess) {
          options.push({
            label: 'Ta bort',
            action: () => {
              if (window.confirm('Är du säker på att du vill ta bort den här användaren?')) {
               removeInternLocally(rowData?._id)
              }
            },
          });
        }
          return (
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {!isLoading && (
                <OptionsMenu
                  style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}
                  options={options}
                />
              )}
            </div>
          )},
        cellStyle: {
          width: '30px',
        },
      },
    ];
  };

  const getProjects = rowData => {
    if (rowData?.projects?.length) {
      const projectNames = Array.from(
        rowData?.projects
          ?.map(p => ` ${p?.projectId?.name}`)
          .reduce((set, name) => set.add(name), new Set())
        ).toSorted((a,b) => a.localeCompare(b, undefined, {numeric: true}));
      
      return <OverflowTip content={projectNames.toString()} />;
    }
    return '';
  };

  const handleSearchUsers = searchedValue => {
    let res = allColleagues.filter(val => val.name.toLowerCase().startsWith(searchedValue.toLowerCase()));
    tableRef.current.dataManager.changePageSize(res.length);
    setColleagues(res);
  };

  const getProjectList = async () => {
    const _projectList = await ServiceProjects.getProjectList();
    setProjectList(_projectList);
  };

  const onAddMember = async () => {
    if (!showModalAddMember) {
      setShowModalAddMember(true);
      setCurrentMemberToEdit(null);
      await getProjectList();
    }
  };

  const onEditMember = async currentMember => {
    setScreenLoader(true);
    setShowModalAddMember(true);
    setCurrentMemberToEdit(currentMember);
    await getProjectList();
    setScreenLoader(false);
  };

  const updateMemberLocally = (updatedMember) => {
    let _colleagues = [...colleagues]
    const updatedIdx = _colleagues.findIndex(c => c._id === updatedMember?._id);
    _colleagues[updatedIdx] = updatedMember;
    
    setColleaguesLocally(_colleagues);
    setCurrentMemberToEdit(null);
  } 

  const addMemberLocally = async (newMember) => {
    await getProjectList();
    let _colleagues = [...colleagues]
    const updatedIdx = _colleagues.findIndex(c => c._id === newMember?._id);
    
    if(updatedIdx > -1) _colleagues[updatedIdx] = newMember;
    else _colleagues = [newMember, ...colleagues];

    setColleaguesLocally(_colleagues);
    setCurrentMemberToEdit(null);
  } 

  const setColleaguesLocally = (_colleagues) => {
    tableRef.current.dataManager.changePageSize(_colleagues.length);
    setColleagues(_colleagues);
    setAllColleagues(_colleagues);
  }

  const toggleBtnLoader = (value) => setSubmitBtnLoader(value);

  return (
    <>
    <div>{screenLoader && <Loading />}</div>
    <AppLayout title="Användare">
      <div className="internChatMenuContainer">
        <div className="tab-container">
          <Tabs tabs={['Alla']} selected={0} />
          {appState.isManagementRole() && (
            <div className="tab-actions">
              <BtButton onClick={onAddMember}>Lägg till användare</BtButton>
            </div>
          )}
        </div>

        <SearchBarWithActions onChange={handleSearchUsers} />
        <div className="internTableContainer">
          <MaterialTable
            localization={{ body: { emptyDataSourceMessage: 'Kunde inte hitta något resultat' } }}
            options={{
              sorting: false,
              draggable: false,
              search: false,
              toolbar: false,
              detailPanelColumnStyle: { display: 'none' },
              rowStyle: () => ({
                color: 'var(--gray-800)',
              }),
            }}
            tableRef={tableRef}
            style={{ margin: '25px 0px' }}
            columns={getColumns()}
            data={colleagues}
            components={{
              Pagination: () => null,
            }}
            isLoading={loading}
            onRowClick={(e, rowData) => {
              if(!rowData?.isActive && rowData.invitationCode) return;
              const orgSlug = appState.getCurrentOrgSlug();
              history.push(`/${orgSlug}/chat/${rowData.slug}`);
              onInternClick(rowData?._id);
            }}
          ></MaterialTable>
        </div>
      </div>
      <Modal
        title = {currentMemberToEdit ? "Redigera användare" : "Lägg till användare"} 
        center
        name="modal-add-member"
        show={showModalAddMember}
        buttonInfo={{ 
          label: currentMemberToEdit ? 'Spara' : 'Lägg till', 
          action: () => appAddMemberRef?.current?.handleSubmit(), 
          loaderShow: submitBtnLoader,
          disabled:submitBtnLoader
        }}
        setShow={() => {
          if(submitBtnLoader) return;
          setShowModalAddMember(false)
          setTimeout(() => {
            if (appAddMemberRef?.current?.reset) appAddMemberRef.current.reset();
            setCurrentMemberToEdit(null);
          }, 300);
        }}
      >
        <AddMember
          projects={projectList}
          currentMember={currentMemberToEdit}
          ref={appAddMemberRef}
          intl={intl}
          updateMemberLocally={updateMemberLocally}
          addMemberLocally={addMemberLocally}
          onClose={() => {
            setShowModalAddMember(false)
            setTimeout(() => {
              if (appAddMemberRef?.current?.reset) appAddMemberRef.current.reset();
              setCurrentMemberToEdit(null);
            }, 300);
          }}
          toggleBtnLoader={toggleBtnLoader}
        />
      </Modal>
    </AppLayout>
    </>
  );
};

export default PageProjectInternList;
