import axios from "axios";
import BaseService from "./baseService";

class ContractService extends BaseService {
  async uploadContract(file, slug, areaId) {
    const payload = {
      areaId,
      file,
    };
    const formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    const response = await axios.post(
      "/api/project/" + slug + "/manualContract",
      formData
    );
    return response.data.data;
  }

  async deleteContract(slug, areaId, filePath) {
    const payload = {
      areaId,
      filePath,
    };
    const response = await axios.delete(
      "/api/project/" + slug + "/manualContract",
      {
        data: payload
      }
    );
    return response.data.data;
  }
}

export const ManualContractService = new ContractService();
