import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { areEqual } from '../../../util';
import './OverflowTip.css';

const OverflowTip = ({ content = '', overflowLength = 20 }) => {
  useEffect(() => getString(content), [content]);

  const [textToShow, setText] = useState('');
  const [hiddenTextCount, setHiddenTextCount] = useState(0);

  const getString = _str => {
    let str = _str.split(',');
    let textToShow = [];
    let textToHide = [];

    if (str.length) {
      str.forEach(a => {
        const _textToShow = textToShow.toString();
        if ((_textToShow + a).length > overflowLength) textToHide.push(a);
        else textToShow.push(a);
      });

      setText(textToShow.toString());
      setHiddenTextCount(textToHide.length);
    }
  };

  return (
    <Tooltip title={content} interactive disableHoverListener={!hiddenTextCount}>
      <div className="overflowTip-container">
        {textToShow}
        {hiddenTextCount > 0 && (
          <div className="overflow-tip-hiddenCount">{`\t+${hiddenTextCount} fler`}</div>
        )}
      </div>
    </Tooltip>
  );
};

export default React.memo(OverflowTip, areEqual);
