import axios from "axios";
import { BehaviorSubject } from "rxjs";
import BaseService from "./baseService";
import { ServiceProjects } from "./service-projects";

export class ServiceContractController extends BaseService {
  data$ = new BehaviorSubject([]);
  storage = [];
  current;

  async sendContract(
    projectId,
    areaId,
    title,
    file,
    supplierId,
    internUsers,
    contactPersons,
    signingMethod,
    attachments,
    amount,
    monitorSupplier,
  ) {
    let data = {
      projectId,
      areaId,
      title,
      file,
      supplierId,
      internUsers:JSON.stringify(internUsers),
      contactPersons:JSON.stringify(contactPersons),
      signingMethod,
      ...(attachments.reduce((prev, attachment, index) => {
        prev["attachment_" + index] = attachment; return prev;
      }, {})),
      amount,
      monitorSupplier,  
    };
    const formData = new FormData();
    for (let key in data) {
      if (data[key] != undefined && data[key] != null) formData.append(key, data[key]);
    }
    const response = await axios.post("/api/contract/upload", formData);
    return response.data;
  }

  async getStatus(documentId) {
    const response = await axios.get("/api/contract/status", {params: {documentId}});
    return response.data;
  }

  async sendAgain(email, documentId, projectId, areaId) {
    const response = await axios.post('/api/contract/resend', {
      email,
      documentId,
      projectId,
      areaId,
    });

    return response.data;
  }

  async sendAgainSalesOrgContract(email, documentId, organizationNumber) {
    const response = await axios.post('/api/sales/contract/resend', {
      email,
      documentId,
      organizationNumber,
    });

    return response.data;
  }

  async updateContractDetails(documentId, projectId, areaId, amount, manualContractFilePath) {
    const response = await axios.put('/api/contract/details', {
      documentId,
      projectId,
      areaId,
      manualContractFilePath,
      amount,
    });
    return response.data;
  }
  
}

export const ServiceContract = new ServiceContractController();
