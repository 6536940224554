import { FC, useCallback, useEffect, useMemo, useState } from "react";
import appState from "../../state/AppStateContainer";
import serviceSupplierMonitoring, { MonitoredSupplier, Result, Sort } from "../../services/service-supplier-monitoring";
import _ from "lodash";
import sv from 'date-fns/locale/sv';
import { format } from 'date-fns';
import StyledCheckbox from "../../components/shared/StyledCheckbox/StyledCheckbox";
import Modal from "../../components/shared/Modal/Modal";
import BtButton from "../bt-button/bt-button";
import { SearchFieldWithDropdown } from "./SearchFieldWithDropdown";
import { useDebouncedState } from "../../util/hooks";
import { NotificationManager } from "react-notifications";
import * as Sentry from "@sentry/react";
import { CustomSort } from "../../components/shared/CustomSort";
import DataTable from "../../components/shared/DataTable/DataTable";
import styles from "./MonitoredSupplierTab.module.scss";
import { TabPage } from "../../components/shared/PageWithTabs/TabPage";
import { TabsType } from "../../components/shared/PageWithTabs/PageWithTabs";

type SupplierList = {
  [key: string]: {
    supplierInfo: {
      name: string,
      organizationNumber: string,
      id: string,
    },
    projectAreaInfo: {
      projectInfo?: {
        name: string,
        id: string,
      },
      areaInfo?: {
        name: string,
        id: string,
      }[]
    }[],
  }
}

type Props = {
  tabs: TabsType,
}

export const MonitoredSupplierTab: FC<Props> = ({ tabs }) => {
  const [fetchingEntities, setFetchingEntities] = useState(false);
  const [addingEntities, setAddingEntities] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [supplierSort, setEntitySort] = useState<Sort>();
  const [entityToAdd, setEntityToAdd] = useState<Result>({} as Result);
  const [removingEntities, setRemovingEntities] = useState(false);
  const [searchValue, setSearchValue, debouncedSearchValue] = useDebouncedState("", 500);
  const [monitoredSuppliers, setMonitoredSuppliers] = useState<MonitoredSupplier[]>([]);
  const [selectedSupplierOrgNumbers, setSelectedSupplierOrgNumbers] = useState<string[]>([]);
  const isWriteAccessRole = appState.isWriteAccessRole();

  const deleteSelectedEntities = useCallback(async () => {
    setRemovingEntities(true);
    try {
      const newMonitoredEntities = await serviceSupplierMonitoring.removeMonitoredSuppliers(selectedSupplierOrgNumbers);
      setMonitoredSuppliers(newMonitoredEntities);
      NotificationManager.success(`Leverantör${selectedSupplierOrgNumbers.length > 1 ? 'erna' : 'en'} har tagits bort`, 'Borttaget');
      setSelectedSupplierOrgNumbers([]);
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      setRemovingEntities(false);
    }
  }, [selectedSupplierOrgNumbers, monitoredSuppliers]);

  const supplierActions = useMemo(() => {
    if (isWriteAccessRole) {
      return (
        <div className={styles.searchContainer}>
          {(selectedSupplierOrgNumbers?.length > 0 ? (
            <>
              <BtButton color="white" onClick={deleteSelectedEntities} loaderShow={removingEntities}>
                Sluta bevaka {selectedSupplierOrgNumbers.length} leverantör{selectedSupplierOrgNumbers.length > 1 ? 'er' : ''}
              </BtButton>
              <BtButton color="white" onClick={() => setSelectedSupplierOrgNumbers([])}>
                Avbryt
              </BtButton>
            </>
          ) : (
            <>
              <span>
                Lägg till:
              </span>
              <SearchFieldWithDropdown
                value={searchValue}
                onChange={setSearchValue}
                debouncedValue={debouncedSearchValue}
                fetchSearchResults={async (value) => {
                  return await serviceSupplierMonitoring.search(value);
                }}
                onSelect={(entity) => {
                  setShowModal(true);
                  setEntityToAdd(entity);
                }}
              />
            </>
          ))}
        </div>
      );
    }
  }, [isWriteAccessRole, selectedSupplierOrgNumbers, removingEntities, searchValue, debouncedSearchValue]);

  useEffect(() => {
    (async () => {
      setFetchingEntities(true);
      const monitoredEntities = await serviceSupplierMonitoring.fetchMonitoredSuppliers(supplierSort);
      setMonitoredSuppliers(monitoredEntities);
      setFetchingEntities(false);
    })()
  }, [supplierSort]);

  const supplierColumns = useMemo(() => [
    {
      title: (
        <span>
          {isWriteAccessRole && (
            <label
              onClick={e => e.stopPropagation()}
              style={{
                display: monitoredSuppliers?.length > 0 ? 'inline' : 'none'
              }}>
              <StyledCheckbox
                checked={selectedSupplierOrgNumbers.length === monitoredSuppliers.length}
                onChange={() => setSelectedSupplierOrgNumbers(selectedSupplierOrgNumbers.length === monitoredSuppliers.length ? [] : monitoredSuppliers.map(x => x.organizationNumber))}
              />
            </label>
          )}
          <CustomSort
            sort={supplierSort}
            setSort={setEntitySort}
            field="name"
          >
            Leverantör
          </CustomSort>
        </span>
      ),
      render: (row: MonitoredSupplier) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          {isWriteAccessRole && (
            <StyledCheckbox
              checked={selectedSupplierOrgNumbers.includes(row.organizationNumber)}
              onChange={() => setSelectedSupplierOrgNumbers(selectedSupplierOrgNumbers.includes(row.organizationNumber) ? selectedSupplierOrgNumbers.filter(x => x !== row.organizationNumber) : [...selectedSupplierOrgNumbers, row.organizationNumber])}
            />
          )}
          {row.name}
        </div>
      ),
    },
    {
      title: (
        <CustomSort
          sort={supplierSort}
          setSort={setEntitySort}
          field="organizationNumber"
        >
          Org.nr
        </CustomSort>
      ),
      field: "organizationNumber",
    },
    {
      title: (
        <CustomSort
          sort={supplierSort}
          setSort={setEntitySort}
          field="monitoredSince"
        >
          Bevakad sedan
        </CustomSort>
      ),
      field: "monitoredSince",
      render: (row: MonitoredSupplier) => (
        <span>{format(
          new Date(row.monitoredSince),
          'dd MMMM yyyy', {
          locale: sv
        }
        )}</span>
      )
    },
    {
      title: (
        <CustomSort
          sort={supplierSort}
          setSort={setEntitySort}
          field="lastEventDate"
        >
          Senaste händelse
        </CustomSort>
      ),
      field: "lastEventDate",
      render: (row: MonitoredSupplier) => (
        <span>{row.lastEventDate ? format(
          new Date(row.lastEventDate),
          'dd MMMM yyyy', {
          locale: sv
        }
        ) : '-'}</span>
      )
    },
  ], [selectedSupplierOrgNumbers, monitoredSuppliers, supplierSort, isWriteAccessRole]);

  const addEntityToList = useCallback(async () => {
    setAddingEntities(true);
    if (!entityToAdd.tags.type) {
      setAddingEntities(false);
      return;
    }
    const data: {
      projectId?: string,
      supplierId?: string
      userId?: string,
    } = {};
    switch (entityToAdd.tags.type) {
      case "Project":
        data.projectId = entityToAdd.id;
        break;
      case "Supplier":
        data.supplierId = entityToAdd.id;
        break;
      case "User":
        data.userId = entityToAdd.id;
        break;
    }

    try {
      setFetchingEntities(true);
      const entities = await serviceSupplierMonitoring.addMonitoredEntity(data);
      setMonitoredSuppliers(entities);
      setFetchingEntities(false);
      setAddingEntities(false);
      setShowModal(false);
      setSearchValue('');
      NotificationManager.success('Lagt till i listan', 'Lyckat');
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      setFetchingEntities(false);
      setAddingEntities(false);
    }
  }, [entityToAdd]);

  const supplierList = useMemo(() => {
    if (_.isEmpty(entityToAdd?.tags?.suppliers)) {
      return [];
    }

    const supplierMap = entityToAdd.tags.suppliers.reduce((s, x) => {
      if (!s[x.supplierInfo.id]) {
        const projectAreaInfo: {
          projectInfo?: {
            name: string;
            id: string;
          };
          areaInfo?: {
            name: string;
            id: string;
          }[];
        } = {};
        if (x.projectInfo) {
          projectAreaInfo.projectInfo = x.projectInfo;
        }
        if (x.areaInfo) {
          projectAreaInfo.areaInfo = [x.areaInfo];
        }
        s[x.supplierInfo.id] = {
          supplierInfo: x.supplierInfo,
          projectAreaInfo: !_.isEmpty(projectAreaInfo) ? [projectAreaInfo] : [],
        }
      } else {
        const projectIndex = s[x.supplierInfo.id].projectAreaInfo.findIndex(p => p.projectInfo?.id === x.projectInfo?.id);
        if (projectIndex != -1) {
          if (x.areaInfo) {
            s[x.supplierInfo.id].projectAreaInfo[projectIndex].areaInfo!.push(x.areaInfo);
          }
        } else {
          const object: {
            projectInfo?: { name: string, id: string },
            areaInfo?: { name: string, id: string }[]
          } = {
            projectInfo: x.projectInfo,
          };
          if (x.areaInfo) {
            object.areaInfo = [x.areaInfo];
          }
          s[x.supplierInfo.id].projectAreaInfo.push(object);
        }
      }
      return s;
    }, {} as SupplierList);
    return Object.values(supplierMap);
  }, [entityToAdd]);

  return (
    <TabPage tabs={tabs} title="Leverantörsbevakning" actions={supplierActions}>
      {entityToAdd && (
        <div>
          <DataTable
            columns={supplierColumns}
            data={monitoredSuppliers}
            isLoading={fetchingEntities}
            pageSize={10}
          />
          <Modal
            title={`Bevaka ${supplierList.length} leverantör${supplierList.length !== 1 ? 'er' : ''}`}
            show={showModal}
            setShow={setShowModal}
            buttonInfo={{ label: "Lägg till", loaderShow: addingEntities, action: addEntityToList }}
            hasCloseButton={false}
          >
            <div className={styles.confirmationDialog}>
              {entityToAdd.tags?.type === 'Project' && <span>Lägger till vinnande leverantörer med kontrakt från {entityToAdd.title}.</span>}
              {entityToAdd.tags?.type === 'User' && <span>Lägger till vinnande leverantörer med kontrakt från inköp som {entityToAdd.title} är ansvarig för.</span>}
              {supplierList.map(s => (
                <div key={s.supplierInfo.id} className={styles.confirmationItem}>
                  <p>{s.supplierInfo.name} ({s.supplierInfo.organizationNumber})</p>
                  {!_.isEmpty(s.projectAreaInfo) && <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    {s.projectAreaInfo.map(pa => {
                      return (
                        <div key={`${pa.projectInfo?.id}.${pa.areaInfo ? pa.areaInfo[0].id : ''}`} style={{ display: 'flex', gap: '8px' }}>
                          {!!pa.projectInfo && <span>{pa.projectInfo.name}</span>}
                          {!!pa.areaInfo && !_.isEmpty(pa.areaInfo) && <>{!!pa.projectInfo && <span>/</span>} <span>{pa.areaInfo.map(a => a.name).join("/ ")}</span></>}
                        </div>
                      )
                    })}
                  </div>}
                </div>
              ))}
            </div>
          </Modal>
        </div>
      )}
    </TabPage>
  )
}