import { FC, useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/layout/app-layout/app-layout";
import Tabs from "../../components/shared/Tabs";
import DataTable from "../../components/shared/DataTable/DataTable";
import { ServiceQuotation } from "../../services/service-quotation";
import moment from "moment";
import { getDaysLeftWithTextV2 } from "../../util";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Modal from "../../components/shared/Modal/Modal";
// import { ReactComponent as CircleQuestionIcon } from '../../assets/svg/circleQuestionIcon.svg';
// import { ReactComponent as CircleXIcon } from '../../assets/svg/circleXIcon.svg';
// import { ReactComponent as CircleCheckIcon } from '../../assets/svg/circleCheckIcon.svg';
// import { BidIntentStatus } from "../page-quotation/BidIntentControls/BidIntentControls";
// import TooltipContainer from "../../components/shared/TooltipContainer/TooltipContainer";
import Loading from "../../components/shared/Loading";
import { NotificationManager } from "react-notifications";
import appState from "../../state/AppStateContainer";
import Card from "../../components/shared/Card/card";
import BtButton from "../bt-button/bt-button";
import serviceSales, { Subscription } from "../../services/service-sales";

type Params = {};
type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
}

const formatDate = (date: string) => {
  return date && moment(date).locale('sv').format('D MMMM YYYY');
};

type OpenRequest = {
  categoryName: string,
  projectName: string,
  orgName: string,
  category: {
    name: string,
    id: string,
  },
  org: {
    name: string,
    id: string,
  },
  project: {
    name: string,
    id: string,
  },
  baseURL: string,
  date: string,
  id: string,
  deliveryEnd?: string,
  deliveryStart?: string,
  timeLeft: string,
  version: number,
}

const fetchRequests = async () => {
  const response = await ServiceQuotation.fetchAllPublicQuotations();
  const requests = response?.data?.map((request: OpenRequest): OpenRequest => {
    return {
      ...request,
      projectName: request?.project?.name,
      categoryName: request?.category?.name,
      orgName: request?.org?.name
    }
  });

  return requests;
}

const PagePublicBidRequestList: FC<Props> = ({ history }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [requests, setRequests] = useState<OpenRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [startSubscriptionShow, setStartSubscriptionShow] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();

  const hasActiveSubscription = !!subscription?.active?.startDate;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const subscription = await serviceSales.fetchPublishedQuotationsSubscription();
        setSubscription(subscription);
        const requests = await fetchRequests();
        setRequests(requests);
      } catch (e) {
        NotificationManager.error((e as Error).toString(), "Kunde inte hämta förfrågningar");
      }
      setLoading(false);
    })();
  }, []);

  const onRowClick = useCallback((_, rowData) => {
    history.push(`/${appState.getCurrentOrgSlug()}/published-requests/${rowData.org.id}/${rowData.project.id}/${rowData.category.id}`);
  }, []);

  const onSubscribe = async () => {
    setLoading(true);
    const subscription = await serviceSales.orderPublishedQuotationsSubscription();
    setSubscription(subscription);
    const requests = await fetchRequests();
    setRequests(requests);
    setStartSubscriptionShow(false);
    setLoading(false);
    if (subscription?.active?.startDate) {
      //  setShowAllRequests(true);
    }
    NotificationManager.success("Prenumerationen kommer förnyas automatiskt ett år i taget.", "Sparat");
  }

  const columns = [
    {
      title: "Projekt",
      field: "projectName",
      render: (rowData: OpenRequest) => {
        return (
          <span style={{ color: 'var(--gray-900)', fontSize: '16px', fontWeight: 500, }}>
            {rowData.projectName}
          </span>
        );
      },
    },
    {
      title: "Beställare",
      field: "orgName",
    },
    {
      title: "Yrkeskategori",
      field: "categoryName",
    },
    {
      title: "Region",
      field: "region",
    },
    {
      title: "Version",
      field: "version",
      render: (rowData: OpenRequest) => +rowData.version + 1,
    },
    {
      title: "Skickad",
      field: "date",
      render: (rowData: OpenRequest) => formatDate(rowData.date),
    },
    {
      title: "Anbudstid",
      field: "timeLeft",
      render: (rowData: OpenRequest) => <span style={{ whiteSpace: 'nowrap' }}>{getDaysLeftWithTextV2(rowData.timeLeft)?.daysLeftText}</span>,
    },
    {
      title: "Leveransdatum",
      field: "deliveryStart",
      render: (rowData: OpenRequest) => {
        const d1 = formatDate(rowData.deliveryStart || '');
        const d2 = formatDate(rowData.deliveryEnd || '');

        if (!d1 && !d2) return '–';

        return [d1, d2].filter(x => x).join(" – ");
      }
    },
    // {
    //   title: "Anbud lämnat",
    //   field: "answered",
    //   render: (rowData: Request) => rowData.answered ? "Ja" : "Nej",
    // },
  ].filter(column => {
    if (hasActiveSubscription) {
      return true;
    } else {
      return ["categoryName", "region", "timeLeft"].includes(column.field);
    }
  });

  return (
    <AppLayout title="Publicerade förfrågningar">
      <div style={{ margin: "32px" }}>
        <div className="tab-container">
          <Tabs
            tabs={["Översikt",]}
            selected={selectedTab}
            onChange={tab => {
              setSelectedTab(tab);
            }}
          />
          <div className="tab-actions">
          </div>
        </div>

        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}><Loading type='inline' /></div>
        ) : (
          selectedTab == 0 && (<>
            {!hasActiveSubscription && (
              <Card>
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  color: "var(--gray-700)",
                }}>
                  <div style={{ fontSize: "14px", color: "var(--gray-700)", flexGrow: 1 }}>
                    <h3 style={{ fontSize: "18px", color: "var(--gray-900)", fontWeight: 500 }}>Publicerade förfrågningar</h3>
                    <p><b>Prenumerera på "Publicerade förfrågningar" och hitta nya kunder och projekt från privata bygg- och fastighetsbolag</b></p>
                    <p>Tjänsten "Publicerade förfrågningar" ger dig exklusiv tillgång till projekt som inte är offentliga. Gå inte miste om chansen att:</p>
                    <ul>
                      <li><b>Öka din chans att vinna anbud:</b> Konkurrera på projekt som inte är öppna för alla och stärk din position på marknaden.</li>
                      <li><b>Få en fördel gentemot dina konkurrenter:</b> Var bland de första att ta del av nya förfrågningar och ligg steget före.</li>
                      <li><b>Utöka din kundbas:</b> Hitta nya beställare och nå nya affärsmöjligheter som du inte hade kunnat nå annars.</li>
                    </ul>
                    <p>"Publicerade förfrågningar" kostar 800 kr/månad. Du kan enkelt teckna en prenumeration genom att klicka på knappen nedan.</p>
                    
                    <div style={{display:"flex", justifyContent:"center", marginTop: "32px"}}>
                      <BtButton onClick={() => setStartSubscriptionShow(true)}>Starta prenumeration</BtButton>
                    </div>
                  </div>
                </div>
              </Card>
            )}
            <DataTable columns={columns} data={requests} isLoading={loading} onRowClick={hasActiveSubscription ? onRowClick : undefined} pageSize={10} isScrollable />
          </>)
        )}
      </div>
      <Modal
        title="Beställ prenumeration"
        show={startSubscriptionShow}
        setShow={setStartSubscriptionShow}
        buttonInfo={{ label: "Bekräfta köp", action: onSubscribe }}
      >
        <div style={{ fontSize: "14px", color: "var(--gray-700)", maxWidth: "500px" }}>
          Tjänsten "visa publicerade förfrågningar" är en prenumerationstjänst som betalas årsvis och förnyas automatiskt.

          <h3>Beställning</h3>
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Visa publicerade förfrågningar, 12 månader</div>
              <div>800 kr/månad</div>
              <div>9 600 kr</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Moms</div>
              <div>2 400 kr</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", fontWeight: 500, color: "var(--gray-900)" }}>
              <div>Totalt</div>
              <div>12 000 kr</div>
            </div>
          </div>
        </div>
      </Modal>
    </AppLayout>
  );
}

export default withRouter(PagePublicBidRequestList);