import { FC, ReactNode } from "react";
import TooltipContainer from "../TooltipContainer/TooltipContainer";
import FileButton from "../../File/File";
import BtButton from "../../../ts-components/bt-button/bt-button";
import { ReactComponent as ClipIcon } from '../../../assets/svg/clip.svg';
import moment from "moment";
import { FileScope } from "../../../util/appEnum";
import { ScopeOptions } from "../../../models/global.model";
import { NotificationManager } from 'react-notifications';
import { humanizeBytes } from "../../../util";

export type ExistingFile = {
  fileName: string,
  file: string,
  mimeType: string,
  uploadedBy?: string,
  uploadedAt?: Date,
}

export type NewFile = {
  key: string,
  file: File,
}

type Props = {
  existingFiles: ExistingFile[],
  setExistingFiles: (value: ExistingFile[]) => void,
  newFiles: NewFile[],
  setNewFiles: (value: NewFile[]) => void,
  icon?: ReactNode,
  label?: string, 
  inlineButton?: boolean,
  accept?: string,
  readonly?: boolean,
  scope?: FileScope,
  scopeOptions?: ScopeOptions
  maxSize?: number,
  single?: boolean,
  hasRemoveAllButton?: boolean,
}

const FileUpload : FC<Props> = ({
  existingFiles,
  setExistingFiles,
  newFiles,
  setNewFiles,
  icon,
  label,
  inlineButton,
  accept,
  readonly,
  scope,
  scopeOptions,
  maxSize,
  single,
  hasRemoveAllButton,
}) => {
  const removeExistingFile = (index: number) => {
    setExistingFiles(existingFiles.filter((_, i) => i != index));
  };
  const removeNewFile = (index: number) => {
    setNewFiles(newFiles.filter((_, i) => i != index));
  };
  const renderAddButton = () => (    
    <label style={{ letterSpacing: 'normal' }}>
      <BtButton 
        color="white" 
        size="xxs"
        leftIcon={icon || 
          <ClipIcon style={{ width: '15px', height: '15px', fill: 'var(--gray-700)' }} />
        }
        style={{ width: 'fit-content' }}
      >
        {label || `Lägg till bilagor`}
        <input
          type="file"
          onChange={(e) => {
            const target = e.target as HTMLInputElement;
            if (target.files) {
              let files = Array.from(target.files);
              if (maxSize) {
                const tooBigFiles = files.filter(f => f.size > maxSize);
                if (tooBigFiles.length) {
                  NotificationManager.error(tooBigFiles.length + " fil" + (tooBigFiles.length > 1 ? "er" : "") + " var större än " + humanizeBytes(maxSize) + ".", "För stor fil")
                  files = files.filter(f => f.size <= maxSize);
                }
              }
              setNewFiles([...newFiles, ...files.map((file, index) => ({ file, key: `${Date.now()}_${index}` }))]);
              target.value = "";
            }
          }}
          style={{ display: 'none' }}
          multiple={!single}
          accept={accept}
        />
      </BtButton>
    </label>
  );

  const numberOfFiles = (existingFiles || []).length + (newFiles || []).length;
  const empty = numberOfFiles == 0;
  return (
    <>
      <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap', marginBottom: "12px", alignItems: 'center' }}>
        {existingFiles?.map((file, index) => 
          <TooltipContainer
            key={file?.file || index}
            delayed
            renderReferenceComponent={(className, ref) => (
              <FileButton
                style={{ placeItems: 'flex-start' }}
                className={className}
                ref={ref}
                file={file?.file || ''}
                fileName={file.fileName}
                disabled={!file.file}
                onRemove={readonly ? undefined : () => removeExistingFile(index)}
                scope={scope}
                scopeOptions={scopeOptions}
              />
            )}
          >
            {file.uploadedBy && file.uploadedAt ? <span style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>{`Tillagd ${moment(file.uploadedAt).format('YYYY-MM-DD HH:mm')} av ${file.uploadedBy}`}</span> : ''}
          </TooltipContainer>
        )}
        {newFiles.map((newFile, index) => 
          <FileButton
            key={newFile.key}
            file={''}
            blob={newFile.file}
            fileName={newFile.file.name}
            onRemove={readonly ? undefined : () => removeNewFile(index)}
          />
        )}
        {inlineButton && !readonly && (!single || empty) && renderAddButton()}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px', width: '100%' }}>
        {!inlineButton && !readonly && (!single || empty) && renderAddButton()}
        {hasRemoveAllButton && !readonly && !single && numberOfFiles > 1 && (
          <a
            href="#"
            style={{ color: 'var(--gray-700)', fontSize: '12px' }}
            onClick={(e) => {
              e.preventDefault();
              setExistingFiles([]);
              setNewFiles([]);
            }}
          >
            Ta bort alla
          </a>
        )}
      </div>
    </>
  )
}

export default FileUpload;