import styles from './page-bid-request.module.scss';
import { useEffect, useState, FC, SetStateAction, ReactNode, useContext } from 'react';
import BackButton from '../../components/shared/BackButton/BackButton';
import { RouteComponentProps } from 'react-router-dom';
import Collapsible from '../../components/shared/Collapsible';
import StyledCheckbox from '../../components/shared/StyledCheckbox/StyledCheckbox';
import { ServiceSupplier } from '../../services/service-supplier';
import { ServiceQuotation } from '../../services/service-quotation';
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';
import BlockIcon from '@material-ui/icons/Block';
import HelpIcon from '@material-ui/icons/Help';
import SupplierBlockedReasons from '../app-supplier-database/supplier-blocked-reasons';
import { Member, Quotation, Supplier } from '../../models/supplier.model';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DatePicker from '../../components/shared/DatePicker/DatePicker';
import TitleWrapper from '../../components/shared/TitleWrapper/TitleWrapper';
import Editor from '../../components/shared/Editor/Editor';
import BtButton from '../bt-button/bt-button';
import { ReactComponent as ClipIcon } from '../../assets/svg/clip.svg';
import { OptionType, OptionTypeWithRequired } from '../../models/global.model';
import { allowedFileExtensions, } from "../../services/service-check-file-extension";
import { NotificationManager } from "react-notifications";
import moment from 'moment';
import { DemandType } from '../../models/demand.model';
import { PriceDetailType } from '../../models/priceType.model';
import DraggableDropdown from '../../components/shared/DraggableDropdown/draggable-dropdown';
import AddCircle from '@material-ui/icons/AddCircle';
import { CustomLink } from '../../components/routes';
import appState from '../../state/AppStateContainer';
import { ServiceProjects } from '../../services/service-projects';
import AppLayout from '../../components/layout/app-layout/app-layout';
import _ from 'lodash';
import EmailTemplate from '../../components/shared/EmailTemplate/EmailTemplate';
import ScrollToTop from '../scroll-to-top/scroll-to-top';
import { FileScope } from '../../util/appEnum';
import SupplierMemberContainer from '../page-quotation/ContactInfoForm/SupplierMemberContainer/SupplierMemberContainer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from 'axios';
import FileUpload, { ExistingFile, NewFile } from '../../components/shared/FileUpload/FileUpload';
import { BidRequestDraftsContext } from '../../App';
import Modal from '../../components/shared/Modal/Modal';
import { Loading } from '../../components/shared';
// import { TrashIcon } from '../../components/svgs';
import * as Sentry from "@sentry/react";

type Params = {
  areaSlug: string;
  project: string;
  version: string;
};

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
  match: RouteTypes['match'];
  currentProject: {
    _id: string,
    name: string,
    slug: string,
    area: {
      areaId: { slug: string, name: string, _id: string },
      suppliers: {
        supplierId: { _id: string }
      }[],
      answersLatestBy?: Date, 
      isPublic?: boolean,
    }[]
  },
};

type SupplierContactWithState = Member & {
  isRemoved?: boolean,
  isOnEditingMode?: boolean,
  errorMessages?: Omit<Member, '_id'>,
  isNew?: boolean,
  isSelected?: boolean,
}

const newContact = (supplierId: string) => ({
  _id: `new_${Date.now()}`,
  name: '',
  email: '',
  phone: '',
  title: '',
  supplierId,
  isRemoved: false,
  isOnEditingMode: true,
  isNew: true,
  isSelected: false,
});

type DraftDecision = 'DRAFT' | 'NEW' | 'UNDECIDED';

const getDraftString = (projectSlug: string, areaSlug: string, quotationVersion: number) => {
  return `${projectSlug},${areaSlug},${quotationVersion}`;
}

const PageBidRequest: FC<Props> = ({ history, match, currentProject }) => {
  const area = currentProject.area.find(area => area.areaId.slug === match.params.areaSlug);
  const answersLatestBy = area?.answersLatestBy;
  const [bidRequestDrafts, setBidRequestDrafts] = useContext(BidRequestDraftsContext);

  const [projectName, setProjectName] = useState("");
  const [areaName, setAreaName] = useState<string | undefined>("");
  const [areSuppliersOpen, setAreSuppliersOpen] = useState(false);
  const [isMainFormOpen, setIsMainFormOpen] = useState(true);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [expirationDate, setExpirationDate] = useState<Date>(answersLatestBy ? new Date(answersLatestBy) : moment().add(1, 'day').endOf('day').toDate());
  const [infoText, setInfoText] = useState('');
  const [files, setFiles] = useState<ExistingFile[]>([]);
  const [newFiles, setNewFiles] = useState<NewFile[]>([]);
  const [demandOptions, setDemandOptions] = useState<OptionType[]>([]);
  const [priceTypeOptions, setPriceTypeOptions] = useState<OptionType[]>([]);
  const [priceTypeUnits, setPriceTypeUnits] = useState<OptionType[]>([]); 
  const [demands, setDemands] = useState<OptionTypeWithRequired[]>([]);
  const [priceTypes, setPriceTypes] = useState<OptionTypeWithRequired[]>([]);
  const [isEmailViewerOpen, setIsEmailViewerOpen] = useState(true);
  const [senderMsg, setSenderMsg] = useState('');
  const [publishLoader, setPublishLoader] = useState(false);
  const [notPublishLoader, setNotPublishLoader] = useState(false);
  const [fileScopeQuotationId, setFileScopeQuotationId] = useState(""); // quotationId of source quotation, to be able to access files
  const [fileScopeQuotationVersion, setFileScopeQuotationVersion] = useState(-1); // version of source quotation, to be able to access files
  const [supplierContacts, setSupplierContacts] = useState<SupplierContactWithState[]>([]);
  const [quotation, setQuotation] = useState<Quotation>();
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [draftDecision, setDraftDecision] = useState<DraftDecision>('UNDECIDED');
  const [isOldDraft, setIsOldDraft] = useState(false);
  const [isQuotationLoaded, setIsQuotationLoaded] = useState(false);
  const [initialLoadingCompleted, setInitialLoadingCompleted] = useState(false);
  const [infoTextHasLoaded, setInfoTextHasLoaded] = useState(false);
  const [senderMsgHasLoaded, setSenderMsgHasLoaded] = useState(false);
  const [shouldPublish, setShouldPublish] = useState(false);

  const draft = bidRequestDrafts?.[getDraftString(match.params.project, match.params.areaSlug, fileScopeQuotationVersion)];

  const fetchQuotation = async () => {
    const query = history.location.search;
    const copySource = query && query.substring(1).split("=")[1];
    const [ copySourceArea, copySourceVersion ] = copySource.split(":");
    let selectedContacts: {supplierId: string, _id: string}[] = [];
    try {
      const quotation = copySource ? 
        (await ServiceQuotation.getQuotation(
          match.params.project,
          copySourceArea,
          +copySourceVersion - 1,
        )) : (await ServiceQuotation.getQuotation(
          match.params.project,
          match.params.areaSlug,
          match.params.version == "new" ? "new" : parseInt(match.params.version) - 1,
        ));

      setQuotation(quotation as Quotation);

      selectedContacts = _.uniqBy((quotation as Quotation)?.suppliers, 'supplierMemberId').map(supplier => ({ supplierId: supplier.supplierId.toString(), _id: supplier.supplierMemberId.toString() }));


      if (quotation) {
        setSenderMsg(quotation.emailText || "");
        setExpirationDate(new Date(answersLatestBy ?? quotation.quotationExpiryDate))
        setInfoText(quotation.content);
        setFiles(quotation.attachments.map((file: any) => ({ ...file, file: file.fileId!, mimeType: file.mimeType! })) || []);
        filterAndSetDemands(quotation);
        filterAndSetPriceTypes(quotation);
        
        // For file scope
        setFileScopeQuotationId(quotation.refId ?? quotation._id ?? "");
        setFileScopeQuotationVersion(quotation.refVersion ?? quotation.__v ?? -1);

        setAreSuppliersOpen(!!copySource);
      } else {
        setAreSuppliersOpen(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.status === 404 && error?.response?.data?.error === "error.quotationNotFound") {
        setAreSuppliersOpen(true);
      } else {
        NotificationManager.error("" + error, "Oväntat fel");
        throw error;
      }
    }

    const area = currentProject.area.find(area => area.areaId.slug === match.params.areaSlug);
    setShouldPublish(!!area?.isPublic);
    const areaSuppliers = area?.suppliers?.map(supplier => supplier.supplierId._id);

    await Promise.all([
      fetchDemands(),
      fetchPriceTypes(),
      fetchPriceUnits(),
    ]);

    const suppliersWithMembers: {
      _id: Supplier,
      members: Member[]
    }[] = await ServiceSupplier.getSuppliersMembers(areaSuppliers) || [];

    let tempSupplierContacts: SupplierContactWithState[] = [];

    setSuppliers(
      suppliersWithMembers.map(({ _id, members }) => {
        tempSupplierContacts = [...tempSupplierContacts, ...members.map(m => ({ ...m, isSelected: false }))];
        return {
          _id: _id._id,
          name: _id.name,
          isBlacklist: _id.isBlacklist,
          blacklistReason: _id.blacklistReason,
        }
      })
    )

    if (selectedContacts?.length > 0) {
      selectedContacts.forEach(selectedContact => {
        const contactIndex = tempSupplierContacts.findIndex(_contact => _contact._id === selectedContact._id && _contact.supplierId === selectedContact.supplierId);

        if (contactIndex != -1) {
          tempSupplierContacts[contactIndex].isSelected = true;
        }
      })
    }

    setSupplierContacts(tempSupplierContacts);

    setProjectName(currentProject?.name);
    setAreaName(area?.areaId?.name);
    setIsQuotationLoaded(true);
  }

  const checkIfDraftIsValid = (draft: any) => {
    const { savedAt, ...draftToTest } = draft || {};
    if (draft) {

      savedAt;
      const stateObject = {
        expirationDate,
        infoText,
        files,
        newFiles,
        priceTypeUnits,
        demands,
        priceTypes,
        senderMsg,
        fileScopeQuotationId,
        fileScopeQuotationVersion,
        supplierContacts,
      };

      return JSON.stringify(draftToTest) !== JSON.stringify(stateObject);
    }

    return false;
  }

  useEffect(() => {
    fetchQuotation();
  }, []);

  const setStateOfDraftModal = () => {
    if (isQuotationLoaded && draftDecision === 'UNDECIDED') {
      const isDraftValid = checkIfDraftIsValid(draft);
  
      const newerVersionExists = moment((quotation as Quotation)?.quotationDate).isAfter(moment(draft?.savedAt));

      if (isDraftValid) {
        if (quotation && newerVersionExists) {
          setIsOldDraft(true);
        }

        setShowDraftModal(true);
      }

      if (!isDraftValid) {
        setDraftDecision('NEW');
      }
    }
  }

  useEffect(() => {
    if (!isQuotationLoaded || draftDecision !== 'UNDECIDED') return;
    if (!infoTextHasLoaded) return;
    if (!senderMsgHasLoaded) return;
    setStateOfDraftModal();
    setInitialLoadingCompleted(true);
  }, [infoTextHasLoaded, senderMsgHasLoaded]);

  const goBack = () => {
    const url = match.url.split('/');
    url.splice(5);
    history.push(url.join('/'));
  };

  const fetchDemands = async () => {
    let allDemands = await ServiceQuotation.getDemands();
    allDemands = allDemands.map((d: { _id: string, name: string }) => ({ label: d.name, value: d._id }));
    setDemandOptions(allDemands);
  }

  const fetchPriceTypes = async () => {
    let allPriceTypes = await ServiceQuotation.getPriceTypes();
    allPriceTypes = allPriceTypes.map((d: { _id: string, name: string }) => ({ label: d.name, value: d._id }));
    setPriceTypeOptions(allPriceTypes);
  }

  const fetchPriceUnits = async () => {
    let allUnits = await ServiceQuotation.getPriceUnits();
    allUnits = allUnits
      .map((x : {_id: string, name: string}) => x.name)
      .sort((a:string, b:string) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map((s : string) => ({ label: s, value: s }));
    setPriceTypeUnits(allUnits);
  }

  const filterAndSetDemands = (quotation: Quotation) => {
    setDemands((prevState) => {
      let filteredDemands = [...prevState];
      if (quotation?.demands) filteredDemands = quotation?.demands?.map((d: DemandType, index: number) => ({ label: d?.demandId.name, value: "existing"+index/*d?.demandId._id*/, required: d?.required  }));
      return filteredDemands;
    })
  }

  const filterAndSetPriceTypes = (quotation: Quotation) => {
    setPriceTypes((prevState) => {
      let filteredPriceTypes = [...prevState];
      if (quotation?.priceDetails) filteredPriceTypes = quotation?.priceDetails?.map((p: PriceDetailType, index: number) => ({ label: p?.priceType.name, value: "existing"+index/*p?.priceType._id*/, required: p?.required, unit: p.unit ?? "kr" }));
      return filteredPriceTypes;
    })
  }

  useEffect(() => {
    const units = priceTypes.reduce(
      (set, p) => p?.unit ? set.add(p?.unit) : set, 
      new Set(priceTypeUnits.map(option => option?.label ?? "kr"))
    );
    setPriceTypeUnits(
      Array.from(units)
        .sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map(s => ({label: s, value: s}))
    );
  }, [priceTypes]);

  const updateDraftWithLatestInfo = () => {
    if (isQuotationLoaded && draftDecision !== 'UNDECIDED') {
      if (initialLoadingCompleted) {
        setBidRequestDrafts((oldDrafts: any) => {
          const temp = {...oldDrafts};
          temp[getDraftString(match.params.project, match.params.areaSlug, fileScopeQuotationVersion)] = {
            expirationDate,
            infoText,
            files,
            newFiles,
            priceTypeUnits,
            demands,
            priceTypes,
            senderMsg,
            fileScopeQuotationId,
            fileScopeQuotationVersion,
            supplierContacts,
            savedAt: new Date(),
          };
    
          return temp;
        })
      }
    }
  }

  useEffect(() => {
    updateDraftWithLatestInfo();
  }, [
    expirationDate,
    infoText,
    files,
    newFiles,
    priceTypeUnits,
    demands,
    priceTypes,
    senderMsg,
    fileScopeQuotationId,
    fileScopeQuotationVersion,
    supplierContacts,
  ]);

  const renderRecipientsList = () => {
    const toggleMember = (contactId: string) => {
      setSupplierContacts(prevState => {
        const tempContacts = [...prevState];
        const index = tempContacts.findIndex(contact => contact._id === contactId);
        if (tempContacts[index]) {
          tempContacts[index].isSelected = !tempContacts[index].isSelected;
        }
        return tempContacts;
      })
    }

    const onChange = (member: SupplierContactWithState, values: Partial<Member>) => {
      setSupplierContacts(contacts => {
        const tempContacts = [...contacts];
        const contactIndex = tempContacts.findIndex(contact => contact._id === member._id && contact.supplierId === member.supplierId);
        tempContacts[contactIndex] = {
          ...tempContacts[contactIndex],
          ...values,
          errorMessages: {
            name: '',
            email: '',
            title: '',
            phone: '',
          },
        }
        return tempContacts;
      })
    }

    const onEdit = (member: SupplierContactWithState) => {
      setSupplierContacts(oldState => {
        const tempContacts = [...oldState];
        const contactIndex = tempContacts.findIndex(contact => contact._id === member._id && contact.supplierId === member.supplierId);

        tempContacts[contactIndex].isOnEditingMode = true;

        return tempContacts;
      });
    }

    const onRemove = (member: SupplierContactWithState, isRemoved: boolean) => {
      if (!member.isNew) {
        setSupplierContacts(prevState => {
          const tempContacts = [...prevState];

          const contactIndex = tempContacts.findIndex(contact => contact._id === member._id && contact.supplierId === member.supplierId);

          tempContacts[contactIndex].isRemoved = isRemoved;

          if (isRemoved) {
            tempContacts[contactIndex].isSelected = false;
            if (tempContacts.filter(contact => contact.supplierId === member.supplierId && contact.isNew).length === 0 && !tempContacts.find(contact => contact.supplierId === member.supplierId && !contact.isRemoved && !contact.isNew)) {
              tempContacts.push(newContact(member.supplierId!));
            }
          } else if (quotation) {
            const contactWasPartOfTheQuotation = _.uniqBy(quotation.suppliers, 'supplierMemberId')?.find(quotationSupplier => member.supplierId === quotationSupplier.supplierId.toString() && member._id == quotationSupplier.supplierMemberId.toString());
            
            if (contactWasPartOfTheQuotation) {
              tempContacts[contactIndex].isSelected = true;
            }
          }

          return tempContacts;
        });
      } else {
        setSupplierContacts(contacts => {
          const newContacts = contacts.filter(contact => !(contact._id === member._id && contact.supplierId === member.supplierId));
          if (newContacts.filter(contact => contact.supplierId === member.supplierId && contact.isNew).length === 0 && !contacts.find(contact => contact.supplierId === member.supplierId && !contact.isRemoved && !contact.isNew)) {
            newContacts.push(newContact(member.supplierId!))
          }
          return newContacts
        })
      }
    }

    return (
      <Collapsible
        header={(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong>{supplierContacts.filter(contact => !!contact.isSelected).length} mottagare </strong>
            <label>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px', cursor: 'pointer' }}>
                <StyledCheckbox
                  onChange={() => setShouldPublish(oldState => !oldState)}
                  checked={shouldPublish}
                />
                <span style={{ letterSpacing: 'normal', color: 'var(--gray-700)', fontSize: '14px', }}>Publicera förfrågan</span>
              </div>
            </label>
          </div>
        )}
        isOpen={areSuppliersOpen}
        onChangeCollapse={() => setAreSuppliersOpen(oldState => !oldState)}
      >
        {suppliers.map(({ _id, name, isBlacklist, blacklistReason }) =>
          <div key={_id} className={`${styles.supplierContainer} ${isBlacklist ? styles.isBlacklisted : {}}`}>
            <div className={styles.supplierNameContainer}>
              {isBlacklist &&
                <TooltipContainer renderReferenceComponent={(className, ref) =>
                  <BlockIcon innerRef={ref} className={`bid-card-blackList-icon ${styles.blackListIcon} ${className}`} />
                }>
                  {blacklistReason?.length > 0 && <SupplierBlockedReasons reasons={blacklistReason} />}
                </TooltipContainer>
              }
              <strong>{name}</strong>
            </div>

            <div className={styles.supplierMembersContainer}>
              {supplierContacts.filter(contact => contact.supplierId === _id).map(member =>
                <label key={`${member._id}${member.supplierId}`} className={styles.memberContainer}>
                  <StyledCheckbox
                    onChange={() => !member.isRemoved && toggleMember(member._id)}
                    checked={!!member?.isSelected}
                  />
                  <SupplierMemberContainer
                    isSupplierBlocked={isBlacklist}
                    key={`${member._id}${member.supplierId}`}
                    member={member}
                    errors={member?.errorMessages || {name: '', email: '', title: '', phone: ''}}
                    editMode={member.isOnEditingMode || !!member.isNew}
                    onChange={(values) => onChange(member, values)}
                    onEdit={() => onEdit(member)}
                    onRemove={!(supplierContacts.filter(contact => contact.supplierId === _id && !contact.isRemoved && member.isNew).length === 1 && member.isNew) ? (isRemoved) => onRemove(member, isRemoved) : undefined}
                  />
                </label>
              )}
              <BtButton 
                color="white" 
                size="xxs"
                leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
                style={{ width: 'fit-content' }}
                onClick={() => setSupplierContacts(oldState => [...oldState, newContact(_id)])}
              >
                Lägg till kontaktperson
              </BtButton>
            </div>
          </div>
        )}
      </Collapsible>
    )
  }

  const renderMainForm = () => {
    const addField = <T extends OptionType>(
      _list: T[],
      setList: (value: SetStateAction<OptionType[]>) => void
    ) => {
      setList((prevState: OptionType[]) => [...prevState, { label: '', value: "new" + Date.now().toString(), required: false, unit: "kr" }]);
    };

    const renderDraggableContainer = ({ title, buttonText, list, options, onChange, rightSideComponent, units }: {
      title: string,
      buttonText: string,
      list: OptionTypeWithRequired[],
      options: OptionType[],
      onChange: (value: SetStateAction<OptionType[]>) => void,
      rightSideComponent?: ReactNode,
      units?: OptionType[],
    }) => {
      return (
        <TitleWrapper title={title} className={styles.titleWrapper}>
          <div style={{ position: 'relative' }}>
            <div style={{ marginLeft: '-32px', marginRight: '-32px' }}>
              {rightSideComponent}
              <DraggableDropdown
                options={options}
                list={list}
                onChange={onChange}
                units={units}
              />
            </div>

            <BtButton class='white' size='xxs' onClick={() => addField(list, onChange)}>
              <AddCircle style={{ fontSize: '16px', fill: 'var(--orange-500)' }} />
              <span>{buttonText}</span>
            </BtButton>
          </div>
        </TitleWrapper>
      )
    }

    const renderProcTips = () => {
      return (
        <div className={styles.procTipsContainer}>
          <HelpIcon className={styles.icon} />
          <CustomLink
            to={`/projects/${match.params.project}/${match.params.areaSlug}/tips`}
            target="_blank"
          >
            <p>{`Se upphandlingstips för ${areaName}`}</p>
          </CustomLink>
        </div>
      );
    };

    return (
      <Collapsible
        header={<strong>Förfrågningsunderlag</strong>}
        isOpen={isMainFormOpen}
        onChangeCollapse={() => setIsMainFormOpen(oldState => !oldState)}
        contentContainerClassName={styles.mainForm}
      >
        <div className={styles.expirationDateContainer}>
          <div>
            <AccessTimeIcon style={{ width: '14px', height: '14px' }} />
            <span>Utgångstid:</span>
          </div>
          <DatePicker selected={expirationDate} onChange={setExpirationDate} wrapperClassName={styles.datePicker} />
        </div>
        <TitleWrapper title='INFORMATION' className={styles.titleWrapper} titleMargin={'12px'}>
          {isQuotationLoaded && <Editor
            value={infoText}
            onChange={setInfoText}
            wrapperStyle={{ height: '291px' }}
            onLoadContent={() => setInfoTextHasLoaded(true)}
          />}
        </TitleWrapper>
        <TitleWrapper title='HANDLINGAR' className={styles.titleWrapper} titleMargin={'20px'}>
          <FileUpload
            existingFiles={files}
            setExistingFiles={setFiles}
            newFiles={newFiles}
            setNewFiles={setNewFiles}
            icon={<ClipIcon style={{ width: '15px', height: '15px', fill: 'var(--gray-700)' }} />}
            scope={FileScope.BidRequestAttachment}
            scopeOptions={{quotationId: fileScopeQuotationId, versionNumber: fileScopeQuotationVersion}}
            accept={allowedFileExtensions.join(', ')}
            label='Bifoga handlingar'
            hasRemoveAllButton
          />
        </TitleWrapper>

        {renderDraggableContainer({
          title: 'PRISFÄLT',
          buttonText: 'Lägg till prisfält',
          list: priceTypes,
          options: priceTypeOptions,
          onChange: setPriceTypes,
          rightSideComponent: renderProcTips(),
          units: priceTypeUnits,
        })}
        {renderDraggableContainer({
          title: 'SKALLKRAV',
          buttonText: 'Lägg till skallkrav',
          list: demands,
          options: demandOptions,
          onChange: setDemands,
        })}
      </Collapsible>
    )
  }

  const renderEmailViewer = () => {
    const {
      name: authorName,
      phone: authorPhone
    } = appState.getLoggedInUserDetail() || {};
    const orgName = appState.getCurrentOrgName();
    return (
      <Collapsible
        header={<strong>E-postmeddelande</strong>}
        isOpen={isEmailViewerOpen}
        onChangeCollapse={() => setIsEmailViewerOpen(oldState => !oldState)}
      >
        <div className={styles.emailWrapper}>
          <EmailTemplate>
            <p>Hej <em>&lt;Förnamn&gt;,</em></p>

            <p>
              {authorName} på {orgName} har bjudit in dig att lämna anbud på {areaName} till
              projektet {projectName}. Anbud lämnas i det digitala inköpsverktyget Accurator där
              ni kan:
            </p>

            <ul style={{ paddingLeft: '35px' }}>
              <li>Ladda ner förfrågningsunderlaget.</li>
              <li>Ställa frågor och skicka meddelanden angående förfrågan.</li>
              <li>Lämna in ert anbud genom vårt formulär samt bifoga anbud i PDF-format.</li>
            </ul>

            <p>
              Du behöver inget konto för att använda Accurator, allt du behöver göra är att klicka
              på knappen nedan för att komma till anbudssidan:
            </p>

            <a style={{ alignSelf: 'center', backgroundColor: 'var(--orange-500)', padding: '16px', borderRadius: '6px', cursor: 'not-allowed' }}>
              <strong style={{ textDecoration: 'none', color: 'var(--white)' }}>
                Öppna anbudssidan i Accurator
              </strong>
            </a>

            <div>
              <strong>Anbudsinformation:</strong>
              <ul style={{ paddingLeft: '35px', marginTop: '5px', margin: 0 }}>
                <li>Beställare: {orgName}</li>
                <li>Projekt: {projectName}</li>
                <li>Kategori: {areaName}</li>
                <li>Utgångsdatum: {moment(expirationDate).locale('sv').format('YYYY-MM-DD') || ''}</li>
                <li>Version: <em>&lt;Version&gt;</em></li>
              </ul>
            </div>

            <div>
              <strong>Upphandlingsansvarig:</strong>
              <ul style={{ paddingLeft: '35px', marginTop: '5px', margin: 0 }}>
                <li>Namn: {authorName}</li>
                <li>Telefonnummer: {authorPhone || ''}</li>
                <li>Företag: {orgName}</li>
              </ul>
            </div>

            <div>
              <strong style={{ display: 'inline-block', marginBottom: '6px' }}>Meddelande från avsändaren:</strong>
              <br />
              {isQuotationLoaded && <Editor
                value={senderMsg}
                onChange={setSenderMsg}
                wrapperStyle={{ height: '400px' }}
                onLoadContent={() => {
                  setSenderMsgHasLoaded(true);
                }}
              />}
            </div>
          </EmailTemplate>
        </div>
      </Collapsible>
    );
  };

  const handleSubmit = async (shouldPublish: boolean, setLoader: (value: boolean) => void) => {
    const getNewFiles = (array: NewFile[]) => {
      return array.reduce((obj: any, newFile: NewFile) => {
        obj[newFile.key] = ({
          id: newFile.key,
          fileName: newFile.file.name,
          mimeType: newFile.file.type,
          fileObj: newFile.file,
        });
        return obj;
      }, {})
    }

    setLoader(true);
    try {
      const membersToUpdate = supplierContacts?.filter(contact => !contact.isRemoved && contact.isOnEditingMode && !contact.isNew) || [];
      const membersToRemove = supplierContacts?.filter(contact => contact.isRemoved);
      const membersToAdd = supplierContacts?.filter(contact => contact.isNew);
      const selectedMembers = supplierContacts?.filter(contact => contact.isSelected)?.map(contact => ({ supplierId: contact.supplierId, supplierMemberId: contact._id }));

      const area = currentProject.area.find(area => area.areaId.slug === match.params.areaSlug);

      const projectSlug = match.params.project;
      const areaSlug = match.params.areaSlug;
      const _demands = demands?.filter(l => l?.label !== 'Välj')?.map(d => {
        const existing = demandOptions.find(o => o?.label === d?.label);
        return { 
          demandId: existing?.value ?? "new", 
          name: d?.label,
          isNew: !existing,
          required: d?.required 
        };
      });
      const _priceTypes = priceTypes?.filter(l => l?.label !== 'Välj')?.map(p => {
        const existing = priceTypeOptions.find(o => o?.label === p?.label);
        return { 
          priceType: existing?.value ?? "new", 
          name: p?.label, 
          isNew: !existing,
          required: p?.required,
          unit: p?.unit,
        };
      });

      const projectQuotation = {
        projectId: currentProject._id,
        areaId: area?.areaId?._id,
        quotationExpiryDate: moment(expirationDate).endOf('day').utc(),
        content: infoText,
        attachments: JSON.stringify(files),
        newFiles: getNewFiles(newFiles),
        suppliers: JSON.stringify(selectedMembers),
        demands: JSON.stringify(_demands),
        priceDetails: JSON.stringify(_priceTypes),
        emailText: senderMsg,
        membersToAdd: JSON.stringify(membersToAdd),
        membersToRemove: JSON.stringify(membersToRemove),
        membersToUpdate: JSON.stringify(membersToUpdate),
      }

      const quotationResponse = await ServiceQuotation.sendQuotation(projectSlug, areaSlug, projectQuotation);

      if (shouldPublish !== area?.isPublic) {
        await ServiceProjects.updateProjectAreaIsPublic(currentProject.slug, match.params.areaSlug, shouldPublish);
      }
      ServiceProjects.markQuoteSent(projectSlug, areaSlug, quotationResponse);
      
      setBidRequestDrafts((drafts: any) => {
        const temp = {...drafts};
        temp[getDraftString(match.params.project, match.params.areaSlug, fileScopeQuotationVersion)] = undefined;

        return temp;
      });

      NotificationManager.success("Skickat");
      goBack();
    } catch (error) {
      console.log({error}, "e::::");
      Sentry.captureException(error);
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 400 && error?.response?.data?.fieldsErrorMessages) {
          const fieldsErrorMessages = error.response.data.fieldsErrorMessages as {[contactId:string]: Omit<Member, "_id">};
          setSupplierContacts(prevState => {
            const tempContacts = [...prevState];
  
            for (const [contactId, errorMessages] of Object.entries(fieldsErrorMessages)) {
              const contactIndex = tempContacts.findIndex(contact => contact._id === contactId);
              tempContacts[contactIndex].errorMessages = errorMessages;
            }
  
            return tempContacts;
          });
          NotificationManager.error("På grund av en ogiltig mottagare kunde förfrågan inte skickas.", "Kunde inte skicka");
        } else {
          const message = error.toString();
          const data = error.response?.data;
          if (data?.error == "File changed size") {
            console.error(data);
            NotificationManager.error(
              <><p>Filen {data.expectedName} ändrade storlek medan den skickades. Se till att alla filer är färdignedladdade och inte öppna i något annat program.</p><p>Ta bort filen och lägg till den på nytt innan du försöker igen.</p></>,
              "Kunde inte skicka",
              10000
            );
          } else {
            const details = data?.error ?? data?.message ?? data?.toString();
            NotificationManager.error([message, details].filter(x=>x).join(": "), "Kunde inte skicka");
            throw error;
          }
        }
      } else {
        NotificationManager.error("" + error, "Oväntat fel");
        throw error;
      }
    } finally {
      setLoader(false);
    }
  };

  const priceFieldCounts = priceTypes.map(o => o?.label.toLowerCase().trim() ?? "").reduce((map, key) => map.set(key, (map.get(key) ?? 0) + 1), new Map<string, number>());
  const duplicatePriceFields = Array.from(priceFieldCounts.values()).find(count => count > 1);
  const emptyPriceFields = priceFieldCounts.get("") ?? 0;
  const demandsCounts = demands.map(o => o?.label.toLowerCase().trim() ?? "").reduce((map, key) => map.set(key, (map.get(key) ?? 0) + 1), new Map<string, number>());
  const duplicateDemands = Array.from(demandsCounts.values()).find(count => count > 1);
  const emptyDemands = demandsCounts.get("") ?? 0;
  const selectedContacts = supplierContacts.filter(contact => contact.isSelected);
  const errorMessage = [
    selectedContacts.length == 0 ? "ingen mottagare vald" : "",
    duplicatePriceFields ? "samma prisfält finns med flera gånger" : "",
    emptyPriceFields == 1 ? "ett prisfält är tomt" : "",
    emptyPriceFields > 1 ? "flera prisfält är tomma" : "",
    duplicateDemands ? "samma skallkrav finns med flera gånger" : "",
    emptyDemands == 1 ? "ett skallkrav är tomt" : "",
    emptyDemands > 1 ? "flera skallkrav är tomma" : "",
  ].filter(x=>x).join(", ");

  const onDraftAccept = () => {
    setExpirationDate(draft.expirationDate);
    setInfoText(draft.infoText);
    setFiles(draft.files);
    setNewFiles(draft.newFiles);
    setPriceTypeUnits(draft.priceTypeUnits);
    setDemands(draft.demands);
    setPriceTypes(draft.priceTypes);
    setSenderMsg(draft.senderMsg);
    setFileScopeQuotationId(draft.fileScopeQuotationId);
    setFileScopeQuotationVersion(draft.fileScopeQuotationVersion);
    setSupplierContacts(draft.supplierContacts?.filter((supplierContact: {supplierId: string}) => suppliers.find(supplier => supplier._id === supplierContact.supplierId)));

    setDraftDecision('DRAFT');

    setShowDraftModal(false);
  }

  return (
    <AppLayout title={<BackButton
      onClick={goBack}
      path={[projectName, `Förfrågningsunderlag för ${areaName} ${match.params.version == "new" ? "" : `version ${match.params.version}`}`]}
    />}>
      {isQuotationLoaded ? (<>
        <ScrollToTop />
        <div className={styles.container}>
          <div className={styles.content}>
            {suppliers?.length > 0 && renderRecipientsList()}
            {renderMainForm()}
            {renderEmailViewer()}
            <TooltipContainer
              renderReferenceComponent={(className, ref) => 
                <div
                  ref={ref}
                  style={{ alignSelf: 'flex-end', display: 'flex', gap: '16px' }}
                  className={className}
                >
                  <BtButton
                    loaderShow={publishLoader}
                    onClick={() => handleSubmit(true, setPublishLoader)}
                    disabled={!selectedContacts.length || !!errorMessage || notPublishLoader}
                    type="submit"  
                    color={shouldPublish ? 'orange' : 'white'}
                  >
                    Skicka ny version och publicera
                  </BtButton>
                  {!shouldPublish && <BtButton
                    loaderShow={notPublishLoader}
                    onClick={() => handleSubmit(false, setNotPublishLoader)}
                    disabled={!selectedContacts.length || !!errorMessage || publishLoader}
                    type="submit"
                  >
                    Skicka ny version
                  </BtButton>}
                </div>
              }
            >
              {errorMessage && (
                <div style={{ width: 'max-content', maxWidth: '310px' }}>Kan inte skicka förfrågan: {errorMessage}.</div>
              )}
            </TooltipContainer>
          </div>
        </div>
      </>) : (<div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
        <Loading type="inline" />
      </div>)}
      <Modal
        title='Forsätt från utkast?'
        buttonInfo={{ label: 'Ja, fortsätt från utkastet', action: onDraftAccept}}
        cancelButtonText='Nej, starta ny version'
        show={showDraftModal}
        setShow={(value) => {
          if (!value) {
            setDraftDecision(oldState => {
              if (oldState === 'UNDECIDED') {
                return 'NEW';
              } else {
                return oldState;
              }
            });
          }

          setShowDraftModal(value);
        }}
        
      >
        <p style={{ color: 'var(--gray-700)' }}>Hittade ett sparat utkast från {moment(draft?.savedAt).locale('sv').format('lll')}.</p>
        
        {isOldDraft && <span style={{ fontWeight: '500', color: 'var(--gray-700)' }}>Obs! Utkastet är äldre än den senaste versionen.</span>}
      </Modal>
    </AppLayout>
  );
};

export default PageBidRequest;
