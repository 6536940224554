import React, { forwardRef, useState } from "react";
import update from "immutability-helper";
import _, { isEmpty } from "lodash";
import { ManualContractService } from "../../services/service-manualContract";
import { ServiceProjects } from "../../services/service-projects";
import { ServiceCheckExtension, allowedFileExtensions } from "../../services/service-check-file-extension";
import AppProjectSinglePurchase from "../app-project-single-purchase/app-project-single-purchase";
import "./app-project-purchase-list.css";
import appState from "../../state/AppStateContainer";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import SearchBarWithActions from '../../components/shared/SearchBarWithActions/SearchBarWithActions';
import {ReactComponent as CurvedArrowIcon} from '../../assets/svg/arrow-curved-up-right.svg';
import OptionsMenu from '../../components/shared/OptionsMenu/OptionsMenu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ServiceUser } from "../../services/service-user";
import appEnum, { FileScope, SavingState } from "../../util/appEnum";
import DataTable from "../../components/shared/DataTable/DataTable";
import { withRouter } from 'react-router-dom';
import Tabs from "../../components/shared/Tabs";
import BtButton from "../bt-button/bt-button";
import { ReactComponent as OpenedEyeIcon } from '../../assets/svg/openedEyeIcon.svg';
import { ReactComponent as CircleDollarIcon } from '../../assets/svg/circleDollarIcon.svg';
import { ReactComponent as CircleXIcon } from '../../assets/svg/circleXIcon.svg';
import { ReactComponent as CircleCheckIcon } from '../../assets/svg/circleCheckIcon.svg';
import { ReactComponent as CirclePendingIcon } from '../../assets/svg/circlePendingIcon.svg';
import { ReactComponent as CommentIcon } from '../../assets/svg/commentIcon.svg';
import { ReactComponent as FlyerTakenLightIcon } from '../../assets/svg/flyerTakenLightIcon.svg';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIconLight.svg';
import TrashIcon from '../../components/svgs/trashIcon';
import { CustomToggle } from "../../components/shared";
import TooltipContainer from "../../components/shared/TooltipContainer/TooltipContainer";
import moment from "moment";
import { getDaysLeft, getDaysLeftWithText, formatAmount, parseAmount, autosave } from "../../util";
import File from "../../components/File/File";
import CustomizationMenu from "../../components/shared/CustomizationMenu/CustomizationMenu";
import ConversationModal from "../../components/shared/ConversationModal/ConversationModal";
import NotificationCounter from "../../components/shared/NotificationCounter/NotificationCounter";
import TeamBuilder from "../../components/shared/TeamBuilder/TeamBuilder";
import ContractIcon from "../../components/svgs/contractIcon";
import TableField from "./TableField/TableField";
import TableDatePicker from "./TableDatePicker/TableDatePicker";
import Modal from "../../components/shared/Modal/Modal";
import HTMLParser from "../../components/shared/HTMLParser/HTMLParser";
import styles from "./app-project-purchase-list.module.scss";
import LazyTooltipContainer from "../../components/shared/TooltipContainer/LazyTooltipContainer";
import AverageAdditionsPercentForCategoryTooltip from "./AverageAdditionsPercentForCategoryTooltip";

export const Badge = forwardRef(function Badge({ icon, value, color, bgColor, className }, ref) {
  return (
    <div ref={ref} className={className} style={{ backgroundColor: bgColor || 'var(--gray-100)', display: 'flex', gap: '4px', height: '24px', width: 'fit-content', borderRadius: '4px', justifyContent: 'center', alignItems: 'center', padding: '4px 8px' }}>
      {icon && icon}
      <span style={{ lineHeight: '16px', fontWeight: !icon ? '600' : '400', color: color ? color : 'var(--gray-700)' }}>{value}</span>
    </div>
  )
})

const ContractBadgeContent = ({ contracts, status }) => {
  const formatDate = date => {
    return date && moment(date).locale('sv').format('D MMMM YYYY');
  };

  let headline;
  let getStatus;
  if (status === 'PENDING') {
    headline = 'Pågående avtal';
    getStatus = (contract) => contract.createdAt ? `skickat den ${formatDate(contract.createdAt)}` : `skickat`;
  } else if (status === 'DONE') {
    headline = 'Färdiga avtal';
    getStatus = (contract) => `${contract.signedAt ? "e-signerat" : "manuellt signerat"}, ${formatDate(contract.signedAt ?? contract.createdAt)}`;
  } else if (status === 'CANCELED') {
    headline = 'Avbrutna avtal';
    getStatus = (contract) => `${{
      canceled: "återkallat",
      timedout: "utgånget",
      rejected: "nekat",
      document_error: "dokumentfel",
    }[contract.lastStatus]}`;
  }

  return (
    <div style={{ width: "max-content", maxWidth: 500 }}>
      <p style={{ fontWeight: 500, color: "var(--gray-900)", marginTop: 0 }}>{`${headline} (${contracts.length}):`}</p>
      <ul style={{ marginBottom: 0 }}>
        {contracts.map(contract => (
          <li key={contract.file}>
            <span style={{ fontWeight: 500, color: "var(--gray-900)" }}>{contract.fileName}</span> ({formatAmount(contract.amount ?? '') || "–"} kr), {getStatus(contract)}
          </li>
        ))}
      </ul>
    </div>
    )
}

const SortOrder = {
  LATEST: "LATEST",
  OLDEST: "OLDEST",
  ALPHABETICAL: "ALPHABETICAL",
};

const SortOrderLabels = {
    LATEST: "Senaste",
    OLDEST: "Äldst",
    ALPHABETICAL: "Alfabetiskt",
}

const COLUMNS = [
  {
    id: 'Upphandling_1',
    name: 'Upphandling',
    static: true,
    defaultOrder: 10,
  }, {
    id: 'Status_2',
    name: 'Status',
    defaultOrder: 20,
  }, {
    id: 'Budget_3',
    name: 'Budget',
    defaultOrder: 30,
  }, {
    id: 'Kontraktssumma_4',
    name: 'Kontraktssumma',
    isRenderedInStep: (step) => step >= 3,
    defaultOrder: 40,
  }, {
    id: 'Inköpsvinst_(SEK)_5',
    name: 'Inköpsvinst (SEK)',
    isRenderedInStep: (step) => step >= 3,
    defaultOrder: 50,
  }, {
    id: 'Inköpsvinst_(%)_6',
    name: 'Inköpsvinst (%)',
    isRenderedInStep: (step) => step >= 3,
    defaultOrder: 60,
  }, {
    id: 'finalCost_21',
    name: 'Slutkostnad',
    isRenderedInStep: (step) => step >= 3,
    defaultOrder: 62,
  }, {
    id: 'Äta_totalt_20',
    name: 'Äta totalt',
    isRenderedInStep: (step) => step >= 3,
    defaultOrder: 65,
  }, {
    id: 'Vald_leverantör_7',
    name: 'Vald leverantör',
    isRenderedInStep: (step) => step > 2,
    defaultOrder: 70,
  }, {
    id: 'Förfrågningar_8',
    name: 'Förfrågningar',
    isRenderedInStep: (step) => step <= 2,
    defaultOrder: 80,
  }, {
    id: 'Återstående_anbudstid_9',
    name: 'Återstående anbudstid',
    isRenderedInStep: (step) => step === 1,
    defaultOrder: 90,
  }, {
    id: 'Förfrågan_skickas_senast_18',
    name: 'Förfrågan skickas senast',
    isRenderedInStep: (step) => step === 1 ,
    defaultOrder: 95,
  }, {
    id: 'Svar_senast_10',
    name: 'Svar senast',
    isRenderedInStep: (step) => step === 1 ,
    defaultOrder: 100,
  }, {
    id: 'Inköp_klart_17',
    name: 'Inköp klart',
    defaultOrder: 105,
  }, {
    id: 'Ledtid_19',
    name: 'Ledtid',
    defaultOrder: 108,
  }, {
    id: 'Leveransstart_11',
    name: 'Leveransstart',
    defaultOrder: 110,
  }, {
    id: 'Leveransslut_12',
    name: 'Leveransslut',
    defaultOrder: 120,
  }, {
    id: 'Ansvarig_13',
    name: 'Ansvarig',
    defaultOrder: 130,
  }, {
    id: 'Utvärdering_14',
    name: 'Utvärdering',
    isRenderedInStep: (step) => step === 2,
    defaultOrder: 140,
  }, {
    id: 'Avtal_15',
    name: 'Avtal',
    isRenderedInStep: (step) => step >= 3,
    defaultOrder: 150,
  }, {
    id: 'Kommentar_16',
    name: 'Kommentar',
    defaultOrder: 160,
  },
  {
    id: 'Uppskattad_ÄTA_(%)_22',
    name: 'Uppskattad ÄTA (%)',
    defaultOrder: 33,    
  },
  {
    id: 'Inköpsmål_%_23',
    name: 'Inköpsmål (%)',
    defaultOrder: 34,    
  },
  {
    id: 'Inköpsbudget_24',
    name: 'Inköpsbudget',
    defaultOrder: 35,    
  },
];

const getColumnNameWithId = (id) => {
  return COLUMNS.find(column => column.id === id)?.name;
}

const initColumns = () => {
  if (!isEmpty(appState.getProjectOverviewPreferences()?.columns)) {
    const mappedColumns = appState.getProjectOverviewPreferences().columns.map(dbColumn => {
      const column = COLUMNS.find(column => column.id === dbColumn.id);
      return {
        ...dbColumn,
        name: column.name,
        static: column.static,
        isRenderedInStep: column.isRenderedInStep,
        defaultOrder: column.defaultOrder,
      }
    });

    const columnsNotSaved = COLUMNS
    .filter(column => !appState.getProjectOverviewPreferences().columns.find(dbColumn => column.id === dbColumn.id))
    .map(column => ({
      ...column,
      show: true,
    })).sort((a, b) => a.defaultOrder - b.defaultOrder);

    const sorted = [...COLUMNS].sort((a, b) => a.defaultOrder - b.defaultOrder);
    const existingOrders = mappedColumns.reduce((set, c) => set.add(c.defaultOrder), new Set());
    
    for (const column of columnsNotSaved) {
      let index = sorted.findIndex(c => c.name == column.name) - 1;
      while (index >= 0 && !existingOrders.has(sorted[index].defaultOrder)) {
        index--;
      }
      if (index >= 0) {
        //console.log("Inserting " + column.name + " after " + sorted[index].name);
        const insertAt = mappedColumns.findIndex(c => c.name === sorted[index].name) + 1;
        mappedColumns.splice(insertAt, 0, column);
        existingOrders.add(column.defaultOrder);
      } else {
        //console.log("Inserting " + column.name + " at the start");
        mappedColumns.splice(0, 0, column);
        existingOrders.add(column.defaultOrder);
      }
    }

    return mappedColumns;
  } else {
    return COLUMNS.map(column => ({
      ...column,
      show: true,
    }))
  }
}

class AppProjectPurchaseList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAgreementModal: false,
      wizard: [
        { step: 6, name: 'Inköpsplan' },
        { step: 1, name: 'Förfrågan' },
        { step: 2, name: 'Utvärdering' },
        { step: 3, name: 'Förhandling' },
        { step: 4, name: 'Avtal' },
      ],
      sortOrder: appState.getProjectOverviewPreferences()?.sortOrder ?? SortOrder.OLDEST,
      columns: initColumns(),
      purchaseVersionData: [],
      temporaryAreaBudget: {},
      temporaryAreaFinalCost: {},
      temporaryAreaLeadTime: {},
    };

    this.onRowClick = (e, rowData) => {
      this.props.history.push(`${this.props.location.pathname}/${rowData.areaId.slug}`)
    };
    this.columns = this.createColumns();
  }

  hasReRendered;

  async componentDidMount() {
    await appState.getUserDetails();
    const purchaseVersionData = await Promise.all(this.props.currentProject.area.map(async (area) => {
      const versionData = await ServiceProjects.getVersionData(this.props.currentProject.slug, area.areaId._id);
      return {
        areaId: area.areaId._id,
        versionData: versionData[0]?.versions ?? [],
      }
    }));
    this.setState({
      sortOrder: appState.getProjectOverviewPreferences()?.sortOrder ?? SortOrder.OLDEST,
      columns: initColumns(),
      purchaseVersionData: purchaseVersionData
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.step != prevProps.step) {
      this.columns = this.createColumns(); // force reordering
      this.setState({columns: initColumns()});
    }
  }

  filterAreas(item) {
    if (item.isQuoteSent) {
      return item.areaId;
    }
    return false;
  }


  async winnerChangeEventHandler(area, supplier, isRemoved) {
    if (!this.element) return;

    let currentProject = this.props.currentProject;
    let slug = currentProject.slug;
    let areaId = area._id;
    let supplierId = supplier && supplier._id;

    if (supplierId) {
      autosave(async () => {
        await ServiceProjects.dbRewardSupplier(
          slug,
          areaId,
          supplierId,
          !isRemoved
        );
        ServiceProjects.updateWinner(slug, areaId, supplierId, !isRemoved);
      })
    } else {
      console.error("Price can't be updated without supplierId.");
    }

    var collection = [];
    currentProject.area.forEach(val => {
      val.suppliers.forEach(s => {
        if (s.supplierId == supplierId) s.reward = !isRemoved;

        if (s.reward) collection.push(s.supplierId);
      });
    });

    if (this.props.winnerChangeEvent) {
      this.props.winnerChangeEvent(this, [...collection]);
    }
  }

  async changeStepEventHandler(area, skipStepChange = false, stepId) {
    // if (!this.element) return;

    let currentProject = this.props.currentProject;
    let slug = currentProject.slug;
    let areaId = area?._id;

    if (this.props.onStepChange && !skipStepChange) this.props.onStepChange(stepId);
    await ServiceProjects.dbChangeStep(slug, areaId, stepId);
    ServiceProjects.updateStepInArea(slug, areaId, stepId);
  }

  async dateChangeEventHandler(area, dateEnd) {
    if (!this.element) return;

    let currentProject = this.props.currentProject;
    let slug = currentProject.slug;
    let areaId = area._id;

    await ServiceProjects.dbUpdateEndDate(slug, areaId, dateEnd);
    ServiceProjects.updateEndDateInArea(slug, areaId, dateEnd);
  }

  async changeAreaBudget(area, budget) {
    if (!this.element) return;
    autosave(async () => {
      const budgetValue = budget.target.value;

      let currentProject = this.props.currentProject;
      let slug = currentProject.slug;
      let areaId = area._id;

      await ServiceProjects.dbUpdateAreaBudge(slug, areaId, budgetValue);
      ServiceProjects.updateBudgetInArea(slug, areaId, budgetValue);
    });
  }

  createColumns() {
    const getWinnerInfoFromRow = (rowData) => {
      const winner = rowData.suppliers.find(s => s.reward);
      return {
        name: winner?.supplierId.name ?? "–",
        frameworkAgreement: winner?.supplierId?.frameworkAgreement,
        organizationNumber: winner?.supplierId?.organizationNumber,
      };
    }

    const getContractSumFromRow = (rowData) => {
      const contractsSum = rowData.contracts?.filter(contract => !!contract?.signedAt)?.reduce((sum, c) => sum + parseAmount(c.amount ?? ''), 0) ?? 0;
      const manualContractsSum = rowData.manualContract?.reduce((sum, c) => sum + parseAmount(c.amount ?? ''), 0) ?? 0;
      const sum = contractsSum + manualContractsSum;

      return sum;
    }

    const getProfitDataFromRow = (rowData) => {
      const cost = getContractSumFromRow(rowData);      
      const additionsFactor = (100 + parseAmount(rowData.estimatedAdditionsPercent ?? "0")) / 100;
      const budget = parseAmount(rowData.budget ?? '') / additionsFactor;
      if (budget > 0 && cost > 0) {
        return [budget - cost, budget];
      }
    }

    const getLongestDateExtensionFromRow = (rowData) => {
      if (!rowData.endDate) {
        return null;
      }

      const areaSuppliers = rowData?.suppliers;

      let longestExtension = moment(rowData.endDate);
      areaSuppliers.forEach(supplier => {
        if (supplier.date && moment(supplier.date).isAfter(longestExtension)) {
          longestExtension = moment(supplier.date);
        }
      })

      return longestExtension;
    }

    const genericCompare = (a, b) => {
      if (a > b) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
      return 0;
    }

    const differenceBetweenFinalCostAndContractSum = (area) => {
      const contractSum = getContractSumFromRow(area);
      let difference;
      if (contractSum && area?.finalCost) {
        difference = parseAmount(area.finalCost) - contractSum;
      }

      return difference;
    }

    return [
      {
        title: getColumnNameWithId('Upphandling_1'), 
        field: "areaId.name", 
        render: rowData => {
          const count = rowData.suppliers.reduce((sum, s) => sum + s.supplierId.notification?.reduce((sum, n) => sum + (n?.count ?? 0), 0), 0)
          return (
            <span style={{display:"flex", gap: 8, minWidth: '200px', alignItems: 'center'}}>
              <span style={{color: 'var(--gray-900)', fontSize: 'var(--fontSize-16)', fontWeight: 'var(--weight-600)'}}>{rowData.areaId.name}</span>
              <NotificationCounter counter={count} />
            </span>
          );
        }
      },
      {
        title: getColumnNameWithId('Budget_3'),
        field: "budget",
        render: rowData => {
          const areaBudget = (rowData?.budget || rowData?.budget === 0) ? rowData?.budget : null;
          const { value, updating } = this.state.temporaryAreaBudget[rowData.areaId._id] || {};
          const temporaryFinalCost = updating ? value : areaBudget;
          return (
            <TableField
              value={rowData?.budget}
              onBlur={async (value) => {
                if (value != rowData?.budget) {
                  await ServiceProjects.dbUpdateAreaBudge(this.props.currentProject.slug, rowData.areaId._id, value);
                  ServiceProjects.updateBudgetInArea(this.props.currentProject.slug, rowData.areaId._id, value);  
                }
              }}
            />
          );
        },
        customSort: (x, y) => {
          const a = parseAmount(x.budget ?? '');
          const b = parseAmount(y.budget ?? '');
          return genericCompare(a, b);
        },
      },
      {
        title: getColumnNameWithId('Ansvarig_13'),
        sorting: false,
        render: rowData => (
          <TeamBuilder users={this.props.users} areaUsers={rowData.areaId.members} currentProject={this.props.currentProject} areaId={rowData.areaId._id} />
        ),
      },
      {
        title: getColumnNameWithId('Vald_leverantör_7'),
        customSort: (x, y) => {
          const a = getWinnerInfoFromRow(x).name;
          const b = getWinnerInfoFromRow(y).name;
          return genericCompare(a, b);
        },
        render: rowData =>{
          const { name, frameworkAgreement, organizationNumber } = getWinnerInfoFromRow(rowData);
          const url = appState._getCurrentOrgUrl();
          const queryString = organizationNumber ? organizationNumber : name;
          return (
            <span style={{ whiteSpace: 'nowrap', display: 'flex' }}>
              {frameworkAgreement && (frameworkAgreement.file || frameworkAgreement.validUntilDate || frameworkAgreement.comment) && 
                <ContractIcon style={{ marginRight: "10px", marginLeft:0, width: "20px", verticalAlign: "middle" }} />
              }
              <span
                style={{ textDecoration: name !== '–' && queryString ? 'underline' : 'normal' }}
                onClick={queryString ? (e) => {
                  e.stopPropagation();
                  this.props.history.push(`${url}/supplier-database`, { q: queryString })
                } : undefined}
              >
                {name}
              </span>
            </span>
        )} 
      },
      {
        title: getColumnNameWithId('Avtal_15'),
        sorting: false,
        render: rowData => {
          const contracts = rowData.contracts ?? [];
          const manualContracts = rowData.manualContract ?? [];
          const done = contracts.filter(contract => contract?.signedAt).concat(manualContracts);
          const pending = contracts.filter(contract => {
            const { lastStatus, signedAt } = contract ?? {};
            if (signedAt) return false;
            return !(lastStatus === 'canceled' || lastStatus === 'timedout' || lastStatus === 'rejected' || lastStatus === 'document_error');
          });
          const canceled = contracts.filter(contract => {
            const { lastStatus, signedAt } = contract ?? {};
            if (signedAt) return false;
            return (lastStatus === 'canceled' || lastStatus === 'timedout' || lastStatus === 'rejected' || lastStatus === 'document_error');
          });
  
          return (
            <div style={{display:"flex", gap: '12px', paddingRight: '16px'}}>
              {pending.length > 0 && (
                <TooltipContainer key="pending" renderReferenceComponent={(className, ref) => <Badge className={className} ref={ref} bgColor='var(--yellow-50)' color='var(--yellow-700)' value={pending.length} icon={<CirclePendingIcon fill='var(--yellow-700)' width='16px' height='16px' />} />}>
                  <ContractBadgeContent contracts={pending} status='PENDING'/>
                </TooltipContainer>
              )}
              {done.length > 0 && (
                <TooltipContainer key="done" renderReferenceComponent={(className, ref) => <Badge className={className} ref={ref} bgColor='var(--green-50)' color='var(--green-700)' value={done.length} icon={<CircleCheckIcon fill='var(--green-700)' width='16px' height='16px' />} />}>
                  <ContractBadgeContent contracts={done} status='DONE'/>
                </TooltipContainer>
              )}
              {canceled.length > 0 && (
                <TooltipContainer key="canceled" renderReferenceComponent={(className, ref) => <Badge className={className} ref={ref} bgColor='var(--red-50)' color='var(--red-700)' value={canceled.length} icon={<CircleXIcon fill='var(--red-700)' width='16px' height='16px' />} />}>
                  <ContractBadgeContent contracts={canceled} status='CANCELED'/>
                </TooltipContainer>
              )}
            </div>
          );
        },
      },
      {
        title: getColumnNameWithId('Kontraktssumma_4'),
        customSort: (x, y) => {
          const a = getContractSumFromRow(x);
          const b = getContractSumFromRow(y);
          return genericCompare(a, b);
        },
        render: rowData => {
          const sum = getContractSumFromRow(rowData);
          return sum > 0 ? <span style={{ whiteSpace: 'nowrap' }}>{formatAmount(sum) + " kr"}</span> : "–";
        }
      },
      {
        title: getColumnNameWithId('Inköpsvinst_(SEK)_5'),
        customSort: (x, y) => {
          const xData = getProfitDataFromRow(x);
          const a = xData ? xData[0] : 0;
          const yData = getProfitDataFromRow(y);
          const b = yData ? yData[0] : 0;
          return genericCompare(a, b);
        },
        render: rowData => {
          const data = getProfitDataFromRow(rowData);
          const contractSum = getContractSumFromRow(rowData);

          if (data) {
            const [ profit, budget ] = data;
            return (
              <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", gap: "12px" }}>
                <TooltipContainer renderReferenceComponent={(className, ref) => (
                  <span className={className} ref={ref} style={{ whiteSpace: 'nowrap' }}>{formatAmount(profit) + " kr"}</span>
                )}>
                  <div style={{maxWidth: "400px"}}>
                  Skillnaden mellan budget justerad för uppskattad ÄTA och kontraktssumma ({formatAmount(budget)} kr - {formatAmount(contractSum)} kr).
                  </div>
                </TooltipContainer>
                
              </div>
            );
          } else {
            return "–";
          }
        }
      },
      {
        title: getColumnNameWithId('Inköpsvinst_(%)_6'),
        customSort: (x, y) => {
          const xData = getProfitDataFromRow(x);
          let a;
          if (xData) {
            const [ profit, budget ] = xData;
            a = Math.round(profit/budget*10000)/100;
          } else {
            a = 0;
          }
          const yData = getProfitDataFromRow(y);
          let b;
          if (yData) {
            const [ profit, budget ] = yData;
            b = Math.round(profit/budget*10000)/100;
          } else {
            b = 0;
          }
          return genericCompare(a, b);
        },
        render: rowData => {
          const data = getProfitDataFromRow(rowData);
          if (data) {
            const [ profit, budget] = data;
            return <span style={{ whiteSpace: 'nowrap' }}>{formatAmount(Math.round(profit/budget*10000)/100) + " %"}</span>;
          } else {
            return "–";
          }
        }
      },
      {
        title: getColumnNameWithId('Inköp_klart_17'),
        field: 'purchaseCompleted',
        render: rowData => {
          return (
            <TableDatePicker
              date={rowData?.purchaseCompleted}
              onChange={async date => {
                await ServiceProjects.updateAndAutocompleteFields(this.props.currentProject.slug, rowData.areaId.slug, 'purchaseCompleted', {
                  purchaseCompleted: date,
                  leadTime: rowData.leadTime,
                  productionStartsAt: rowData.productionStartsAt,
                });
              }}
            />
          )
        }
      },
      {
        title: getColumnNameWithId('Förfrågan_skickas_senast_18'),
        field: 'quotationSentBy',
        render: rowData => {
          return (
            <TableDatePicker
              date={rowData?.quotationSentBy}
              onChange={async date => {
                await ServiceProjects.updateProjectAreaQuotationSentBy(this.props.currentProject.slug, rowData.areaId.slug, date);
              }}
            />
          )
        }
      },
      {
        title: getColumnNameWithId('Ledtid_19'),
        field: 'leadTime',
        render: rowData => {
          const areaLeadTime = (rowData?.leadTime || rowData?.leadTime === 0) ? rowData?.leadTime : null;
          const { value, updating } = this.state.temporaryAreaLeadTime[rowData.areaId._id] || {};
          const temporaryLeadTime = updating ? value : areaLeadTime;
          const leadTimeInWeeks = (temporaryLeadTime || temporaryLeadTime === 0) ? temporaryLeadTime / (1000 * 60 * 60 * 24 * 7) : undefined;
          
          return (
            <TableField
              value={leadTimeInWeeks}
              originalValue={rowData?.leadTime}
              setValue={(value) => {
                this.setState({ temporaryAreaLeadTime: {...this.state.temporaryAreaLeadTime, [rowData.areaId._id]: value} })
              }}
              onBlur={async (value) => {
                await ServiceProjects.updateAndAutocompleteFields(this.props.currentProject.slug, rowData.areaId.slug, 'leadTime', {
                  purchaseCompleted: rowData.purchaseCompleted,
                  leadTime: value,
                  productionStartsAt: rowData.productionStartsAt,
                });
              }}
              isLeadTime
            />
          )
        }
      },
      {
        title: getColumnNameWithId('finalCost_21'),
        customSort: (x, y) => {
          return genericCompare(parseAmount(x?.finalCost ?? ''), parseAmount(y?.finalCost ?? ''));
        },
        render: rowData => {
          const areaFinalCost = (rowData?.finalCost || rowData?.finalCost === 0) ? rowData?.finalCost : null;
          const { value, updating } = this.state.temporaryAreaFinalCost[rowData.areaId._id] || {};
          const temporaryFinalCost = updating ? value : areaFinalCost;
          return (
            <TableField
              value={temporaryFinalCost}
              originalValue={rowData?.finalCost}
              setValue={(value) => this.setState({ temporaryAreaFinalCost: {...this.state.temporaryAreaFinalCost, [rowData.areaId._id]: value} })}
              onBlur={async (value) => {
                await ServiceProjects.updateProjectAreaFinalCost(this.props.currentProject.slug, rowData.areaId.slug, value);
              }}
            />
          );
        }
      },
      {
        title: getColumnNameWithId('Äta_totalt_20'),
        customSort: (x, y) => {
          const a = differenceBetweenFinalCostAndContractSum(x);
          const b = differenceBetweenFinalCostAndContractSum(y);

          return genericCompare(a, b);
        },
        render: rowData => {
          const difference = differenceBetweenFinalCostAndContractSum(rowData);
          return <span style={{ whiteSpace: 'nowrap' }}>{difference !== undefined ? `${formatAmount(difference)} kr` : '–'}</span>
        }
      },
      {
        title: getColumnNameWithId('Förfrågningar_8'),
        sorting: false,
        render: rowData => {
          const versions = rowData?.versionData;
          const areaSuppliers = rowData?.suppliers;

          const latestVersionPerSupplier = {};

          versions.forEach(version => {
            const refVersion = version.refVersion;
            const suppliers = version?.suppliers?.filter(versionSupplier => areaSuppliers.find(areaSupplier => areaSupplier.supplierId._id === versionSupplier.supplierId));
            suppliers.forEach(supplier => {
              if (!latestVersionPerSupplier[supplier.supplierId] || refVersion > latestVersionPerSupplier[supplier.supplierId].refVersion ) {
                latestVersionPerSupplier[supplier.supplierId] = version;
              }
            })
          });

          const totalStatus = Object.entries(latestVersionPerSupplier).map(([ id, version ]) => {
            const submitted = version.supplierBids.find(x => x.supplierId === id);
            if (submitted) {
              return 'submitted';
            }
            let status = 0;
            for (const supplierMember of version.suppliers.filter(x => x.supplierId === id)) {
              if (supplierMember.intentStatus?.intent === 'DECLINED') {
                status = 3;
              } else if (supplierMember.intentStatus?.intent === 'ACCEPTED' && status < 2) {
                status = 2;
              } else if (supplierMember.isSeen && status < 1) {
                status = 1;
              }
            }

            switch (status) {
              case 0:
                return 'not-seen';
              case 1:
                return 'seen';
              case 2:
                return 'accepted';
              case 3:
                return 'declined';
            }
          }).reduce((total, current) => {
            if (!total[current]) {
              total[current] = 1;
            } else {
              total[current] += 1;
            }

            return total;
          }, {});

          let submitted = totalStatus['submitted'] ?? 0;
          let declined = totalStatus['declined'] ?? 0;
          let accepted = totalStatus['accepted'] ?? 0;
          let seen = totalStatus['seen'] ?? 0;
          let notSeen = totalStatus['not-seen'] ?? 0;
          const numberOfSuppliersWithAtLeastOneVersion = Object.keys(latestVersionPerSupplier)?.length ?? 0;

          return (
            <>
              <div className='requestsContainer' style={{ display: 'flex', gap: '8px' }}>
                {numberOfSuppliersWithAtLeastOneVersion > 0 && (
                  <TooltipContainer key="total" renderReferenceComponent={(className, ref) => <Badge className={className} ref={ref} value={numberOfSuppliersWithAtLeastOneVersion} />}>
                    <span>Förfrågningar</span>
                  </TooltipContainer>
                ) }
                {
                  <>
                    {notSeen > 0 && (
                      <TooltipContainer key="notSeen" renderReferenceComponent={(className, ref) => <Badge className={className} ref={ref} value={notSeen} icon={<OpenedEyeIcon fill='var(--gray-300)' height='18px' />} />}>
                        <span>Anbudssidan ej öppnad</span>
                      </TooltipContainer>
                    )}
                    {seen > 0 && (
                      <TooltipContainer key="seen" renderReferenceComponent={(className, ref) => <Badge className={className} ref={ref} value={seen} icon={<OpenedEyeIcon fill='var(--gray-700)' height='18px' />} />}>
                        <span>Leverantören har öppnat anbudssidan</span>
                      </TooltipContainer>
                    )}
                    {accepted > 0 && (
                      <TooltipContainer key="accepted" renderReferenceComponent={(className, ref) => <Badge className={className} ref={ref} value={accepted} icon={<CircleCheckIcon fill='var(--gray-700)' height='16px' />} />}>
                        <span>Avser att lämna anbud</span>
                      </TooltipContainer>
                    )}
                    {declined > 0 && (
                      <TooltipContainer key="declined" renderReferenceComponent={(className, ref) => <Badge className={className} ref={ref} value={declined} icon={<CircleXIcon style={{ fill: 'var(--gray-700)', height: '16px' }} />} />}>
                        <span>Avser att inte lämna anbud</span>
                      </TooltipContainer>
                    )}
                  </>
                }
                
                {submitted > 0 && (
                  <TooltipContainer key="submitted" renderReferenceComponent={(className, ref) => <Badge className={className} ref={ref} value={submitted} icon={<CircleDollarIcon style={{ fill: 'var(--gray-700)' }} />} />}>
                    <span>Leverantören har lämnat anbud</span>
                  </TooltipContainer>
                )}
              </div>
            </>
          );
        }
      },
      {
        title: getColumnNameWithId('Återstående_anbudstid_9'),
        customSort: (x, y) => {
          const xDate = getLongestDateExtensionFromRow(x);
          const a = xDate !== null ? getDaysLeft(xDate) : 0;
          const yDate = getLongestDateExtensionFromRow(y);
          const b = yDate  !== null ? getDaysLeft(yDate) : 0;
          return genericCompare(a, b);
        },
        render: rowData => {
          const longestExtension = getLongestDateExtensionFromRow(rowData);

          if (longestExtension === null) {
            return;
          }

          const daysLeft = getDaysLeft(longestExtension);

          return (
            <TooltipContainer
              renderReferenceComponent={(className, ref) =>
                <div className={className} ref={ref} style={{width: 'fit-content', textAlign: "right", whiteSpace: 'nowrap'}}>
                  {getDaysLeftWithText(longestExtension)}
                </div>
              }
            >
              <div style={{ whiteSpace: 'nowrap' }}>
                {(daysLeft < 0 ? "Anbudstiden gick ut " : "Anbudstiden går ut ") + longestExtension.format("YYYY-MM-DD")}
              </div>
            </TooltipContainer>
          );
        }
      },
      {
        title: getColumnNameWithId('Utvärdering_14'),
        sorting: false,
        render: rowData => {
          if (rowData.attachment?.length > 0) {
            const areaId = rowData.areaId._id;
            const projectId = this.props.currentProject._id;
            const evaluation = rowData.attachment[rowData.attachment.length - 1];
            return (
              <div onClick={e => e.stopPropagation()}>
                <File file={evaluation.file} fileName={evaluation.fileName} scope={FileScope.ProjectEvaluation} scopeOptions={{ projectId, areaId }}/>
              </div>
            )
          }
        }
      }, {
        title: getColumnNameWithId('Kommentar_16'),
        sorting: false,
        render: rowData => {
          return (
            <div key={rowData.areaId.slug} onClick={(e) => e.stopPropagation()} style={{ cursor: 'default' }}>
              <ConversationModal
                title='Kommentar'
                fetchConversation={async () => await ServiceProjects.getProjectAreaComments(this.props.currentProject.slug, rowData.areaId.slug)}
                updateConversation={async (content) => await ServiceProjects.updateProjectAreaComments(this.props.currentProject.slug, rowData.areaId.slug, content)}
                render={(hasContent, content, openModal,) => {
                  if (hasContent) {
                    return (
                      <div onClick={openModal} className="projectAreaCommentsContainer">
                        <CommentIcon fill='var(--gray-700)' width='18px' height='18px' style={{ marginBottom: '-3px' }} />
                        <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                          <HTMLParser data={content} />
                        </span>
                      </div>
                    )
                  } else {
                    return (
                      <BtButton
                        onClick={openModal}
                        style={{ width: 'fit-content' }}
                        color='white'
                        size='xxs'
                      >
                        Lägg till
                      </BtButton>
                    )
                  }
                }}
              />
            </div>
          )
        },
      }, {
        title: getColumnNameWithId('Status_2'),
        field: 'step',
        render: rowData => {
          return (
            <div>
              {this.state.wizard.find(phase => phase.step == rowData.step)?.name}
            </div>
          )
        }
      },
      {
        title: getColumnNameWithId('Svar_senast_10'),
        field: 'answersLatestBy',
        render: rowData => {
          const versions = rowData?.versionData;
          const areaSuppliers = rowData?.suppliers;
          
          let latestExpirationDate;
          if (rowData?.answersLatestBy && !_.isEmpty(versions)) {
            const latestVersionPerSupplier = {};

            versions.forEach(version => {
              const refVersion = version.refVersion;
              const suppliers = version?.suppliers?.filter(versionSupplier => areaSuppliers.find(areaSupplier => areaSupplier.supplierId._id === versionSupplier.supplierId));
              suppliers.forEach(supplier => {
                if (!latestVersionPerSupplier[supplier.supplierId] || refVersion > latestVersionPerSupplier[supplier.supplierId].refVersion ) {
                  latestVersionPerSupplier[supplier.supplierId] = version;
                }
              })
            });

            
            Object.entries(latestVersionPerSupplier).map(([id, version]) => {
              const refVersion = version.refVersion;

              const expiryDate = version.quotationExpiryDate;
              
              const latestExtension = version.updatedSuppliers.find(sup => sup.supplierId === id && sup.version === refVersion)?.endDate;
              const selectedDate = moment(latestExtension).isAfter(expiryDate) ? latestExtension : expiryDate;
              if (!latestExpirationDate || moment(selectedDate).isAfter(latestExpirationDate)) {
                latestExpirationDate = selectedDate;
              }
            })
          }

          const latestExpirationIsAfterplannedDate = rowData?.answersLatestBy && !_.isEmpty(versions) && moment(latestExpirationDate).isAfter(rowData.answersLatestBy, 'day');
          
          return (
            <TableDatePicker
              date={rowData?.answersLatestBy}
              warningMessage={latestExpirationIsAfterplannedDate ? <div style={{ maxWidth: '250px', whiteSpace: 'normal' }}>Minst en leverantör har fått en förfrågan med senare anbudsdatum än detta ({moment(latestExpirationDate).locale('sv').format('D MMMM YYYY')}).</div> : ''}
              onChange={async date => {
                await ServiceProjects.updateProjectAreaAnswersLatestBy(this.props.currentProject.slug, rowData.areaId.slug, date);
              }}
            />
          )
        }
      },
      {
        title: getColumnNameWithId('Leveransstart_11'),
        field: 'productionStartsAt',
        render: rowData => {
          const date = rowData?.productionStartsAt && moment(rowData?.productionStartsAt);

          const purchaseCompleted = rowData.purchaseCompleted;
          const leadTime = rowData.leadTime;

          let errorMessage = '';
          if (date && purchaseCompleted && (leadTime || leadTime === 0)) {
            const calculatedLeadTime = moment(date).diff(purchaseCompleted, 'milliseconds');
            const calculatedLeadTimeInWeeks = calculatedLeadTime / (1000 * 60 * 60 * 24 * 7);
            const leadTimeInWeeks = leadTime / (1000 * 60 * 60 * 24 * 7);
            const leadTimesDontMatch = calculatedLeadTimeInWeeks < leadTimeInWeeks;
            const purchaseCompletedDateIsAfterProductionsStartsDate = moment(purchaseCompleted).isSameOrAfter(date);
            errorMessage = (leadTimesDontMatch || purchaseCompletedDateIsAfterProductionsStartsDate) ? (
              <div style={{ maxWidth: '200px', whiteSpace: 'normal' }}>
                Inte tillräckligt med tid mellan inköp klart och leveransstart (med {leadTimeInWeeks} veckors ledtid)
              </div>
            ) : '';
          }

          return (
            <TableDatePicker
              date={rowData?.productionStartsAt}
              errorMessage={errorMessage}
              onChange={async date => {
                await ServiceProjects.updateAndAutocompleteFields(this.props.currentProject.slug, rowData.areaId.slug, 'productionStartsAt', {
                  purchaseCompleted: rowData.purchaseCompleted,
                  leadTime: rowData.leadTime,
                  productionStartsAt: date,
                })
              }}
            />
          )
        }
      },
      {
        title: getColumnNameWithId('Leveransslut_12'),
        field: 'productionEndsAt',
        render: rowData => {
          return (
            <TableDatePicker
              date={rowData?.productionEndsAt}
              onChange={async date => {
                await ServiceProjects.updateProjectAreaProductionEndsAt(this.props.currentProject.slug, rowData.areaId.slug, date);
              }}
            />
          )
        }
      },
      {
        title: getColumnNameWithId('Uppskattad_ÄTA_(%)_22'),
        field: 'estimatedAdditionsPercent',
        render: rowData => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", gap: "12px" }}>
              <LazyTooltipContainer renderReferenceComponent={(className, ref) => <InfoIcon className={className} ref={ref} />}>
                <div style={{maxWidth: "400px"}}>
                  Uppskattade kostnader för ÄTA (ändringar, tillägg, avgående) i procent av kontraktsumman.{" "}
                  <AverageAdditionsPercentForCategoryTooltip slug={rowData.areaId.slug} />
                </div>
              </LazyTooltipContainer>
              <TableField
                value={rowData.estimatedAdditionsPercent}
                onBlur={async (value) => {
                  await ServiceProjects.updateProjectAreaEstimatedAdditionsPercent(this.props.currentProject.slug, rowData.areaId.slug, value);
                }}
                unit="%"
                style={{ width: "40px", minWidth: "40px" }}
                className={styles.fitContent}
              />
            </div>
          );
        }
      },
      {
        title: getColumnNameWithId('Inköpsmål_%_23'),
        field: 'purchaseGoalPercent',
        render: rowData => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", gap: "12px" }}>
              <TooltipContainer renderReferenceComponent={(className, ref) => <InfoIcon className={className} ref={ref} />}>
                Mål för inköpsbesparing i procent av budget.
              </TooltipContainer>
              <TableField
                value={rowData.purchaseGoalPercent}
                onBlur={async (value) => {
                  await ServiceProjects.updateProjectAreaPurchaseGoalPercent(this.props.currentProject.slug, rowData.areaId.slug, value);
                }}
                unit="%"
                style={{ width: "40px", minWidth: "40px" }}
                className={styles.fitContent}
              />
            </div>
          );
        }
      },
      {
        title: getColumnNameWithId('Inköpsbudget_24'),
        render: rowData => {
          const estimatedAdditionsPercent = parseAmount(rowData.estimatedAdditionsPercent ?? 0);
          const purchaseGoalPercent = parseAmount(rowData.purchaseGoalPercent ?? 0);
          const additionsFactor = (100 + estimatedAdditionsPercent) / 100;
          const goalFactor = (100 - purchaseGoalPercent) / 100;
          const budget = parseAmount(rowData.budget ?? 0);
          const purchaseBudget = budget / additionsFactor * goalFactor;

          if (purchaseBudget) {
            return (            
              <TooltipContainer renderReferenceComponent={(className, ref) => <div className={className} ref={ref}>{formatAmount(purchaseBudget)} kr</div>}>
                Budget justerad för uppskattad ÄTA och inköpsmål ({formatAmount(budget)} kr / {_.round(additionsFactor*100, 2)}% * {_.round(goalFactor*100, 2)}%).              
              </TooltipContainer>          
            );
          } else {
            return "";
          }
        }
      },
    ].filter(x => x);
  }
 

  reRender() {
    if (!this.hasReRendered) {
      console.log('---reRender---')
      this.setState({});

      this.hasReRendered = true;
    }

    setTimeout(() => {
      this.hasReRendered = false;
    }, 0);
  }
  async changeCallIcon(area, supplier, call) {

    let currentProject = this.props.currentProject;
    let slug = currentProject.slug;
    let areaId = area._id;
    let supplierId = supplier && supplier._id;
    let called = call ? call.isCalled : false;
    if (supplierId) {
      await ServiceProjects.dbUpdateCalled(slug, areaId, supplierId, called);
      // ServiceProjects.updatePriceInSupplier(slug, areaId, supplierId, price);
    } else {
      console.error("Price can't be updated without supplierId.");
    }
  }

  contractUploadHandler(area) {
    const winnerFlag =
      area.suppliers &&
      area.suppliers.some(_ => {
        if (_.reward == true) {
          return true;
        }
      });
      console.log("createUploadHandler", winnerFlag, this.props.contractUploadEvent)
    if (this.props.contractUploadEvent) {
      if (winnerFlag) {
        this.props.contractUploadEvent(this, area);
      } else {
        NotificationManager.error(
          " Du måste markera leverantören som vinnare i kategorin",
          "Misslyckat ",
          3000
        );
      }
    }
  }

  getSignedFileNameWithExtension(signedFileName) {
    if (signedFileName && !signedFileName.endsWith(".pdf")) {
      signedFileName = signedFileName + ".pdf";
      return signedFileName;
    }
    return null;
  }

  onChangeMeeting = async (area, meeting, { supplierId }) => {
    const { currentProject } = this.props;
    const slug = currentProject.slug;
    const areaId = area._id;
    const _supplierId = supplierId && supplierId._id;
    autosave(async () => {
      if (_supplierId) {
        await ServiceProjects.dbUpdateMeeting(slug, areaId, _supplierId, meeting);
      } else {
        console.error("Meeting can't be updated without supplierId.");
        throw new Error("Meeting can't be updated without supplierId.");
      }
    })
  };

  onCallDetail = async (area, callDetail, { supplierId }) => {
    const { currentProject } = this.props;
    const slug = currentProject.slug;
    const areaId = area._id;
    const _supplierId = supplierId && supplierId._id;
    if (_supplierId) {
      await ServiceProjects.dbUpdateCallDetail(
        slug,
        areaId,
        _supplierId,
        callDetail,
      );
    } else {
      console.error("Call Detail can't be updated without supplierId.");
    }
  };

  onSupplierDate = async (area, suppDate, { supplierId }) => {
    const { currentProject } = this.props;
    const slug = currentProject.slug;
    const areaId = area._id;
    const _supplierId = supplierId && supplierId._id;
    if (_supplierId) {
      await ServiceProjects.dbUpdateSupplierDate(
        slug,
        areaId,
        _supplierId,
        suppDate
      );
      ServiceProjects.changeSupplierDate(
        slug,
        area.slug,
        _supplierId,
        suppDate.date
      );
    } else {
      console.error("Supplier date. [!_supplierId]");
    }
  };

  elBtnAttachClickCallback = areaId => {
    console.log("clickety", areaId, this[`elInputFile${areaId}`]);
    this[`elInputFile${areaId}`] && this[`elInputFile${areaId}`].click();
  };
  elInputFileChangeCallback = async (e, areaId) => {
    const { currentProject } = this.props;
    var files = e.target.files;
    let isValid = ServiceCheckExtension.checkFileExtension(files[0]);
    if (isValid) {
      var fileObj = files[0];
      let slug = this.props.currentProject.slug;
      try {
        const uploadedFile = await ManualContractService.uploadContract(
          fileObj,
          slug,
          areaId,
        );
        if(uploadedFile){
          NotificationManager.success(`Filen har laddats upp`, "Sparat");
          //Move bid card to avtal step on manual contract upload 
          ServiceProjects.addManualContract(currentProject._id, areaId, uploadedFile);
          this.changeStepEventHandler({_id: areaId}, false, 4);
        }
      } catch (e) {
        console.log({ e });
        this.throwErr(files[0].name);
      }
    } else {
      console.log({ e });
      this.throwErr(files[0].name);
    }
  };

  throwErr = fileName => {
    NotificationManager.error(
      `Det gick inte att ladda upp ${fileName}`,
      "Fel"
    );
  };

  uploadItemRemoveClickCallback = async (areaId, manualContractFilePath) => {
    const { currentProject } = this.props;
    const area = currentProject.area.find(a => a?.areaId?._id == areaId);
    if (area.manualContract && area.manualContract.length) {
      let slug = currentProject.slug;
      await ManualContractService.deleteContract(slug, areaId, manualContractFilePath);

      //Move bid card if no signed or manual contract exists!!! 
      ServiceProjects.deleteManualContract(currentProject._id, areaId, manualContractFilePath);
      if(!area?.contracts?.find(contract => contract?.lastStatus === "closed") && area.manualContract.length === 1 && this.props.step == 4){
        this.changeStepEventHandler(area.areaId, false, 3);
      }
    }
  };

  getCurrentStepName = () => {
    return this.state.wizard.find(wiz => wiz.step == this.props.step).name.toLowerCase();
  }

  renderOverviewItems() {
    if (!this.props.currentProject) {
      return null;
    }

    let projectAreas = [...this.props.currentProject.area];

    if (this.state.sortOrder == SortOrder.ALPHABETICAL) {
      projectAreas.sort((a, b) => {
        return a.areaId.name.localeCompare(b.areaId.name);
      });  
    } else if (this.state.sortOrder == SortOrder.LATEST) {
      projectAreas.reverse();
    }

    let isContractSigning = this.props.isContractSigning;

    if (projectAreas.length > 0) {
      const currentStepName = this.getCurrentStepName();
      const areasWithVersionData = projectAreas.map(area => ({...area, versionData: this.state.purchaseVersionData.find(x => x.areaId === area.areaId._id)?.versionData ?? []}));
      const searchFilteredAreas = areasWithVersionData.filter(area => area.areaId.name.toUpperCase().includes(this.props.searchValue.toUpperCase()));
      if (searchFilteredAreas.length == 0) {
        return (
          <div className="empty-filtered-project">
            <h2>Inga upphandlingar</h2>
            <p>{`Inga kategorier i ${currentStepName} matchar din sökning.`}</p>
          </div>
        )
      }
     
      const stepFilteredAreas = searchFilteredAreas.filter(area => {
        return this.props.step == 6 ? true : area.step == this.props.step;
      });
      if (stepFilteredAreas.length == 0) {
        return (
          <div className="empty-filtered-project">
            <h2>Inga upphandlingar</h2>
            <p>{`Detta projekt har inga upphandlingar i ${currentStepName}.`}</p>
          </div>
        )
      }

      const visibleColumns = this.state.columns.filter(x => {
        if (this.props.step === 6) {
          return !x.static ? x.show : true;
        } else {
          return !x.isRenderedInStep || x.isRenderedInStep(this.props.step);
        }
      }).sort((a,b) => {
        if (this.props.step === 6) {
          return 0;
        }
  
        if (a.defaultOrder > b.defaultOrder) {
          return 1;
        } else if (a.defaultOrder < b.defaultOrder) {
          return -1;
        } else {
          return 0;
        }
      }).map(x => ({ id: x.id, name: x.name }));
  
      const tableColumns = visibleColumns.map(column => this.columns.find(c => c.title === column.name));

      if (this.props.selectedArea) {
        return areasWithVersionData.filter(val => val.areaId.slug == this.props.selectedArea).map(val => {
          let area = val.areaId;
          if (!val.step) {
            val.step = 1;
          }
          if (!val.attachment) {
            val.attachment = [];
          }
  
          const removeArea = async () => {
            const id = area._id;
            const name = area.name;
            const message = `Är du säker på att du vill radera ${name}?`;
            if (window.confirm(message)) {
              await ServiceProjects.removeProjectArea(this.props.currentProject.slug, id);
              this.props.refreshCurrentProject();
              this.props.history.push(this.props.location.pathname.split('/').slice(0,4).join('/'));
            }
          }
                  
          return (
            <div
              className={`item`}
              key={area._id}
            >
              <div className="tab-container">
                <Tabs 
                  tabs={["Översikt"]} 
                  selected={0}
                  onChange={() => {}}
                />
                <div className="tab-actions">
                  {/* <BtButton color="white" leftIcon={<EditIcon height="16"></EditIcon>}>Byt namn</BtButton> */}
                  <div style={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
                      {/* <span>Öppna förfrågning</span> */}
                    {val.isPublic ? (
                      <BtButton leftIcon={<FlyerTakenLightIcon/>} color="white" onClick={this.showUnpublishModal.bind(this)}>Avpublicera förfrågan</BtButton>
                    ) : (
                      <BtButton leftIcon={<FlyerTakenLightIcon/>} color="white" onClick={this.showPublishModal.bind(this)}>Publicera förfrågan</BtButton>
                    )}
                    <BtButton 
                      color="white" 
                      leftIcon={<TrashIcon color="var(--gray-700)" size="16" />}
                      onClick={removeArea}
                    >
                      Ta bort
                    </BtButton>
                  </div>
                </div>
              </div>
              <AppProjectSinglePurchase
                attachments={val.attachment}
                key={area._id}
                step={val.step}
                areaStep={val.step}
                areaId={area._id}
                dateEnd={val.endDate}
                quotations={val.quotations}
                label={area.name}
                areaSlug={area.slug}
                suppliers={val.suppliers}
                budget={val.budget}
                currentProject={this.props.currentProject}
                setCurrentProject={this.props.setCurrentProject}
                allowEditing={this.props.allowEditing}
                isWinnerEditable={this.props.isWinnerEditable}
                winnerChangeEvent={this.winnerChangeEventHandler.bind(this, area)}
                changeStepEvent={(step, skipStepChange) => this.changeStepEventHandler(area, skipStepChange, step)}
                dateChangeEvent={this.dateChangeEventHandler.bind(this, area)}
                onChangeMeeting={this.onChangeMeeting.bind(this, area)}
                onCallDetail={this.onCallDetail.bind(this, area)}
                onSupplierDate={this.onSupplierDate.bind(this, area)}
                changeCallIcon={this.changeCallIcon.bind(this, area)}
                changeAreaBudget={this.changeAreaBudget.bind(this, area)}
                reRender={this.reRender.bind(this)}
                refreshCurrentProject={this.props.refreshCurrentProject}
                users={this.props.users}
                addSupplier={this.props.addSupplier}
                createNewVersion={this.props.createNewVersion}
                createContract={this.contractUploadHandler.bind(this, val)}
                uploadContract={this.elBtnAttachClickCallback.bind(this, area._id)}
                deleteContract={this.uploadItemRemoveClickCallback.bind(this, area._id)}
                manualContract={val.manualContract && [...val.manualContract]}
                wizard={this.state.wizard}
              ></AppProjectSinglePurchase>
              {val.step >= 3 && (
                <input
                  type="file"
                  onClick={event => {
                    event.target.value = null;
                  }}
                  onChange={e =>
                    this.elInputFileChangeCallback(e, area._id)
                  }
                  ref={el => {console.log("ref",el);(this[`elInputFile${area._id}`] = el)}}
                  hidden={true}
                  accept={allowedFileExtensions.join(', ')}
                />
              )}
            </div>
          );
        });  
      } else {
        return (
          <DataTable
            isScrollable
            isLoading={false}
            columns={tableColumns}
            data={stepFilteredAreas.map(a => ({...a, id: a?.areaId?._id}))}
            onRowClick={this.onRowClick}
          />
          
        );  
      }
    } else {
      return (
        <div className="empty-project">
          <h2>Inga upphandlingar</h2>
          <p>Skapa en upphandling så kommer den visas här.</p>
          <CurvedArrowIcon className="arrow" />
        </div>
      );
    }
  }

  setSortOrder = async sortOrder => {
    this.setState({sortOrder});
    await ServiceUser.updatePreferences(
      appState.getUserId(),
      { sortOrder, columns: this.state.columns },
      appEnum.userPrefrences.projectOverview,
    );
  }

  setColumns = async columns => {
    this.columns = this.createColumns(); // force reordering
    this.setState({ columns });
    await ServiceUser.updatePreferences(
      appState.getUserId(),
      { columns, sortOrder: this.state.sortOrder },
      appEnum.userPrefrences.projectOverview,
    )
  }

  showPublishModal = () => {
    this.setState({ showPublishModal: true });
  }
  hidePublishModal = () => {
    this.setState({ showPublishModal: false });
  }
  onPublish = async () => {
    await ServiceProjects.updateProjectAreaIsPublic(this.props.currentProject.slug, this.props.selectedArea, true);
    this.setState({ showPublishModal: false });
  }
  showUnpublishModal = () => {
    this.setState({ showUnpublishModal: true });
  }
  hideUnpublishModal = () => {
    this.setState({ showUnpublishModal: false });
  }
  onUnpublish = async () => {
    await ServiceProjects.updateProjectAreaIsPublic(this.props.currentProject.slug, this.props.selectedArea, false);
    this.setState({ showUnpublishModal: false });
  }

  render() {
    // console.log({props:this.props,state:this.state})
    const { searchValue, setSearchValue } = this.props;
    let projectAreas =
      this.props.currentProject.area.filter(a => a.isQuoteSent) || false;
    let sortOptions = Object.keys(SortOrder).map(sortOrder => ({
      label: SortOrderLabels[sortOrder], 
      action: () => { this.setSortOrder(sortOrder); 
    }}));

    return (
      <div
        className={`app-project-overview upload-pdf-scction-main${
          projectAreas && projectAreas.length > 0 ? "" : "no-area-msg"
        }`}
        ref={ref => (this.element = ref)}
      >
        {!this.props.selectedArea && (
          <SearchBarWithActions value={searchValue} onChange={(value) => setSearchValue(value)}>
            <div className="sort-order-selector">
              {this.props.step === 6 && (
                <CustomizationMenu
                  columns={this.state.columns}
                  setColumns={this.setColumns}
                />
              )}
              <OptionsMenu selectedItem={SortOrderLabels[this.state.sortOrder]} options={sortOptions} style={{display:"inline-block"}}>
                Sortera på <strong>{SortOrderLabels[this.state.sortOrder]}</strong> <ExpandMoreIcon fontSize="small" />
              </OptionsMenu>
            </div>
          </SearchBarWithActions>
        )}
        <div style={{marginTop: "16px"}}>
          {this.renderOverviewItems()}
        </div>
        <Modal 
          title="Avpublicera förfrågan" 
          show={this.state.showUnpublishModal} 
          setShow={this.hideUnpublishModal.bind(this)}
          buttonInfo={{label: "Avpublicera", action: this.onUnpublish.bind(this)}}
        >
          <div style={{fontSize: "14px", color: "var(--gray-700)", maxWidth: "500px"}}>
            <p>Om du avpublicerar förfrågan kommer den endast vara öppen för de leverantörer du skickat den till direkt.</p>            
          </div>
        </Modal>
        <Modal 
          title="Publicera förfrågan" 
          show={this.state.showPublishModal} 
          setShow={this.hidePublishModal.bind(this)}
          buttonInfo={{label: "Publicera", action: this.onPublish.bind(this)}}
        >
          <div style={{fontSize: "14px", color: "var(--gray-700)", maxWidth: "500px"}}>
            <p>Att publicera förfrågan öppnar upp för fler leverantörer än de specifikt inbjudna och gör det möjligt att få in fler anbud. </p>
            <p>Publicerade förfrågningar visas för de leverantörer som har eget konto i Accurator och prenumererar på tjänsten "publicerade förfrågningar".</p>
            <p>Om inte leverantören redan finns i ert leverantörsregister skapas automatiskt en leverantörsansökan vid anbud.</p>
            <p>Du kan avpublicera förfrågan när som helst.</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(AppProjectPurchaseList);