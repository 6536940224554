import React, { useEffect, useState } from 'react';
import axios from 'axios';

import serviceCreditSafe from '../../services/service-creditSafe';
import { areEqual } from '../../util';
import { Button, Loading } from '../../components/shared';
import { CrossSvg } from '../../components/svgs/cross';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import Checkbox from '@material-ui/core/Checkbox';
import RiskIndicator from './riskIndicator';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import StarRating from '../../components/shared/Rating';
import appState from '../../state/AppStateContainer';
import moment from "moment";
import 'moment/locale/sv.js';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import ContractIcon from '../../components/svgs/contractIcon';
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';
import BlockIcon from '@material-ui/icons/Block';
import SupplierBlockedReasons from './supplier-blocked-reasons';
import SupplierControls from '../app-add-supplier/supplier-controls';
import File from '../../components/File/File';
import { FileScope, PrequalificationDecision } from '../../util/appEnum';
import { getImageUrl } from '../../components/shared/ImagePreview/ImagePreview';
import BtButton from '../bt-button/bt-button';
import { ServiceSupplier } from '../../services/service-supplier';
import * as Sentry from "@sentry/react";
import { ServiceDashboard } from '../../services/service-dashboard';
import ServiceSales from '../../services/service-sales';
import PlaceSelector from '../page-supplier-presentation/PlaceSelector';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIconLight.svg';
import { ReactComponent as VerifiedIcon } from '../../assets/svg/verifiedIcon.svg';
import BtRow from '../bt-row/bt-row';
import { ReactComponent as MonitoringIcon } from "../../assets/svg/monitor-icon.svg";

const formatDateTime = (date) => date ? moment(date).locale('sv').format('D MMMM YYYY HH:mm') : ''

const SupplierProfileField = ({ supplierValue, supplierProfileValue, renderValue=x=>x, isMonitored }) => {
  console.log({supplierProfileValue, supplierValue})
  const monitorTooltip = () => {
    return isMonitored && (
      <TooltipContainer renderReferenceComponent={(className, ref) => (
        <span className={className} ref={ref} style={{ position: 'relative', width: 'fit-content', marginLeft: '8px', }}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'bottom', }}><path d="M9 0L11.2249 2.15239L14.2901 1.71885L14.8249 4.76795L17.5595 6.21885L16.2 9L17.5595 11.7812L14.8249 13.2321L14.2901 16.2812L11.2249 15.8476L9 18L6.77508 15.8476L3.70993 16.2812L3.17508 13.2321L0.440492 11.7812L1.8 9L0.440492 6.21885L3.17508 4.76795L3.70993 1.71885L6.77508 2.15239L9 0Z" fill="#E5E7EB"></path></svg>
          <MonitoringIcon style={{
              width: '10px',
              height: '10px',
              position: 'absolute',
              top: 'calc(50% - 3.5px)',
              left: 'calc(4px)',
              color: 'var(--gray-500)',
            }}
          />
        </span>
      )}>
        <div style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>
          <p style={{ marginTop: 0 }}><b>Bevakning</b></p>
          Leverantören bevakas i Syna.
        </div>
      </TooltipContainer>
    )
  }
  if (!supplierProfileValue?.trim()) return <>{renderValue(supplierValue)}{monitorTooltip()}</> ;
  if (!supplierValue?.trim()) return <>{renderValue(supplierProfileValue)}{monitorTooltip()}</>;
  if (supplierValue?.trim() == supplierProfileValue?.trim()) {
    return (<div>
      {renderValue(supplierValue)}
      <TooltipContainer renderReferenceComponent={(className, ref) => <VerifiedIcon className={className} ref={ref} style={{ marginLeft: "8px", verticalAlign: "bottom" }} />}>
        <div style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>
          <p style={{ marginTop: 0 }}><b>Leverantörsprofil</b></p>
          Matchar leverantörens egna uppgifter.
        </div>
      </TooltipContainer>
      {monitorTooltip()}
    </div>);
  }

  return (<div>
    {renderValue(supplierValue)}
    <TooltipContainer renderReferenceComponent={(className, ref) => <InfoIcon className={className} ref={ref} style={{ marginLeft: "8px" }} />}>
      <div style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>
        <p style={{ marginTop: 0 }}><b>Leverantörsprofil</b></p>
        Leverantören har angett <span style={{fontWeight: 500, color: "var(--gray-900)"}}>{supplierProfileValue}</span>
      </div>
    </TooltipContainer>
    {monitorTooltip()}
  </div>);
}

const SupplierDetailPanel = ({
  rowData,
  openCreditReport,
  reportData,
  supId,
  tableRef,
  hasSyna,
  selectMode,
  onSelectContactPerson,
  renderActions,
  checkbox,
  onFeaturedSupplierSubmit,
  history,
}) => {
    const [isCreditSafeConfigured, setCreditSafeConfigured] = useState(null);
    const [contacts, setContacts] = useState(rowData.supMember);
    const [quoteContacts, setQuoteContacts] = useState(rowData.selectedContactPersons);
    const [showAllFiles, setShowAllFiles] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const supplierProfile = rowData.supplierProfile;
    console.log({supplierProfile});
    console.log({rowData});
    const supplierAreaIds = rowData.areaArray.map(a => a._id).sort().join(",");
    const profileAreaIds = supplierProfile?.areas.map(a => a._id).sort().join(",");
    const supplierProfileAreas = (supplierAreaIds != profileAreaIds) ? supplierProfile.areas.map(a => a.name) : [];
    
    const renderFiler = (name, type) => {
      return (
        <li>
          <span className={`sp-file-format sp-file-format-${type}`}></span> {name}
        </li>
      );
    };

    const handleClose = () => {
      if (tableRef && tableRef.current)
        tableRef.current.onToggleDetailPanel([0], rowData => <div>{rowData.name}</div>);
    };

    const isChecked = cp => {
      let cpIndex = quoteContacts && quoteContacts.findIndex(c => c.supplierMemberId === cp._id);
      return cpIndex > -1;
    };

    const renderRecommendedContact = d => {
      return (
        <li className="authSign" key={d._id || d.email}>
          <div className="contact-name">
            <div>{d.name}</div>
            <div>{d.title}</div>
          </div>          
          <div className="contact-email-and-phone">
            <div>{d.email}</div>
            <div>{d.phone}</div>
          </div>
        </li>
      );
    }

    const renderContactPerson = d => {      
      const renderProfileInfo = (d) => {
        const otherEmailAddress = supplierProfile.otherEmailAddresses.find(o => o.email == d.email);      
        const contact = supplierProfile.contacts.find(c => c.email == d.email);

        if (contact) {
          const diff = [
            contact.name && contact.name != d.name && <div>Namn: {contact.name}</div>,
            contact.title && contact.title != d.title && <div>Titel: {contact.title}</div>,
            contact.phone && contact.phone != d.phone && <div>Telefon: {contact.phone}</div>,
          ].filter(x=>x);
          return (
            <div style={{width: "26px"}}>
              <TooltipContainer renderReferenceComponent={(className, ref) => <VerifiedIcon className={className} ref={ref} style={{ marginRight: "8px" }} />}>
                <div style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>                
                  {diff.length > 0 ? (<>
                    E-postadressen matchar leverantörens egna uppgifter, men följande uppgifter skiljer sig:
                    {diff}
                  </>) : (
                    <>Kontaktpersonen matchar leverantörens egna uppgifter.</>
                  )}
                </div>
              </TooltipContainer>
            </div>
          );
        }
        if (otherEmailAddress) {
          if (otherEmailAddress.redirectTo) {
            const supplierProfileContact = supplierProfile.contacts.find(c => c._id == otherEmailAddress.redirectTo);
            const email = supplierProfileContact?.email;
            const contact = contacts.find(c => c.email == email);
            const name = contact?.name ?? supplierProfileContact?.name;
            if (name) {
              return (
                <div style={{width: "26px"}}>
                  <TooltipContainer renderReferenceComponent={(className, ref) => <InfoIcon className={className} ref={ref} style={{ marginRight: "8px" }} />}>
                    <div style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>
                      Leverantören rekommenderar: Kontakta <span style={{fontWeight: 500, color: "var(--gray-900)"}}>{name}</span> i stället 
                      {otherEmailAddress.comment && ` (${otherEmailAddress.comment})`}
                      .
                    </div>
                  </TooltipContainer>
                </div>
              );
            }
          } else if (otherEmailAddress.comment) {
            return (
              <div style={{width: "26px"}}>
                <TooltipContainer renderReferenceComponent={(className, ref) => <InfoIcon className={className} ref={ref} style={{ marginRight: "8px" }} />}>
                  <div style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>
                    Meddelande från leverantören: <span style={{fontWeight: 500, color: "var(--gray-900)"}}>{otherEmailAddress.comment}</span>
                  </div>
                </TooltipContainer>
              </div>
            );
          }
        }
        return null;
      }

      return (
        <li className="authSign" key={d._id || d.email}>
          <div style={{display:"flex", alignItems: "center"}}>
            {renderProfileInfo(d)}
            <div style={{flexGrow: 1}}>
              <div className="contact-name">
                <div>{d.name}</div>
                <div>{d.title}</div>
              </div>          
              <div className="contact-email-and-phone">
                <div>{d.email}</div>
                <div>{d.phone}</div>
              </div>
            </div>
          </div>
        </li>
      );
    };

    const renderTitle = title => (
      <div className="detail-sec-title">
        <span className="detail-sec-title-title">{title}</span>
      </div>
    );

    const renderCreditSafeDetails = () => (
      <div className="ar-row sp-detail-bottom-row">
        <div className="ar-column">
          <div className="ar-row">
            <div className="ar-column">
              <div className="sp-detail-bottom-col">
                <div className="sp-detail-bottom-lbl">Sate</div>
                <div className="sp-detail-bottom-lbl-name">Stockholm</div>
              </div>
            </div>

            <div className="ar-column">
              <div className="sp-detail-bottom-col">
                <div className="sp-detail-bottom-lbl">Leverans</div>
                <div className="sp-detail-bottom-lbl-name">Hela Sverige</div>
              </div>
            </div>

            <div className="ar-column">
              <div className="sp-detail-bottom-col">
                <div className="sp-detail-bottom-lbl">Antal anstallda</div>
                <div className="sp-detail-bottom-lbl-name">{employee}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

    const renderFileDateAndComment = (item, activeLabel, inactiveLabel, expiresLabel, scope, scopeOptions) => {
      let active = false;
      let end;
      const pickedDate = item.validUntilDate || item.submittedDate;
      if (pickedDate) {
        end = new Date(pickedDate);
        end.setHours(23, 59, 59, 999);
        active = new Date() < end;
      }
      return <React.Fragment>
        <div className="sp-control-row">
          {item.file && 
            <File
              style={{ marginTop: '5px', display: 'flex' }}
              file={item.file.file}
              fileName={item.file.fileName}
              scope={scope ?? FileScope.SupplierFiles}
              scopeOptions={scopeOptions ?? {
                supplierId: supId
              }}
            />
          }
          {(activeLabel || inactiveLabel) && (<div key="indicator">
            {pickedDate && (
              <React.Fragment>
                {active && (
                  <div className="collective-agreementFlag true">
                    <CheckIcon /> {activeLabel}
                  </div>
                )}
                {!active && (
                  <div className="collective-agreementFlag false">
                    <CloseIcon /> {inactiveLabel}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          )}
          {end && 
            <span>{expiresLabel + " " + end.toLocaleDateString()}</span>
          }
        </div>
        <div className="contact-email-and-phone" style={{marginTop: "-14px", marginBottom: "16px"}}>
          {item.comment}
        </div>
      </React.Fragment>
    }

    const renderFrameworkAgreement = (item) => {
      if (!item) return;
      return item && (item.file || item.validUntilDate || item.comment) &&
        <React.Fragment>
          {renderTitle("Ramavtal")}
          {renderFileDateAndComment(item, "Aktivt", "Utgånget", "Giltigt till")}
        </React.Fragment>
    }
	
    const renderLiabilityInsurance = (item, label, fileScope, fileScopeOptions) => {
      if (!item) return;
      return (item?.file?.file || item?.file?.fileName || item?.validUntilDate || item?.comment) &&
        <React.Fragment>            
          <b>{label}</b><br/>
          {renderFileDateAndComment(item, "Aktiv", "Utgången", "Giltig till", fileScope, fileScopeOptions)}
        </React.Fragment>
    }

    const renderUE2021Form = (item) => {
      if (!item) return;
      return item && (item.file || item.submittedDate || item.comment) &&
        <React.Fragment>            
          <b>UE2021</b><br/>
          {renderFileDateAndComment(item, null, null, "Inskickat")}
        </React.Fragment>
    }

    const addFeaturedSupplierToRegistry = async (supplierId) => {
      setIsLoading(true);
      if (!supplierId) return;

      try {
        const response = await ServiceSupplier.addFeaturedSupplierToRegistry(supplierId);
        onFeaturedSupplierSubmit(response.name);
      } catch (error) {
        const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
        NotificationManager.error(errorMsg, 'Misslyckat');
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    }
  
    return (
      <div className="sp-row-detail-wrapper-wrapper">
        <div className="sp-row-detail-wrapper-pre">
          <div className="sp-row-detail-wrapper-pre-inner"></div>
        </div>
      <div className="sp-row-detail-wrapper">
        <div className="ar-row">
          <div className="ar-column detail-left-col">
            <div className="detail-sec-title main">
              {checkbox}
              <div onClick={handleClose} style={{ display: 'flex', width: '100%' }}>
                <span className="detail-sec-title-title" style={rowData.isBlacklist ? {color: "var(--gray-400)"} : {}}>
                  {rowData.isBlacklist && (
                    <span className="blacklistWarningHover">
                      <TooltipContainer renderReferenceComponent={(className, ref) => <BlockIcon style={{ verticalAlign: "middle", marginRight: '15px'}} className={className} innerRef={ref} />}>
                        {rowData.reasons && rowData.reasons.length > 0 && (
                          <SupplierBlockedReasons reasons={rowData.reasons} />
                        )}
                      </TooltipContainer>
                    </span>
                  )}

                  {rowData.frameworkAgreement && (rowData.frameworkAgreement.file || rowData.frameworkAgreement.validUntilDate || rowData.frameworkAgreement.comment) && 
                    <ContractIcon style={{ marginRight: "10px", marginLeft:0, width: "20px", verticalAlign: "middle" }} />
                  }
                  <SupplierProfileField supplierProfileValue={supplierProfile.name} supplierValue={rowData.sup_name} isMonitored={rowData.isMonitored} />
                  {rowData.organizationNumber && (<span>, <span style={{fontWeight: "normal"}}>{rowData.organizationNumber}</span></span>)}
                </span>

                {(rowData.isFeatured && appState.getCurrentOrgSlug() !== 'superadmin') && (
                  <BtButton
                    style={{ marginLeft: '16px', fontSize: '14px', cursor: !rowData?.exists ? 'cursor' : 'default' }}
                    loaderShow={isLoading}
                    disabled={rowData?.exists}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!rowData?.exists) {
                        ServiceDashboard.logRegistryAddition(rowData.sup_id)
                        addFeaturedSupplierToRegistry(rowData.sup_id);
                      }
                  }}>
                    {!rowData?.exists ? (appState.isAdminRole() ? 'Lägg till i registret' : 'Lägg till som ansökan') : 'Redan tillagt i registret'}
                  </BtButton>
                )}
                

                {isCreditSafeConfigured && renderCreditSafeDetails()}
              </div>   
            </div>
            <div className="sp-action-wrapper">
              {renderActions(supId, rowData)}
            </div>
          </div>
        </div>

        <div className="ar-row">
          <div className="ar-column detail-left-col">
            <div className="detail-left-desc" style={{whiteSpace:"pre-line"}}>
              {supplierProfile.orgNr && (<>
                {renderTitle('Leverantörsprofil')}
                {supplierProfile.logo && <img style={{ maxWidth: '300px' }} src={getImageUrl(supplierProfile.logo.file, appState.getCurrentOrgSlug(), FileScope.SupplierProfileLogo, { organizationNumber: supplierProfile.orgNr })} />}
                <p>{supplierProfile.presentation}</p>
                {renderTitle('Platser')}
                <PlaceSelector places={supplierProfile.places} readonly />
              </>)}
              {renderTitle('Om leverantören')}
              <p>{rowData.description}</p>
              {rowData?.logo?.file && <img style={{ maxWidth: '300px' }} src={getImageUrl(rowData.logo.file, appState.getCurrentOrgSlug(), FileScope.SupplierLogo, {supplierId: supId})} />}
            </div>
              {renderFrameworkAgreement(rowData.frameworkAgreement)}
            {(rowData.website || supplierProfile.website) && (
              <>
                {renderTitle("Webbplats")}
                <SupplierProfileField supplierProfileValue={supplierProfile.website} supplierValue={rowData.website} renderValue={
                  value => (
                    <a href={(value?.includes('://') ? "" : "https://") + value}>
                      {value}
                    </a>
                  )
                } />
              </>
            )}
            {renderTitle('Yrkesområden')}
            <div>
              {rowData.area}
              {supplierProfileAreas.length > 0 && (
                <TooltipContainer renderReferenceComponent={(className, ref) => <InfoIcon className={className} ref={ref} style={{ marginLeft: "8px", verticalAlign: "bottom" }} />}>
                  <div style={{maxWidth: "512px", fontWeight: 400, fontSize: "14px", color: "var(--gray-700)" }}>
                    <p><b>Leverantörsprofil</b></p>
                    Leverantören har angett <span style={{fontWeight: 500, color: "var(--gray-900)"}}>{supplierProfileAreas}</span>
                  </div>
                </TooltipContainer>
              )}
            </div>
            {rowData?.otherFiles?.length > 0 && (
              <>
                {renderTitle(`Filer (${rowData.otherFiles.length})`)}
                <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
                  {rowData.otherFiles.map((file, index) => (index < 3 || showAllFiles) ? (
                    <TooltipContainer key={index} delayed renderReferenceComponent={(className, ref) => <File className={className} ref={ref} scope={FileScope.SupplierFiles} scopeOptions={{ supplierId: supId }} style={{ width: 'fit-content', placeItems: 'flex-start' }} file={file.file || ''} fileName={file.fileName} disabled={!file.file} />}>
                      {file.uploadedBy && file.uploadedAt ? <span style={{ whiteSpace: 'nowrap' }}>{`Tillagd ${moment(file.uploadedAt).format('YYYY-MM-DD HH:mm')} av ${file.uploadedBy}`}</span> : ''}
                    </TooltipContainer>
                  ) : null)}
                </div>
              </>
            )}
            {rowData?.otherFiles?.length > 3 && !showAllFiles && (
              <a onClick={() => setShowAllFiles(true)} style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '8px' }}>
                Visa alla filer
              </a>)}

              {rowData?.application && (
                <>
                  {renderTitle(`Ansökan`)}
                  <div>
                    {rowData?.prequalification?.decidedAt && (<div style={{marginBottom: "16px"}}>
                      {{
                        [PrequalificationDecision.APPROVED]: "Godkänd",
                        [PrequalificationDecision.REJECTED]: "Nekad",
                        [PrequalificationDecision.INBOX]: "Omstartad",
                      }[rowData?.prequalification.decision]} den {formatDateTime(rowData?.prequalification.decidedAt)} av {rowData?.prequalification.decidedByName}.
                    </div>)}
                    {rowData?.prequalification.decision == PrequalificationDecision.INBOX && (<>
                      Inskickad den {formatDateTime(rowData?.createdAt)} av {[
                        rowData?.application?.applicant?.name,
                        rowData?.application?.applicant?.title,
                        rowData?.application?.applicant?.email,
                        rowData?.application?.applicant?.phone,
                      ].filter(x=>x).join(", ")}.
                      {rowData?.application?.message && (<>
                        <p style={{whiteSpace: "pre-line"}}>
                          <span style={{fontWeight: "bold"}}>Meddelande</span><br/>
                          {rowData?.application?.message}
                        </p>
                      </>)}
                    </>)}
                    {appState.getCurrentOrgSlug() === 'superadmin' && (<>
                      <div className='sp-control-row' style={{marginTop: 16}}><b>Fakturaadress</b> {rowData?.application?.invoiceAddress}</div>
                      <div className='sp-control-row'><b>Märkning/referens</b> {rowData?.application?.invoiceReference}</div>
                      <div className='sp-control-row'><b>Avtalslängd</b> {rowData?.application?.invoiceInterval}</div>
                    </>)}
                  </div>
                </>
              )}
          </div>

          <div className="ar-column detail-right-col">
            {renderTitle('Erfarenheter')}
            {rowData.projectsCount ? (
              <div>
                <div className="sp-control-row">
                  <b>Rekommendationer</b>
                    <StarRating className="rating-component" rating={rowData.rating} readOnly />
                </div>          
                <div className="sp-control-row">
                  <b>Utvärderingar</b>
                  <div>
                    <a href={`/rating/${supId}/true`} className="iconed-btn">
                      <span className="iconed-btn-text">Visa utvärderingar</span>
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="sp-control-row">
                  <b>Rekommendationer</b>
                  <div>Inga projekt genomförda</div>
                </div>          
                <div className="sp-control-row">
                  <b>Utvärderingar</b>
                  <div>Inga projekt genomförda</div>
                </div>
              </div>
            )}
            {renderTitle('Kontaktpersoner')}
            <div className="details-right-inner">
              <div className="details-right-inner-list">
                {/* loading ? (
                  <Loading type="inline" color="#ff5e15" height={'80px'} width={'80px'} />
                ) : (
                  ) */}
                  <ul>
                    {contacts.map(renderContactPerson)}
                  </ul>
              </div>
            </div>
            {supplierProfile.contacts.filter(profileContact => !contacts.find(c => c.email == profileContact.email)).length > 0 && (<>
              {renderTitle('Leverantörens föreslagna kontaktpersoner')}
              <div className="details-right-inner">
                <div className="details-right-inner-list">
                    <ul>
                      {supplierProfile.contacts
                        .filter(profileContact => !contacts.find(c => c.email == profileContact.email))
                        .map(renderRecommendedContact)}
                    </ul>
                </div>
              </div>
            </>)}

          </div>

          <SupplierControls organizationNumber={rowData.organizationNumber} hasSyna={hasSyna} history={history}>
            {renderLiabilityInsurance(rowData.liabilityInsurance, "Ansvarsförsäkring")}
            {renderLiabilityInsurance(supplierProfile.liabilityInsurance, "Ansvarsförsäkring från leverantörsprofil", FileScope.SupplierProfileFile, {organizationNumber: rowData.organizationNumber})}
            {renderUE2021Form(rowData.ue2021Form)}
          </SupplierControls>
        </div>
      </div>
      <div className="sp-row-detail-wrapper-post">
          <div className="sp-row-detail-wrapper-post-inner"></div>
        </div>
      </div>
    );
  }

export default SupplierDetailPanel;
