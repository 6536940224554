import { FC, useEffect, useState } from "react";
import { Loading } from "../../components/shared";
import TitleWrapper from "../../components/shared/TitleWrapper/TitleWrapper";
import File from "../../components/File/File";
import { ServiceProjects } from "../../services/service-projects";
import BtButton from "../bt-button/bt-button";
import { TrashIcon } from "../../components/svgs";
import FileUpload from "../../components/shared/FileUpload/FileUpload";
import { ServiceContract } from "../../services/service-contract";
import { NotificationManager } from 'react-notifications';
import Field from "../../components/shared/Field/Field";
import { ServiceUser } from "../../services/service-user";
import { formatAmount } from "../../util";
import { FileScope } from "../../util/appEnum";

type Contract = {
  signedFileName?: string,
  fileName?: string,
  attachments: any[],
  file?: string,
  documentId?: string,
  createdAt?: Date,
  amount?: string,
  status: string,
  senderUserId: string,
  creditReport?: string,
}

type Props = {
  deleteContract: (manualContractId: string) => void,
  currentProject: {
    _id: string,
    slug: string,
    area: {
      areaId: {
        _id : string
      },
      contracts?: Contract[]
    }[]
  },
  areaSlug: string,
  areaId: string,
  isReadOnlyUser: boolean,
  hideModal: () => void,
  selectedContract: Contract,
  refreshCurrentProject: (a: boolean, b: boolean) => Promise<void> 
}

type ContractStatus = {
  status: string,
  parties: {
    email: string,
    name: string,
    phone: string,
    company: string,
    sign_time: Date,
    seen_time: Date,
    rejected_time: Date,
    rejection_reason?: string,
    status: string,
    sign_order: number,
  }[],
}

const SendAgainState = {
  IN_PROGRESS:"IN_PROGRESS", ERROR:"ERROR", SUCCESS:"SUCCESS"
}

const getSignedFileNameWithExtension = (signedFileName?: string) => {
  if (signedFileName && !signedFileName.endsWith(".pdf")) {
    signedFileName = signedFileName + ".pdf";
    return signedFileName;
  }
  return null;
}


const getSignatoryStatus = (p: any, status: string) => {
  if (p.sign_time) return "Signerat";
  if (p.rejected_time) return "Nekat";
  if (status == "timedout") return "Utgånget";
  if (status == "document_error") return "Dokumentfel";
  if (status == "canceled") return "Avbrutet";
  if (p.seen_time) return "Öppnat";
  if (p.status == "WAITING") return `Väntar`;
  if (p.status == "STOPPED") return `Ej längre möjligt`;
  return "Skickat";
}

const ContractModal: FC<Props> = ({ deleteContract, currentProject, areaSlug, areaId, isReadOnlyUser, hideModal, selectedContract, refreshCurrentProject }) => {
  const [sendAgain, setSendAgain] = useState<{[key: string]: string}>({});
  const [selectedContractSender, setSelectedContractSender] = useState<{name: string}>();
  const [selectedContractStatus, setSelectedContractStatus] = useState<ContractStatus>();
  const [selectedContractStatusError, setSelectedContractStatusError] = useState("");
  const [selectedContractStatusLoading, setSelectedContractStatusLoading] = useState(true);
  const [amount, setAmount] = useState(selectedContract?.amount ?? "");
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const refreshAndReturnCurrentProject = async (documentId: string|undefined, contract: Contract) => {
    setSelectedContractStatus(undefined);
    setSelectedContractStatusLoading(!!documentId);

    if (documentId) {
      await refreshCurrentProject(false, false);
      const area = currentProject.area.find(a => a.areaId._id == areaId);
      const foundContract = area?.contracts?.find(contract => contract.documentId === documentId);
      return { documentId: foundContract?.documentId, contract: foundContract }
    } else {
      return { documentId, contract };
    }
  }

  const showContractStatus = async (_documentId: string|undefined, _contract: Contract) => {
    const { documentId, contract } = await refreshAndReturnCurrentProject(_documentId, _contract);
    const user = contract?.senderUserId ? await ServiceUser.getUserById(contract.senderUserId) : null;
    setSelectedContractSender(user);
    setSendAgain({}),
    setSelectedContractStatusError("");

    if (documentId) {
      try {
        const status = await ServiceContract.getStatus(documentId);
        setSelectedContractStatus(status),
        setSelectedContractStatusLoading(false);
      } catch (e) {
        setSelectedContractStatusError("Kunde inte hämta status från Scrive: " + (e as Error).message);
        setSelectedContractStatusLoading(false);
      }
    }
  }

  useEffect(() => {
    showContractStatus(selectedContract?.documentId, selectedContract);
  }, [selectedContract]);

  const deleteSelectedContract = async () => {
    if (window.confirm("Är du säker på att du vill ta bort kontraktet?")) {
      
      if (selectedContract && !selectedContract.documentId && selectedContract.file) {
        // delete manualContract
        deleteContract(selectedContract.file);
        NotificationManager.success('Kontraktet har tagits bort', 'Borttaget' )
      } else {
        // delete contract
        const isPending = selectedContract?.documentId && !selectedContract?.signedFileName && selectedContractStatus?.status !== 'rejected'
        await ServiceProjects.dbDeleteContract(currentProject.slug, areaSlug, selectedContract.documentId);
        ServiceProjects.deleteContract(currentProject._id, areaId, selectedContract.documentId);
        if (isPending) {
          NotificationManager.success('Kontraktet har återkallats', 'Återkallat')
        } else {
          NotificationManager.success('Kontraktet har tagits bort', 'Borttaget' )
        }
      }
      hideModal();
    }
  }
  
  const sendContractEmailAgain = async (email: string, documentId: string) => {
    const setSendAgainState = (email: string, state: string) => {
      setSendAgain((prevState) => {
        const tempState = {...prevState};
        tempState[email] = state;
        return tempState;
      });  
    }
    setSendAgainState(email, SendAgainState.IN_PROGRESS);
    try {
      const projectId = currentProject._id;
      await ServiceContract.sendAgain(email, documentId, projectId, areaId);
      NotificationManager.success("E-post med signeringslänk skickad på nytt.")
      setSendAgainState(email, SendAgainState.SUCCESS);
    } catch (e) {
      NotificationManager.error("Kunde inte skicka om kontraktet: " + (e as Error).message)
      setSendAgainState(email, SendAgainState.ERROR);
    }
  }

  const saveChanges = async () => {
    setSaving(true);
    try {
      const projectId = currentProject._id;
      const response = await ServiceContract.updateContractDetails(selectedContract?.documentId, projectId, areaId, amount, selectedContract?.file);
      if (!selectedContract?.documentId) {
        ServiceProjects.addManualContract(projectId, areaId, response);
      }
      await refreshCurrentProject(false, false);
      hideModal();
    } catch (e) {
      console.log(e);
      NotificationManager.error("Kunde inte spara ändringar: " + (e as Error).message)
      setSaving(false);
    }
  }

  const isPending = selectedContract?.documentId && !selectedContract?.signedFileName && selectedContractStatus?.status !== 'rejected';
  return (<>
    {
      selectedContractStatusLoading ? (
        <div className="selectedContractLoading"><Loading type='inline' color="#ff5e15" size='30px' /></div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "24px", width: "600px" }}>
          <TitleWrapper title={'Dokument' + (selectedContract?.signedFileName && selectedContract?.attachments?.length > 0 ? " inklusive bilagor" : "")} className="selectedContractMessage">
            {(selectedContract?.fileName || selectedContract?.signedFileName) && (
              <div className="selectedContractRow">
                <File
                  scope={FileScope.Contract}
                  scopeOptions={{
                    projectId: currentProject._id,
                    areaId: areaId,
                  }}                
                  fileName={selectedContract?.fileName || "okänt filnamn.pdf"}
                  file={selectedContract?.signedFileName ?
                    getSignedFileNameWithExtension(selectedContract?.signedFileName) || "":
                    selectedContract?.file || selectedContract?.fileName || ""}
                />
                {!isReadOnlyUser && (
                  <div onClick={() => deleteSelectedContract()}>
                    <BtButton color="white" size="xxs"><TrashIcon color='var(--gray-400)' size="16px" />{isPending ? 'Återkalla' : 'Ta bort' }</BtButton>
                  </div>
                )}
              </div>
            )}
          </TitleWrapper>
          {selectedContract?.attachments && selectedContract?.attachments?.length > 0 && !selectedContract?.signedFileName && (
            <TitleWrapper title='Bilagor' className="selectedContractMessage">
              <FileUpload
                scope={FileScope.ContractAttachment}
                scopeOptions={{
                  projectId: currentProject._id,
                  areaId: areaId,
                }}
                existingFiles={selectedContract?.attachments}
                setExistingFiles={() => { }}
                newFiles={[]}
                setNewFiles={() => { }}
                readonly
              />
            </TitleWrapper>
          )}
          {selectedContract?.createdAt && (
            <TitleWrapper title='Skapat' className="selectedContractMessage">
              {selectedContract.createdAt && new Date(selectedContract.createdAt).toLocaleString("sv-SE", { dateStyle: "long", timeStyle: "short" }) + " "}
              {selectedContractSender && `av ${selectedContractSender.name}`}
            </TitleWrapper>
          )}
          {selectedContract?.creditReport && (
            <TitleWrapper title='Sparad kreditupplysning' className="selectedContractMessage">
              <File fileName={`Sparad kreditupplysning för ${selectedContract.fileName}`} url={`/api/project/${currentProject.slug}/area/${areaSlug}/contract/${selectedContract.documentId}/creditReport`} />
            </TitleWrapper>
          )}
          <TitleWrapper title='E-signering'>
            <div className="selectedContractStatuses">
              {!selectedContractStatusLoading && selectedContractStatus && (
                selectedContractStatus.parties.map(p => {
                  const status = getSignatoryStatus(p, selectedContractStatus.status);
                  return (
                    <div className="signatoryRow" key={p.email}>
                      <span
                        className="signatoryName"
                        title={[p.name, p.email, p.phone, p.company].filter(x => x).join("\n")}
                      >
                        {["Signerat"].includes(status) && (<span style={{ color: "var(--green-500)" }}>• </span>)}
                        {["Nekat", "Utgånget", "Dokumentfel", "Avbrutet", "Ej längre möjligt"].includes(status) && (<span style={{ color: "var(--red-500)" }}>• </span>)}
                        {["Öppnat", "Skickat", "Väntar"].includes(status) && (<span style={{ color: "var(--yellow-500)" }}>• </span>)}
                        {p.name}
                      </span>
                      <span className="signatoryStatus" title={status == "Nekat" ? (p.rejection_reason || "Inget meddelande") : undefined}>{status}</span>
                      <div className="signatoryButtonWrapper">
                        <BtButton
                          class="white"
                          size="xxs"
                          style={{ fontSize: "12px", visibility: !["Skickat", "Öppnat"].includes(status) || isReadOnlyUser ? "hidden" : "visible" }}
                          onClick={() => sendContractEmailAgain(p.email, selectedContract?.documentId!)}
                          loaderShow={sendAgain[p.email] == SendAgainState.IN_PROGRESS}
                          disabled={sendAgain[p.email] == SendAgainState.SUCCESS || isReadOnlyUser}
                        >
                          {sendAgain[p.email] == SendAgainState.SUCCESS &&
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29 29">
                                <path
                                  fill="var(--green-500)"
                                  d="M14.5,0 C6.50390909,0 0,6.50390909 0,14.5 C0,22.4960909 6.50390909,29 14.5,29 C22.4960909,29 29,22.4960909 29,14.5 C29,6.50390909 22.4960909,0 14.5,0 Z M23.3423636,10.1605455 L12.7969091,20.706 C12.5385455,20.9617273 12.2010909,21.0909091 11.8636364,21.0909091 C11.5261818,21.0909091 11.1887273,20.9617273 10.9303636,20.706 L5.65763636,15.4332727 C5.14354545,14.9191818 5.14354545,14.0834545 5.65763636,13.5693636 C6.17172727,13.0552727 7.00745455,13.0552727 7.52154545,13.5693636 L11.861,17.9088182 L21.4731818,8.29663636 C21.9872727,7.78254545 22.823,7.78254545 23.3370909,8.29663636 C23.8511818,8.81072727 23.8564545,9.64381818 23.3423636,10.1605455 Z"
                                />
                              </svg> Skickat
                            </>
                          }
                          {sendAgain[p.email] != SendAgainState.SUCCESS &&
                            "Skicka igen"
                          }
                        </BtButton>
                      </div>
                    </div>
                  )
                })
              )}
              {!selectedContractStatusLoading &&
                !selectedContractStatus &&
                !selectedContractStatusError && (
                  <span className="selectedContractMessage">Ingen e-signering</span>
                )}
              {selectedContractStatusError && (
                <span className="selectedContractMessage">{selectedContractStatusError}</span>
              )}
            </div>
          </TitleWrapper>
          <>
            <h3 style={{display:"flex", justifyContent:"space-between", alignItems: "flex-end", borderBottom: 'solid 1px #E5E7EB', paddingBottom: '10px', fontWeight: 500}}>
              Kontraktsuppgifter 
              {!editing && !isReadOnlyUser && <BtButton size="xxs" color="white" onClick={() => setEditing(true)}>Redigera</BtButton>}
            </h3>
            <div>
              {editing ? (
                <Field
                  label="Kontraktssumma/uppskattad totalkostnad i SEK"
                  value={amount}
                  onChange={value => setAmount(formatAmount(value))}
                  errorMessage={""}
                  style={{ fontSize: '14px' }}
                  required
                />
              ) : (
                <div>
                  <div>Kontraktssumma/uppskattad totalkostnad i SEK</div>
                  <div style={{paddingTop:"8px", fontWeight: 400}}>{amount || "–"} kr</div>
                </div>
              )}
              <div style={{ marginTop: "8px"}}>
                <span style={{ fontSize: "var(--fontSize-14)", fontWeight: "var(--weight-400)", color: "var(--gray-700)" }}>Används för att analysera och följa upp inköpsvolymer och skillnader mellan anbud, kontraktssumma och slutkostnad.</span>
              </div>
            </div>
            {editing && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <BtButton loaderShow={saving} onClick={saveChanges}>Spara</BtButton>
              </div>
            )}
          </>
        </div>
      )
    }
  </>);
}

export default ContractModal;