import Card from "../../components/shared/Card/card"
import StyledCheckbox from "../../components/shared/StyledCheckbox/StyledCheckbox";
import { serviceOrganization } from "../../services/service-organization";
import appState from "../../state/AppStateContainer";
import styles from "./OrganizationSettings.module.scss";
import { autosave } from "../../util";
import AllQuotationsSubscription from "./AllQuotationsSubscription";
import RecommendedSubscription from "./RecommendedSubscription";
import PublishedQuotationsSubscription from "./PublishedQuotationsSubscription";

const OrganizationSettings = () => {
  const onlyAdminsCanCreateSuppliers : boolean = appState.getCurrentOrg()?.onlyAdminsCanCreateSuppliers ?? false;
  
  const toggleOnlyAdminsCanAddSuppliers = async () => {
    autosave(async () => {
      await serviceOrganization.saveSettings({
        onlyAdminsCanCreateSuppliers: !onlyAdminsCanCreateSuppliers,
      });
      await appState.fetchOrganizations();  
    });
  }

  const renderPurchasingSettings = () => {
    return (
      <label className={styles.checkboxLabel}><StyledCheckbox checked={onlyAdminsCanCreateSuppliers} onChange={toggleOnlyAdminsCanAddSuppliers}/> Endast administratörer kan lägga till leverantörer</label>
    );
  }

  const renderSalesSettings = () => {
    return (
      <div style={{fontSize: "14px", color: "var(--gray-700)"}}>
        <h3 style={{ fontSize: "18px", color: "var(--gray-900)", fontWeight: 500 }}>Prenumerationstjänster</h3>
        <AllQuotationsSubscription />
        <RecommendedSubscription />
        <PublishedQuotationsSubscription />
      </div>
    );
  }

  return (
    <Card>
      {appState.isPurchasingActive() && renderPurchasingSettings()}
      {appState.isSalesActive() && renderSalesSettings()}
    </Card>
  );
}

export default OrganizationSettings;