import axios from "axios";
import { AdApplicationStatus, InvoiceInterval } from "../ts-components/page-bid-request-list/types";
import { AdApplicationType } from "../util/appEnum";

type FeaturedAdInfo = {
  url: string,
  image: File,
  title: string,
  description: string,
  startDate: Date,
}

type AdApplicationFeaturedData = {
  featuredInfo: FeaturedAdInfo,
  invoiceInterval: InvoiceInterval,
}

type AdApplicationFetchData = {
  status?: AdApplicationStatus,
  type?: AdApplicationType,
  search?: string,
  sort?: {column: string, order: string},
  start?: number,
}

type AdApplicationStatusData = {
  status: AdApplicationStatus,
  rejectionReason?: string
}

class ServiceAdApplication {
  async fetchAdApplications(data: AdApplicationFetchData) {
    const res = await axios.get(`/api/sales/all-ad-applications`, { params: data });
    return res.data;
  }
  async fetchLocalAdApplications() {
    const res = await axios.get(`/api/sales/ad-applications`);
    return res.data;
  }

  async updateAdApplicationStatus(id: string, { status, rejectionReason }: AdApplicationStatusData) {
    const res = await axios.put(`/api/sales/ad-application-status/${id}`, {
      status,
      rejectionReason
    });
    return res.data;
  }

  async deleteAdApplication(id: string) {
    const res = await axios.delete(`/api/sales/ad-application/${id}`);
    return res.data;
  }

  async createFeaturedAdApplication(
    {
      featuredInfo,
      invoiceInterval
    }: AdApplicationFeaturedData
  ) {
    const payload: any = {
      featuredInfo: {
        url: featuredInfo.url,
        title: featuredInfo.title,
        description: featuredInfo.description,
        startDate: featuredInfo.startDate,
      },
      invoiceInterval,
    };

    const formData = new FormData();
    if (featuredInfo.image) {
      formData.append("file", featuredInfo.image);
    }
    formData.append("json", JSON.stringify(payload));

    const res = await axios.post(`/api/sales/featured-ad-application`, formData);
    return res.data;
  }
  async updateFeaturedAdApplication(
    id: string,
    {
      featuredInfo,
      invoiceInterval
    }: AdApplicationFeaturedData
  ) {
    const payload: any = {
      featuredInfo: {
        url: featuredInfo.url,
        title: featuredInfo.title,
        description: featuredInfo.description,
        startDate: featuredInfo.startDate,
      },
      invoiceInterval,
    };

    const formData = new FormData();
    if (featuredInfo.image) {
      formData.append("file", featuredInfo.image);
    }
    formData.append("json", JSON.stringify(payload));

    const res = await axios.put(`/api/sales/featured-ad-application/${id}`, formData);
    return res.data;
  }

  async createRecommendedAdApplication(
    data: {
      ad: {
        startDate: Date,
        regions: string[],
        areas: string[],
      },
      invoiceInterval: InvoiceInterval,
    }
  ) {
    const res = await axios.post(`/api/sales/recommended-ad-application`, data);
    return res.data;
  }

  async updateRecommendedAdApplication(
    id: string,
    data: {
      ad: {
        startDate: Date,
        regions: string[],
        areas: string[],
      },
      invoiceInterval: InvoiceInterval,
    }
  ) {
    const res = await axios.put(`/api/sales/recommended-ad-application/${id}`, data);
    return res.data;
  }
}

export const serviceAdApplication = new ServiceAdApplication();