import { FC, useState } from "react";
import AppAddEditSupplier from "../app-add-supplier/app-add-supplier";
import { serviceAreas } from "../../services/service-areas";
import appState from "../../state/AppStateContainer";
import styles from "./page-supplier-signup.module.scss";
import Card from "../../components/shared/Card/card";

type Props = {}
type Area = { name: string, _id: string }

const SupplierSignup : FC<Props> = ({}) => {
  const [areas, setAreas] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const renderFooter = () => {
    return (
      <footer className='bidFormFooter'>
        <img src="https://assets.website-files.com/60cb60c79838d8d947708ce8/6200ef55808a7d7751fdb8a5_accurator_logo.png" alt="Accurator" width="140" height="48" />
        
        <p>
          Vid frågor om Accurator, kontakta Accurator support:<br/>
          08-156 225, <a href='mailto:support@accurator.se'>support@accurator.se</a>
        </p>

        <p>
          <em>Hur lämnar jag anbud?</em><br/>
          <a target='_blank' rel='noreferrer' href="https://www.accurator.se/sidor/information-for-leverantorer">Instruktionsvideo för leverantörer</a>
        </p>
      </footer>
    )
  }

  const fetchAreas = async () => {
    const areasData = await serviceAreas.getAreas();
    const areas = areasData.forEach((item : Area) => ({ label: item.name, value: item._id }));
    setAreas(areas);
  };

  const orgName = appState.getCurrentOrgName();

  return <>          
    <img src={appState.getCurrentOrgSlug() === 'superadmin' ? 'https://assets.website-files.com/60cb60c79838d8d947708ce8/6200ef55808a7d7751fdb8a5_accurator_logo.png' : `/api/organizations/${appState.getCurrentOrgSlug()}/logo`} style={{display: "block", margin: "2em auto", maxWidth: "350px"}} />
    {submitted ? (
      <Card className={styles.signup} title="Ansökan skickad">
        <p>Tack för din ansökan. Vi granskar uppgifterna och återkommer så snart som möjligt.</p>
      </Card>
    ) : (
      <Card className={styles.signup} title={appState.getCurrentOrgSlug() === 'superadmin' ? "Beställningsformulär: Rekommenderad leverantör" : "Leverantörsansökan"}>
        {appState.getCurrentOrgSlug() === 'superadmin' ? (<>
          <p>Använd detta formulär för att beställa Accurators tilläggstjänster för leverantörer. Uppgifterna sparas i en leverantörsdatabas i inköpssystemet Accurator. <a href="https://www.accurator.se/sidor/gdpr">Läs om personuppgiftsbehandlingen.</a></p>
          <p>Accurators rekommenderade leverantörer måste uppfylla vissa krav. Vi granskar därför alla beställningar innan de godkänns. Fyll i beställningen så hör vi av oss så snart som möjligt.</p> 
        </>) : (<>
          <p>Ansök om att bli leverantör till {orgName}. Uppgifterna sparas i en leverantörsdatabas i inköpssystemet Accurator. <a href="https://www.accurator.se/sidor/gdpr">Läs om personuppgiftsbehandlingen.</a></p>
          <p>Det är {orgName} som granskar och bedömer ansökan. Om ansökan godkänns kan kontaktpersonerna nedan börja få förfrågningar från {orgName} via e-post från noreply@accurator.se.</p> 
        </>)}
        
        <AppAddEditSupplier 
          show={true}
          setShow={() => {}}
          saveSupplier={() => {
            setSubmitted(true);
          }}
          editSupplierData={ {
            name: '',
            supplierId: '',
            areaIds: [],
            organizationNumber: '',
            description: '',
          }}  
          onModalHide={() => {}}
          fetchNewAreas={fetchAreas}
          areas={areas}
          supplierApplication={true}
        />
      </Card>  
    )}
    {renderFooter()}
  </>;
}

export default SupplierSignup;