
import { FC, ReactNode, useEffect, useState } from "react";
import Loading from "../../components/shared/Loading";
import CollectiveAgreement from "../app-supplier-database/collectiveAgreement";
import BtButton from "../bt-button/bt-button";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { ServiceSyna } from "../../services/service-syna";
import ServiceCollectiveAgreement from '../../services/service-collectiveAgreement';
import { ServiceId06 } from '../../services/service-id06';
import appState from "../../state/AppStateContainer";
import axios from "axios";
import { NotificationManager } from 'react-notifications';
import serviceSupplierMonitoring, { MonitoredSupplier } from "../../services/service-supplier-monitoring";
import { ReactComponent as MonitoringHeartIcon } from '../../assets/svg/monitoringHeartIcon.svg';
import { RouteComponentProps } from "react-router-dom";
import { formatDate } from "../../util";

type Params = {};

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  organizationNumber: string,
  children?: ReactNode,
  hasSyna: boolean,
  className?: string,
  history: RouteTypes['history'],
}

type Id06 = {
  status: boolean,
  cards: number,
  error: null,
}

type SynaDetails = {
  sate: string,
  fskatt: boolean,
  momsreg: boolean,
  arbetsgivarreg: boolean,
  revenue: string,
  employees: number,
  arbgivavg: { amount: number, year: number, month: number },
  error: null,
  rating: number,
  comment: string,
  postponedTax: { date: string, amount: number }[],
}
type ByggInstSektornAgreement = {
  Medlemsorganisation: string,
  Avtal: string,
  FackligMotpart: string,
}

type CollectiveAgreements = {
  kollektivavtalskollen: {
    source_data: any,
    error: string,
  },
  byggInstSektorn: {
    legalName: string,
    organizationNumber: string,
    collectiveAgreements: ByggInstSektornAgreement[],
    error: string,
  },
}

type Agreement = {}

const YesNo: FC<{ value: boolean }> = ({ value }) => {
  return value ? (
    <div className="collective-agreementFlag true">
      <CheckIcon /> Ja
    </div>
  ) : (
    <div className="collective-agreementFlag false">
      <CloseIcon /> Nej
    </div>
  )
}

const SupplierControls: FC<Props> = ({ organizationNumber, children, hasSyna, className, history }) => {
  const [id06, setId06] = useState<Id06 | null>(null);
  const [agreement, setAgreement] = useState<Agreement | null>(null);
  const [synaDetails, setSynaDetails] = useState<SynaDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [agreementError, setAgreementError] = useState("");
  const [monitoredSupplier, setMonitoredSupplier] = useState<MonitoredSupplier>();
  const synaLoader = false;

  useEffect(() => {
    (async (organizationNumber: string) => {
      setLoading(true);
      setSynaDetails(null);
      setAgreement(null);
      setId06(null);

      if (hasSyna) {
        ServiceSyna.getSynaDetails(organizationNumber).then(data => setSynaDetails(data));
        serviceSupplierMonitoring.fetchMonitoredSupplier(organizationNumber).then(data => setMonitoredSupplier(data));
      }

      try {
        const result = (await ServiceCollectiveAgreement.getCollectiveAgreement(organizationNumber)) as CollectiveAgreements;
        if (result) {
          setAgreement(result);
        }
      } catch (e) {
        console.log(e);
        setAgreementError("Kunde inte hämta kollektivavtal.");
      }

      setLoading(false);
      const id06response = await ServiceId06.checkId06(organizationNumber);
      setId06(id06response.data);
    })(organizationNumber);
  }, [organizationNumber]);

  const getErrorMsg = (err: string) => {
    let error = 'Det gick inte att hämta rapporten';
    const publicMsgIndex = err.indexOf('Public message:');
    const errorCodeIndex = err.indexOf('public error code:');

    if (publicMsgIndex > -1) error = err.slice(publicMsgIndex + 16, errorCodeIndex);
    return error;
  };

  const downloadSyna = async (organizationNumber: string) => {
    const orgSlug = appState.getCurrentOrgSlug();
    const targetWindow = window.open(`/${orgSlug}/syna/report`) as (Window & { fetchingReport: boolean });
    if (targetWindow) {
      targetWindow.fetchingReport = true;
      const res = await axios.get('/api/syna/report', { params: { organizationNumber } });
      targetWindow.fetchingReport = false;
      if (res.data.link) {
        targetWindow.location = res.data.link;
      } else {
        targetWindow.close();
        let errMsg = getErrorMsg(res.data.error);
        NotificationManager.error(errMsg, 'Fel vid hämtning av rapport');
      }
    }
  };

  const renderSynaIndicatorAndButton = (organizationNumber: string) => (synaLoader ? (
    <Loading type="inline" loadingStyle="bubbles" size="30px" />
  ) : (
    <>
      <div className="sp-control-row">
        <b>Kreditupplysning</b>
        {synaDetails && !synaDetails.error && (
          <div>
            <span className={'indicator ' + { 1: 'high', 2: 'medium', 3: 'low', 4: 'low', 5: 'low' }[synaDetails.rating]}>{synaDetails.rating}</span>
          </div>
        )}
        {synaDetails && synaDetails.error && (<div>
          <span className="indicator">?</span></div>
        )}
        {!synaDetails && (
          <div><Loading type="inline" loadingStyle="bubbles" size="30px" /></div>
        )}
        <div>
          <BtButton
            onClick={() => downloadSyna(organizationNumber)}
            color="white"
            size="xxs"
          >Syna rapport</BtButton>
        </div>
      </div>
      {synaDetails && synaDetails.comment && (
        <div className="contact-email-and-phone" style={{ marginTop: "-14px", marginBottom: "16px" }}>{synaDetails.comment}</div>
      )}
    </>
  ));
  const renderSynaDetails = () => {
    const renderKeyValue = (key: string, value: ReactNode) => (
      <div className="sp-control-row">
        <b>{key}</b>
        <div>{value}</div>
      </div>
    );
    if (!hasSyna) return null;
    if (synaDetails) {
      if (synaDetails.error) {
        return (
          <div className="ar-row" style={{ justifyContent: "center", padding: "8px 0 16px 0" }}>
            Kunde inte hämta uppgifter från Syna
          </div>
        );
      } else {
        let postponedTax = "–";
        if (synaDetails.postponedTax && synaDetails.postponedTax?.[0]?.amount) {
          postponedTax = `${(+synaDetails.postponedTax?.[0]?.amount).toLocaleString()} kr`;
        }
        return (<>
          {renderKeyValue("Säte", synaDetails.sate)}
          {renderKeyValue("F-skatt", <YesNo value={synaDetails.fskatt} />)}
          {renderKeyValue("Reg. för moms", <YesNo value={synaDetails.momsreg} />)}
          {renderKeyValue("Reg. arbetsgivare", <YesNo value={synaDetails.arbetsgivarreg} />)}
          {renderKeyValue("Omsättning", synaDetails.revenue != null ? `${(+synaDetails.revenue).toLocaleString()} tkr` : "–")}
          {renderKeyValue("Anställda", synaDetails.employees != null ? (+synaDetails.employees).toLocaleString() : "–")}
          {renderKeyValue("Skatteanstånd", postponedTax)}
        </>);
      }
    } else {
      return (
        <div className="ar-row" style={{ justifyContent: "center", padding: "8px 0 16px 0" }}>
          <Loading type="inline" color="#ff5e15" loadingStyle="bubbles" size="30px" />
        </div>
      );
    }
  }

  const url = appState._getCurrentOrgUrl();

  return (
    <div className={`ar-column detail-right-col ${className}`}>
      <h3 className="inner-sec-title">Kontroll</h3>

      <div className="details-right-inner">
        {renderSynaDetails()}
        <div className="sp-control-row">
          <b>Kollektivavtal</b> <CollectiveAgreement agreementData={agreement} error={agreementError} loading={loading} />
        </div>

        {hasSyna && renderSynaIndicatorAndButton(organizationNumber)}

        {id06 && !id06.error && (
          <div className="sp-control-row">
            <b>ID06</b>
            <div key="indicator">
              <YesNo value={id06.status}></YesNo>
            </div>
            <div>{id06.cards || "Inga"} kort</div>
          </div>
        )}
        {id06 && !id06.error && !!synaDetails && !!synaDetails.arbgivavg && (
          <>
            <div className="sp-control-row">
              <b>Arbetsgivaravgifter (för {moment(synaDetails.arbgivavg.year + "-" + synaDetails.arbgivavg.month + "-01").locale('sv').format("MMMM YYYY")})</b>
              <div>{new Number(synaDetails.arbgivavg.amount).toLocaleString()} kr</div>
            </div>
            <div className="sp-control-row">
              <b>Antal heltidsanställda (uppskattat)</b><br />
              <div>≤ {Math.ceil(synaDetails.arbgivavg.amount / 0.3142 / 30000)}</div>
            </div>
            <div className="contact-email-and-phone" style={{ marginTop: "-14px", marginBottom: "16px" }}>Baserat på 31,42% arbetsgivaravgift och minst 30 000 kr i månadslön</div>
          </>
        )}
        {id06 && id06.error && (
          <div className="sp-control-row">
            <b>ID06</b>
            <b>{id06.error == "Invalid org.nr" ? "Ogiltigt organisationsnummer" : "Fel vid hämtning från ID06: " + id06.error}</b>
          </div>
        )}
        {hasSyna && (
          <>
            <div className="sp-control-row">
              <b>Bevakad</b>
              <YesNo value={!!monitoredSupplier} />
              <div>
                {monitoredSupplier && history && (
                  <BtButton
                    size="xxs"
                    color="white"
                    leftIcon={<MonitoringHeartIcon />}
                    onClick={() => {
                      history.push(`${url}/supplier-monitoring/events`, {
                        supplier: {
                          name: monitoredSupplier.name,
                          organizationNumber: monitoredSupplier.organizationNumber,
                        },
                      })
                    }}
                  >
                    Visa händelser
                  </BtButton>
                )}
              </div>
            </div>
            {monitoredSupplier && <div className="contact-email-and-phone" style={{ marginTop: "-14px", marginBottom: "16px" }}>Bevakad sedan {formatDate(monitoredSupplier.monitoredSince)}{monitoredSupplier.lastEventDate && `, senaste händelse ${formatDate(monitoredSupplier.lastEventDate)}`}</div>}
          </>
        )}
        {children}
      </div>
    </div>
  );
}


export default SupplierControls;
