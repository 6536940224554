import { FC, 
// useEffect,
useState } from 'react';
import Logo from '../../components/svgs/logo';
// import _ from 'lodash';
// import UserDetailsForm from './user-details';
import CraneImage from '../../assets/images/crane.jpg';
import BtBackground from '../bt-background/bt-background';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import BtCenter from '../bt-center/bt-center';
import { RouteComponentProps } from 'react-router-dom';
import styles from './page-supplier-signup.module.scss';
import Field from '../../components/shared/Field/Field';
import _ from 'lodash';
import BtButton from '../bt-button/bt-button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { NotificationManager } from "react-notifications";
import * as Sentry from "@sentry/react";
import { serviceSalesOrgApplication } from '../../services/salesOrgApplicationService';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import { AxiosError } from 'axios';

type Params = {
  areaSlug: string;
  project: string;
  version: string;
};

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
  location: RouteTypes['location'];
  match: RouteTypes['match'];
};

type Recipient = {
  personalNumber: string,
  name: string,
  email: string,
  tempId: number,
}

const newRecipient = () => ({
  personalNumber: '',
  name: '',
  email: '',
  tempId: Date.now(),
});

type ErrorMessages = {
  organizationNumber?: string,
  invoiceEmail?: string,
  invoiceRef?: string,
  adminEmail?: string,
  recipients?: Partial<Recipient>[]
}

const SupplierSignUp: FC<Props> = ({ location, history }) => {
  location;
  history;

  const [organizationNumber, setOrganizationNumber] = useState('');
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [invoiceRef, setInvoiceRef] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [recipients, setRecipients] = useState<Recipient[]>([newRecipient()]);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({});
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [loadingOrgName, setLoadingOrgName] = useState(false);
  const [organizationNumberWarning, setOrganizationNumberWarning] = useState('');

  const submit = async () => {
    setSubmitting(true);
    setErrorMessages({});

    try {
      const { errorMessages } = await serviceSalesOrgApplication.createSalesOrgApplication({
        organizationNumber,
        invoiceEmail,
        invoiceRef,
        adminEmail,
        recipients,
      });
      if (!_.isEmpty(errorMessages)) {
        setErrorMessages(errorMessages);
        NotificationManager.error('Ogiltiga fält', 'Ogiltig');
      } else {
        setOrganizationNumber('');
        setInvoiceEmail('');
        setInvoiceRef('');
        setAdminEmail('');
        setOrganizationName('');
        setRecipients([newRecipient()]);
        NotificationManager.success('Din ansökan har lämnats', 'Ansökt');
        setSubmitted(true);
      }
    } catch (error) {
      if ((error as AxiosError<{error: string}>)?.response?.data?.error === 'error.notAllowedToSign') {
        NotificationManager.error('Firmateckningsrätt saknas. Lägg till VD eller registrerade firmatecknare.', 'Misslyckat');
      } else {
        const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
        NotificationManager.error(errorMsg, 'Misslyckat');
        Sentry.captureException(error);
      }
    } finally {
      setSubmitting(false);
    }
  }

  const lookupOrgNumber = async (organizationNumber: string) => {
    if (organizationNumber) {
      setLoadingOrgName(true);
      setOrganizationName('');
      try {
        const response = await serviceSalesOrgApplication.checkOrgNumber(organizationNumber);
        const orgName = response.name;
        setOrganizationName(orgName);
        setErrorMessages({ organizationNumber: '' });
        return orgName;
      } catch (error) {
        if ((error as AxiosError<{error: string}>)?.response?.data?.error === 'error.invalidOrgNumber') {
          setErrorMessages({ organizationNumber: 'Ogiltigt organisationsnummer' });
        } else if ((error as AxiosError<{error: string}>)?.response?.data?.error === 'error.canNotParseSigningRules') {
          setOrganizationNumberWarning('Kunde inte tolka firmateckningsregler. Systemet förstod inte vem som tecknar firman för detta organisationsnummer. Ansökan kommer granskas manuellt inom tre arbetsdagar.');
        } else if ((error as AxiosError<{error: string}>)?.response?.data?.error === 'error.orgExists') {
          setErrorMessages({ organizationNumber: 'En organisation med det organisationsnumret finns redan' });
        } else if ((error as AxiosError<{error: string}>)?.response?.data?.error === 'error.company.notFound') {
          setErrorMessages({ organizationNumber: 'Inget företag med detta organisationsnummer hittades.' });
        } else if ((error as AxiosError<{error: string}>)?.response?.data?.error === 'error.service.unavailable') {
          setErrorMessages({ organizationNumber: 'Något gick fel med anslutningen till vår kreditupplysningstjänst, så vi kunde inte slå upp organisationsnumret.' });
        } else {
          const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
          NotificationManager.error(errorMsg, 'Misslyckat');
          Sentry.captureException(error);
        }
      } finally {
        setLoadingOrgName(false);
      }
    }
  }

  const formatTaxNumber = (oldN:string, newN: string) => {
    if (oldN.split('-').length < newN.split('-').length && oldN?.includes('-')) {
      return oldN;
    }
    if (newN.length > 11) return oldN;
    const wantsToRemoveDash = !newN.includes('-') && oldN?.includes('-');

    if (newN.length == 6 && !wantsToRemoveDash && !newN?.includes('-')) {
      newN = newN + '-';
    } else if (newN.length >= 7 && !newN?.includes('-')) {
      newN = newN.substring(0, 6) + '-' + newN.substring(6, newN.length);
    }

    return newN;
  }

  return (
    <div className="page-login">
      <BtGrid>
        <BtRow>
          <BtColumn className="bt-w50 first-column bg-white" style={{ padding: '70px 0' }}>
            <BtCenter>
              <div className="align-center bt-w60">
                <div className="m-t-30 m-b-40 d-flex justify-content-center">
                  <Logo size="194px" type="logoWithTitle" />
                </div>
                {submitted ? (
                  <div className={styles.form}>
                    <div>
                      <h3>Ansökt</h3>
                      <p>
                        Din ansökan har skickats in och användaravtalet har skickats för signering. 
                      </p>
                      <p>
                        En länk till en sida där man kan följa signeringsprocessen har skickats till administratören.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.form}>
                    <div>
                    <h3>Accurator för leverantörer: Skapa konto för ditt företag</h3>
                    <p>
                      Att svara på anbudsförfrågningar i Accurator är gratis och kräver ingen inloggning. 
                    </p>
                    <p>
                      Ett leverantörskonto hos Accurator ger dig
                      <ul>
                        <li>mer kontroll över hur ditt företag presenteras i Accurator och vilka kontaktuppgifter som används för förfrågningar</li>
                        <li>möjlighet att se och besvara förfrågningar för hela företaget på ett ställe</li>
                        <li>tillgång till upphandlingar som inköparen valt att göra öppna</li>
                        <li>möjlighet att marknadsföra företaget mot inköpare i systemet.</li>
                      </ul>
                      Att skapa ett konto är gratis, men vissa av dessa funktioner är betalda prenumerationstjänster som beställs inne i systemet.
                    </p>
                    <p>
                      För att skapa ett konto för ditt företag måste vårt användaravtal signeras med BankID. Om VD eller firmatecknare signerar godkänns 
                      ansökan automatiskt, annars granskas den manuellt, vilket kan ta upp till tre arbetsdagar. 
                    </p>
                    <p>
                      Direkt när ansökan är godkänd får du som administratör en inbjudan till det nya kontot via e-post.
                    </p>
                    </div>
                    <Field
                      label='Organisationsnummer'
                      value={organizationNumber}
                      required
                      errorMessage={errorMessages.organizationNumber}
                      warningMessage={organizationNumberWarning}
                      onChange={value => {
                        setOrganizationNumber(prevState => {
                          const orgVal = formatTaxNumber(prevState, value.replace(/[^0-9-]/, '')?.trim());
                          
                          if (orgVal.length == 11) {
                            lookupOrgNumber(orgVal)
                          } else {
                            setOrganizationName("");
                            setErrorMessages({ organizationNumber: '' });
                          }

                          return orgVal;
                        });
                      }}
                    />
                    <div>
                      {<h4 style={{marginTop: "8px", marginBottom: "8px", color: "var(--gray-700)", fontWeight: 500 }}>Företagsnamn</h4>}
                      <span className={styles.organizationName}>
                        {organizationName}
                        {loadingOrgName && <div className={styles.spinner}><div></div><div></div></div>}
                        {!organizationName && !loadingOrgName && <span style={{color: "var(--gray-500)"}}>(fyll i organisationsnummer)</span>}
                      </span>
                    </div>

                    <Field label='Administratörens e-postadress' value={adminEmail} onChange={setAdminEmail} required errorMessage={errorMessages.adminEmail} />
                    <div>
                      <h4>Fakturauppgifter</h4>
                      <p>För fakturering av eventuella tilläggstjänster som ni beställer i systemet.</p>
                    </div>
                    <Field label='E-postadress' value={invoiceEmail} onChange={setInvoiceEmail} required errorMessage={errorMessages.invoiceEmail} />
                    <Field label='Referens' value={invoiceRef} onChange={setInvoiceRef} required errorMessage={errorMessages.invoiceRef} />
                    {!_.isEmpty(recipients) && (
                      <div>
                        <h4>Signering</h4>
                        <p>Ange nedan vilka som ska skriva på användaravtalet.</p>
                        <div className={styles.recipients}>
                          {recipients.map((recipient, index) => {
                            return (
                              <div className={styles.recipient} key={recipient.tempId}>
                                {recipients.length > 1 && <CloseIcon onClick={() => setRecipients(prevState => prevState.filter((_, i) => i !== index))} />}
                                <Field
                                  label='Personnummer'
                                  value={recipient.personalNumber}
                                  placeholder='ÅÅMMDD-XXXX'
                                  required
                                  errorMessage={errorMessages.recipients?.[index].personalNumber}
                                  onChange={value => {
                                    setRecipients(prevState => {
                                      const newState = [...prevState];
                                      const personalNumber = formatTaxNumber(prevState[index].personalNumber, value.replace(/[^0-9-]/, '').trim());

                                      newState[index].personalNumber = personalNumber;
                                      return newState;
                                    });
                                  }}
                                />
                                <Field
                                  label='Namn'
                                  required
                                  errorMessage={errorMessages.recipients?.[index].name}
                                  value={recipient.name}
                                  onChange={value => {
                                    setRecipients(prevState => {
                                      const newState = [...prevState];
                                      newState[index].name = value;
                                      return newState;
                                    })
                                  }}
                                />
                                <Field
                                  label='E-postadress'
                                  required
                                  errorMessage={errorMessages.recipients?.[index].email}
                                  value={recipient.email}
                                  onChange={value => {
                                    setRecipients(prevState => {
                                      const newState = [...prevState];
                                      newState[index].email = value;
                                      return newState;
                                    })
                                  }}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                    <BtButton
                      color='white'
                      size='xxs'
                      leftIcon={<AddCircleIcon htmlColor="var(--orange-500)"/>}
                      style={{ width: 'fit-content' }}
                      onClick={() => setRecipients(prevState => [...prevState, newRecipient()])}
                    >
                      Lägg till undertecknare
                    </BtButton>
                    <BtButton
                      loaderShow={submitting}
                      onClick={submit}
                      style={{ width: 'fit-content', justifySelf: 'flex-end' }}
                    >
                      Skicka in
                    </BtButton>
                  </div>
                )}
              </div>
            </BtCenter>
          </BtColumn>
          <BtColumn className="second-column">
            <BtBackground
              className={`bg-login bt-background-position-left ${styles.background}`}
              src={CraneImage}
            />
          </BtColumn>
        </BtRow>
      </BtGrid>
    </div>
  );
};

export default SupplierSignUp;
