export default function ContractIcon({size = '20px', color = '#047857', style}) {
    return (
      <svg width={size} height={size} style={style} viewBox="0 0 487 487" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_44:1111)">
          <path d="M308.3 259.7C308.3 252.4 302.3 246.3 294.9 246.3H69.9C62.6 246.3 56.5 252.3 56.5 259.7C56.5 267.1 62.5 273.1 69.9 273.1H295C302.3 273.1 308.3 267.1 308.3 259.7Z" fill={color}/>
          <path d="M296.1 192.9H170.5C163.2 192.9 157.1 198.9 157.1 206.3C157.1 213.6 163.1 219.7 170.5 219.7H296.1C303.4 219.7 309.5 213.7 309.5 206.3C309.4 198.9 303.4 192.9 296.1 192.9Z" fill={color}/>
          <path d="M338 415.4C338 431.9 324.6 445.3 308.1 445.3H56.7C40.2 445.3 26.8 431.9 26.8 415.4V70.4C26.8 53.9 40.2 40.5 56.7 40.5H233.9V83.9C233.9 115.2 259.3 140.6 290.6 140.6H338V212.9C338.4 212.3 338.8 211.6 339.1 211C339.8 209.9 340.6 208.6 341.5 207.2C348.8 195.2 360.4 175.9 362.7 172.2C363.3 171.1 364 170.1 364.7 169V132.5C364.7 129 364 125.6 362.7 122.5C361.3 119.3 359.4 116.5 356.9 114.1L260.2 21.1C257.8 18.8 255 17 252 15.8C248.9 14.6 245.7 13.9 242.4 13.9H56.7C25.4 13.9 0 39.3 0 70.6V415.6C0 446.9 25.4 472.3 56.7 472.3H308.1C339.4 472.3 364.8 446.9 364.8 415.6V349.4C356.2 361 347.3 372.5 338.1 383.7V415.4H338V415.4Z" fill={color}/>
          <path d="M94 101.7C81.9 106.3 74.5 114.7 73.9 128C73.4 139.7 79.6 147.6 89.3 153.2C95.3 156.7 101.9 158.7 108.2 161.4C110.7 162.4 113 163.7 115.1 165.3C121.2 170.2 120.2 178.5 113.1 181.8C109.3 183.5 105.2 184 101.1 183.6C94.7 182.9 88.5 181.3 82.6 178.4C79.2 176.7 78.2 177.2 77.1 180.9C76.2 184.1 75.3 187.3 74.5 190.5C73.4 194.8 73.8 195.9 78 197.8C83.3 200.3 88.9 201.5 94.6 202.3C99.1 202.9 99.2 203.1 99.4 207.8C99.5 209.9 99.5 212 99.6 214.1C99.7 216.8 101 218.3 103.7 218.3C106.8 218.3 109.9 218.3 113 218.1C115.5 218 116.8 216.6 116.8 214C116.8 211.1 116.9 208.2 116.7 205.4C116.5 202.5 117.8 201 120.6 200.2C127.1 198.3 132.5 194.8 136.7 189.5C148.3 175 143.5 154 126.6 145C121.3 142.2 115.7 140.1 110.1 137.8C106.9 136.5 103.8 135 101.1 132.9C95.7 128.7 96.6 121.8 102.8 119C104.5 118.2 106.4 117.9 108.3 117.8C115.5 117.3 122.4 118.5 129 121.6C132.3 123.1 133.4 122.6 134.4 119.2C135.5 115.6 136.4 111.9 137.4 108.3C138.1 105.9 137.2 104.3 134.9 103.3C130.7 101.5 126.3 100.2 121.8 99.6C115.9 98.8 115.9 98.7 115.8 92.8C115.6 84.4 115.6 84.4 107.3 84.5C106.1 84.5 104.9 84.5 103.6 84.6C99.7 84.8 99 85.5 99 89.4C99 91.2 99 92.9 99.1 94.7C99 99.9 99 99.8 94 101.7Z" fill={color}/>
          <path d="M283.3 314.2C285.6 309.4 288 304.6 290.4 299.8H69.9C62.6 299.8 56.5 305.8 56.5 313.2C56.5 320.5 62.5 326.6 69.9 326.6H277.5C279.3 322.5 281.3 318.4 283.3 314.2Z" fill={color}/>
          <path d="M56.5 366.5C56.5 373.8 62.5 379.9 69.9 379.9H256.1C259 370.9 262.2 362.1 265.8 353.2H69.9C62.5 353.2 56.5 359.2 56.5 366.5Z" fill={color}/>
          <path d="M481.4 166.7L473.8 162.3L461.5 155.2C463.5 150.6 465.3 145.9 466.7 141.1C467.1 139.5 467.6 138 467.9 136.4C470.1 125.5 466.3 118 455.4 111.5C446 105.9 438.6 106.5 430.1 113.5C424.3 118.3 419.6 124.1 415.2 130.1C399.2 151.9 385.6 175.3 371.4 198.2L354.2 226.8C335.6 258.3 318.1 290.4 302.2 323.4C291.7 345.1 281.8 367.1 274.8 390.3C274.1 393.5 273.2 396.8 272.9 400.1C272.5 403.9 271.1 408.6 275.2 411C279.2 413.3 282.7 410.1 285.9 407.8C294.5 401.6 301.7 393.9 308.6 386C342.3 347.5 371 305.5 398.6 262.5L420.3 227.7C429.6 211.8 439.3 196.2 448.5 180.3C450.2 177.3 452 174.2 453.7 171.2L465.1 177.8L409.5 273.5C405.3 280.8 409 290.5 418 292.6C418.8 292.8 419.7 292.4 420.1 291.7L485 179.9C487.5 175.2 486 169.4 481.4 166.7Z" fill={color}/>
        </g>
        <defs>
          <clipPath id="clip0_44:1111">
            <rect width="486.201" height="486.201" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    );
}